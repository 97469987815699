import React from "react";
import { Sheet, Typography, Tabs, TabList, Tab, TabPanel, Button } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import UserManagement from "../components/UserManagement";

const Settings = () => {
  const navigate = useNavigate();

  return (
    <Sheet sx={{ p: 3, borderRadius: "md", boxShadow: "sm" }}>
      <Typography level="h3">Firm Users</Typography>

      <Tabs>
        <TabList>
          <Tab>Users</Tab>
          <Tab>Preferences</Tab>
        </TabList>

        <TabPanel value={0}>
          <UserManagement />
        </TabPanel>

        <TabPanel value={1}>
          <Typography>Preferences Page</Typography>
        </TabPanel>
      </Tabs>

      {/* Navigation Button to User Page */}
      <Button onClick={() => navigate("/users")} sx={{ mt: 2 }}>
        Go to User Management
      </Button>
    </Sheet>
  );
};

export default Settings;