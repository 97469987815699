import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Input,
  CircularProgress,
  Table,
} from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import AddContactModal from "../components/AddContactModal";

const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ search: "" });
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();
  const API_BASE_URL =
    process.env.REACT_APP_BASE_URL || "http://localhost:3001";
  const limit = 20; // number of contacts per page

  // Helper function to format date to mm/dd/yy
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const fetchContacts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/contacts`, {
        params: {
          page: currentPage,
          search: filters.search,
        },
      });
      setContacts(
        Array.isArray(response.data.contacts)
          ? response.data.contacts
          : []
      );
      const totalContacts = response.data.totalContacts || 0;
      const calculatedPages = Math.ceil(totalContacts / limit);
      setTotalPages(calculatedPages || 1);
    } catch (error) {
      console.error("Error fetching contacts:", error);
      setContacts([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [currentPage, filters]);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
    setCurrentPage(1);
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          gap: 1,
        }}
      >
        <Typography level="h4">Contacts</Typography>
        <Button
          startDecorator={<AddIcon />}
          variant="solid"
          size="sm"
          onClick={() => setOpenModal(true)}
        >
          Add a Contact
        </Button>
      </Box>

      {/* Filters */}
      <Box sx={{ display: "flex", gap: 2, mb: 2, flexWrap: "wrap" }}>
        <Input
          placeholder="Search by name"
          name="search"
          value={filters.search}
          onChange={handleFilterChange}
          size="sm"
          sx={{ minWidth: 200 }}
        />
      </Box>

      {/* Contacts Table */}
      <Box sx={{ overflowX: "auto", mb: 2 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table sx={{ minWidth: 600, borderRadius: 2, boxShadow: 2 }}>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Cases</th>
                <th>Added</th>
              </tr>
            </thead>
            <tbody>
              {contacts.length > 0 ? (
                contacts.map((contact) => (
                  <tr
                    key={contact.contact_id}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/contacts/${contact.contact_id}`)
                    }
                  >
                    <td>{contact.first_name || "N/A"}</td>
                    <td>{contact.last_name || "N/A"}</td>
                    <td>{contact.case_name || "N/A"}</td>
                    <td>
                      {contact.created_date
                        ? `${formatDate(contact.created_date)} by ${contact.created_by}`
                        : "N/A"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center", padding: "20px" }}>
                    No contacts found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </Box>

      {/* Pagination */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Button
          variant="soft"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => prev - 1)}
        >
          Previous
        </Button>
        <Typography>
          Page {currentPage} of {totalPages}
        </Typography>
        <Button
          variant="soft"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
        </Button>
      </Box>

      {/* Add Contact Modal */}
      <AddContactModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onContactAdded={fetchContacts}
      />
    </Box>
  );
};

export default Contacts;