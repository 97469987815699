import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalDialog,
  Typography,
  FormLabel,
  Input,
  Textarea,
  Select,
  Option,
  Button,
  Box,
  Alert,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/joy";
import axios from "axios";

export default function TaskModal({ open, onClose, 
  task = {},  // Default empty object if not provided
  setTask = () => {},  // Default empty function if not provided
  onSave, isEditing }) {
  const [errors, setErrors] = useState({});
  const [cases, setCases] = useState([]);
  const [selectedCase, setSelectedCase] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
    // Fetch all cases initially
    useEffect(() => {
      const fetchCases = async () => {
        try {
          const response = await axios.get("/cases",{
            params:{search:searchTerm},
          });
          setCases(response?.data?.cases || []);
          console.log("Cases:", response?.data?.cases);
        } catch (error) {
          console.error("Error fetching cases:", error);
          setCases([]);
        }
      };
  
      fetchCases();
    }, [searchTerm]);
  
    // Fetch single case if not in current list
    const fetchCaseById = async (caseId) => {
      try {
        const { data } = await axios.get(`/cases/${caseId}`);
        console.log("Fetched Case:", data);
        setCases((prevCases) =>
          prevCases.some((c) => c?.case_id === data?.case_id) ? prevCases : [...prevCases, data]
        );
        setSelectedCase(data?.case_id);
      } catch (error) {
        console.error("Error fetching case:", error);
      }
    };
  
    // Check if editing task has an associated case
    useEffect(() => {
      if (isEditing && task?.case_id) {
      
  
        const foundCase = cases.find((c) => c?.case_id === task?.case_id);
        if (foundCase) {
          console.log("Found Case:", foundCase);
          setSelectedCase(foundCase?.case_id);
        } else {
          console.log("Case not found in current list. Fetching...");
          fetchCaseById(task?.case_id);
        }
      }
    }, [isEditing, task, cases]);
  // Validate fields
  const validateFields = () => {
    let newErrors = {};
    if (!task.task_name) newErrors.task_name = "Task name is required";
    if (!task.description) newErrors.description = "Description is required";
    if (!task.priority) newErrors.priority = "Priority is required";
    if (task.completed === undefined) newErrors.completed = "Completion status is required";
    if (!task.due_date) newErrors.due_date = "Due date is required";
    if (!task.case_id) newErrors.case_id = "Case ID is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (validateFields()) {
      const updatedTask = { ...task, case_id: selectedCase };
      setTask(updatedTask);
      onSave(updatedTask);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog sx={{ width: 400 }}>
        <Typography level="h4">{isEditing ? "Edit Task" : "Add Task"}</Typography>

        {Object.keys(errors).length > 0 && (
          <Alert color="danger" sx={{ marginBottom: 2 }}>
            Please fill in all required fields.
          </Alert>
        )}

        <FormLabel>Task Name</FormLabel>
        <Input
          value={task?.task_name}
          onChange={(e) => setTask({ ...task, task_name: e.target.value })}
          error={!!errors.task_name}
          placeholder="Enter task name"
        />
        
        <FormLabel>Description</FormLabel>
        <Textarea
          value={task?.description}
          onChange={(e) => setTask({ ...task, description: e.target.value })}
          error={!!errors.description}
          placeholder="Enter task description"
        />

        <FormLabel>Priority</FormLabel>
        <Select
          value={task?.priority}
          onChange={(event, newValue) => setTask({ ...task, priority: newValue })}
          error={!!errors.priority}
        >
          <Option value="Low">Low</Option>
          <Option value="Medium">Medium</Option>
          <Option value="High">High</Option>
        </Select>

        <FormLabel>Completed</FormLabel>
        <Select
          value={task?.completed ? "true" : "false"}
          onChange={(event, newCompleted) =>
            setTask({ ...task, completed: newCompleted === "true" })
          }
          error={!!errors.completed}
        >
          <Option value="true">Yes</Option>
          <Option value="false">No</Option>
        </Select>

        <FormLabel>Due Date</FormLabel>
        <Input
          type="date"
          value={task?.due_date}
          onChange={(e) => setTask({ ...task, due_date: e.target.value })}
          error={!!errors.due_date}
        />

<Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <FormLabel>Case</FormLabel>
            <Autocomplete
              fullWidth
              options={cases}
              getOptionLabel={(option) => option?.name}
              value={cases?.find((c) => c?.case_id === task?.case_id) || null}
              onChange={(e, value) => {
                setSelectedCase(value?.case_id || "");
                setTask({ ...task, case_id: value?.case_id || "" });
              }}
              inputValue={searchTerm}
              onInputChange={(e, newInputValue) => setSearchTerm(newInputValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search case..."
                  placeholder="Type to search"
                  error={!!errors.case_id}
                />
              )}
            />
            {errors.case_id && (
              <Typography sx={{ color: "#D32F2F", fontSize: "0.875rem", mt: 0.5 }}>
                {errors.case_id}
              </Typography>
            )}
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="solid" onClick={handleSave}>
            {isEditing ? "Update" : "Create"}
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
