import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventModal from "../components/EventModal"; // Import Event Modal
import {
  Sheet,
  Modal,
  ModalDialog,
  ModalClose,
  Button,
  Typography,
  Box,
  Select,
  Option,
  Divider,
} from "@mui/joy";
import axios from "axios"; // Import axios
import AddEventForm from "../components/AddEventForm";
import { auth } from "../firebase/firebase";

const localizer = momentLocalizer(moment);

const CalendarPage = () => {
  const [events, setEvents] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState("");
  const [selectedEventType, setSelectedEventType] = useState(["all"]);
  const [isAddEventOpen, setIsAddEventOpen] = useState(false);
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [commentText, setCommentText] = useState("");
  const handlePostComment = () => {
    console.log("Comment Posted:", commentText);
    setShowCommentForm(false);
  };

  // Fetch events and event types from the backend using axios
  const fetchEvents = async () => {
    try {
      const eventResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/events`);
      const eventTypeResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/event-types`);

      const eventsData = eventResponse.data;
      const eventTypesData = eventTypeResponse.data;

      // Map event types into an object for quick lookup
      const eventTypeMap = eventTypesData.reduce((acc, type) => {
        acc[type.event_type_name] = type.color_code;
        return acc;
      }, {});

      // Map events and assign colors
      const formattedEvents = eventsData.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
        backgroundColor: eventTypeMap[event.event_type] || "#3788d8", // Default color if no match
      }));

      setEvents(formattedEvents);
      setEventTypes(eventTypesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
   
  
    fetchEvents();
  }, []);

  const eventStyleGetter = (event) => {
    const backgroundColor = event.backgroundColor || "#3788d8"; // Default if no color found
    return {
      style: {
        backgroundColor,
        color: "white", // Ensure text is readable
        borderRadius: "5px",
        padding: "5px",
        border: "none",
      },
    };
  };

  // Handle event selection (Open modal)
  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setIsEditing(false);
  };

  // Close the modal
  const closeModal = () => {
    setSelectedEvent(null);
    setIsEditing(false);
  };

  // Handle event edit
  const handleEventEdit = async (updatedEvent) => {
    try {
      const currentUser = auth.currentUser?.uid;

      await axios.put(`${process.env.REACT_APP_BASE_URL}/events/${updatedEvent.id}`, updatedEvent, {
        headers: {
          "Content-Type": "application/json",
          'x-user-uid': currentUser  

        },
      });

      // Update the event in the local state
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === updatedEvent.id
            ? { ...updatedEvent, start: new Date(updatedEvent.start), end: new Date(updatedEvent.end) }
            : event
        )
      );

      closeModal();
      fetchEvents();
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  // Handle event delete
  const handleEventDelete = async (eventId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/events/${eventId}`);

      // Remove the event from the local state
      setEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventId));

      closeModal();
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  // Filter events based on selected event type
  const filteredEvents =
    selectedEventType.includes("all")
      ? events
      : events.filter((event) => selectedEventType.includes(event.event_type));

  return (
    <Sheet
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Switch to column on small screens
        maxWidth: "100%",
        padding: "24px",
        margin: "auto",
        marginTop:"1rem"

      }}
    >
      {/* Sidebar */}
      <Box
        sx={{
          width: { xs: "100%", md: "25%" },
          paddingRight: { xs: 0, md: "20px" },
          marginBottom: { xs: "20px", md: 0 },
        }}
      >
        {/* Mini Calendar */}
        <Calendar
          localizer={localizer}
          events={[]}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 250, marginBottom: "20px" }}
          defaultView="month"
          views={["month"]}
        />
        <Typography level="h5" sx={{ marginTop: "16px" }}>
          Event Types
        </Typography>
        <Select
          multiple // Enable multiple selection
          value={selectedEventType}
          onChange={(e, newValue) => setSelectedEventType(newValue)}
        >
          <Option value="all">
            <input type="checkbox" checked={selectedEventType.includes("all")} readOnly /> Select All
          </Option>
          {eventTypes.map((eventType) => (
            <Option key={eventType.id} value={eventType.event_type_name}>
              <input
                type="checkbox"
                checked={selectedEventType.includes(eventType.event_type_name)}
                readOnly
              />{" "}
              {eventType.event_type_name}
            </Option>
          ))}
        </Select>
      </Box>

      {/* Main Content */}
      <Box sx={{ flex: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <Typography level="h2" align="center" sx={{ marginBottom: { xs: "16px", sm: "0" } }}>
            Event Calendar
          </Typography>
          <Button onClick={() => setIsAddEventOpen(true)} color="primary">
            Add Event
          </Button>
        </Box>
        <Calendar
          localizer={localizer}
          events={filteredEvents}
          startAccessor="start"
          endAccessor="end"
          titleAccessor="title"
          // Optionally adjust height for smaller screens if needed
          style={{ height: 700, marginBottom: "50px" }}
          defaultView="month"
          views={["month", "week", "day", "agenda"]}
          onSelectEvent={handleSelectEvent}
          eventPropGetter={eventStyleGetter}
        />
      </Box>

      {/* Add Event Modal */}
      <Modal open={isAddEventOpen} onClose={() => setIsAddEventOpen(false)}>
        <ModalDialog>
          <ModalClose onClick={() => setIsAddEventOpen(false)} />
          <AddEventForm onClose={() => setIsAddEventOpen(false)} />
        </ModalDialog>
      </Modal>

      {/* Event Modal */}
      <Modal open={!!selectedEvent} onClose={closeModal}>
        <ModalDialog
          sx={{
            maxWidth: { xs: "90%", sm: "600px", md: "800px" },
            maxHeight: "90vh",
            padding: "20px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ModalClose onClick={closeModal} />

          {/* Scrollable Content */}
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              paddingBottom: "10px", // Prevents cut-off before the fixed buttons
            }}
          >
            {isEditing ? (
              <EventModal
                event={{
                  id: selectedEvent?.id || "",
                  event_name: selectedEvent?.title || "Unnamed Event",
                  event_description: selectedEvent?.description || "No description available",
                  start_event: selectedEvent?.start ? moment(selectedEvent.start).format("YYYY-MM-DDTHH:mm") : "",
                  end_event: selectedEvent?.end ? moment(selectedEvent.end).format("YYYY-MM-DDTHH:mm") : "",
                  attendees: selectedEvent?.staff_name || "",
                  case: selectedEvent?.case_name || "",
                  event_type: selectedEvent?.event_type || "",
                  case_id: selectedEvent?.case_id || "",
                  color: selectedEvent?.backgroundColor || "#fff",
                }}
                onClose={closeModal}
                onEventEdit={handleEventEdit}
                onEventDelete={handleEventDelete}
              />
            ) : (
              <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, width: "100%" }}>
                {/* Left Side - Event Details */}
                <Box sx={{ flex: 1, paddingRight: { md: "20px", xs: "0px" }, marginBottom: { xs: "20px", md: "0px" } }}>
                  <Typography level="h4" sx={{ fontWeight: "bold" }}>
                    {selectedEvent?.title}
                  </Typography>
                  <Typography>
                    <strong>Event type:</strong>{" "}
                    <span style={{ color: selectedEvent?.backgroundColor, fontWeight: "bold" }}>
                      {selectedEvent?.event_type}
                    </span>
                  </Typography>
                  <Typography>
                    <strong>Case:</strong> {selectedEvent?.case_name}
                  </Typography>
                  <Typography>
                    <strong>Description:</strong>{" "}
                    {selectedEvent?.description || "No description available"}
                  </Typography>
                  <Typography>
                    <strong>Start:</strong>{" "}
                    {moment(selectedEvent?.start).format("ddd, MMM D, YYYY, h:mm A")}
                  </Typography>
                  <Typography>
                    <strong>End:</strong>{" "}
                    {moment(selectedEvent?.end).format("ddd, MMM D, YYYY, h:mm A")}
                  </Typography>

                  <Divider sx={{ margin: "15px 0" }} />
                  <Typography sx={{ fontWeight: "bold", marginBottom: "5px" }}>Shared / Attending</Typography>
                  <Box>
                    {selectedEvent?.staff_name?.split(",").map((attendee, index) => (
                      <Typography key={index} sx={{ color: "text.primary" }}>
                        {attendee.trim()}
                      </Typography>
                    ))}
                  </Box>
                </Box>

                {/* Right Side - Comments and History */}
                <Box
                  sx={{
                    flex: 1,
                    borderLeft: { md: "1px solid #ddd", xs: "none" },
                    paddingLeft: { md: "20px", xs: "0px" },
                  }}
                >
                  <Button
                    onClick={() => setShowCommentForm(!showCommentForm)}
                    variant="outlined"
                    sx={{ marginBottom: "10px" }}
                  >
                    Add a Comment
                  </Button>
                  {showCommentForm && (
                    <Box>
                      <CKEditor
                        editor={ClassicEditor}
                        data={commentText}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setCommentText(data);
                        }}
                      />
                      <Button onClick={handlePostComment} variant="contained" sx={{ marginTop: "10px" }}>
                        Post
                      </Button>
                    </Box>
                  )}

                  <Divider sx={{ margin: "15px 0" }} />
                  <Typography sx={{ fontWeight: "bold", marginBottom: "5px" }}>History</Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    No history to show at this time.
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          {/* Fixed Bottom Section - Edit & Delete Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              borderTop: "1px solid #ddd",
              paddingTop: "10px",
              backgroundColor: "white",
              position: "sticky",
            }}
          >
            <Button onClick={() => setIsEditing(true)} color="primary" variant="contained">
              Edit
            </Button>
            <Button onClick={() => handleEventDelete(selectedEvent.id)} color="danger" variant="outlined">
              Delete Event
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </Sheet>
  );
};

export default CalendarPage;