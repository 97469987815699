import React, { useState } from "react";
import {
  CssBaseline,
  GlobalStyles,
  Box,
  Button,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListDivider,
} from "@mui/joy";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import CaseListReport from "./CaseContactReport/CaseListReport";
import { ChartBarIcon } from "@heroicons/react/24/solid";
import UserTimeExpenses from "./ProductivityReport/UserTimeExpense";
import ContactReport from "./CaseContactReport/ContactReport";
import StatuteReport from "./CaseContactReport/StatuteLimitation";

const AllReport = () => {
  const navigate = useNavigate();
  const [activeReport, setActiveReport] = useState("Case List Report");

//   const [activeReport, setActiveReport] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);

  const reports = {
    
    "Case & Contact Reports": [
      "Case List Report",
      "Contact Report",
      "Statute of Limitations",
    ],
    "Financial Reports": [
      "Aging Invoices",
      "Accounts Receivable",
      "Case Balance Summary",
      "Case Revenue",
      "Fee Allocation",
      "Trust Account Summary",
      "Trust Account Activity",
    ],
   
    "Productivity Reports": [
      "User Time & Expenses",
      "Firm Time & Expenses",
      "Case Time & Expenses",
    ],
    "Leads Reports": [
      "Consultation Fee Revenue",
      "Leads Referral Source",
      "Leads Forecasted Pipeline Value",
    ],
  };

  const handleReportClick = (item) => {
    setActiveReport(item);
  };

  return (
    <>
     <Box sx={{background:"#ffffff", padding:"1rem", borderRadius:"25px", marginTop:"1rem"}}>
      <CssBaseline />
      <GlobalStyles styles={{ body: { margin: 0, padding: 0 } }} />

      {/* AllReport */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#f8f9fa",
          padding: "10px 20px",
          boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
        }}
      >
           <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <ChartBarIcon style={{ width: "24px", height: "24px", color: "#000" }} />
      <Typography level="h4" sx={{ fontWeight: "bold" }}>
        Reports
      </Typography>
    </div>

        {/* Desktop Navigation */}
        <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
          {Object.entries(reports).map(([category, items]) => (
            <Dropdown key={category}>
              <MenuButton>{category}</MenuButton>
              <Menu>
                {items.map((item) => (
                  <MenuItem
                    key={item}
                    onClick={() => handleReportClick(item)}
                    sx={{
                      backgroundColor: activeReport === item ? "#d0e0ff" : "white",
                    }}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Menu>
            </Dropdown>
          ))}
        </Box>

        {/* Mobile Menu Button */}
        <IconButton
          sx={{ display: { xs: "block", md: "none" } }}
          onClick={() => setMobileOpen(true)}
        >
          <MenuIcon />
        </IconButton>
      </Box>

      {/* Mobile Drawer */}
      <Drawer open={mobileOpen} onClose={() => setMobileOpen(false)}>
        <Box sx={{ width: 250, padding: 2 }}>
          <Typography level="h5" sx={{ fontWeight: "bold", marginBottom: 1 }}>
            📊 Reports
          </Typography>
          <List>
            {Object.entries(reports).map(([category, items]) => (
              <React.Fragment key={category}>
                <Typography level="body2" sx={{ fontWeight: "bold", marginTop: 1 }}>
                  {category}
                </Typography>
                {items.map((item) => (
                  <ListItem
                    key={item}
                    onClick={() => {
                      handleReportClick(item);
                      setMobileOpen(false);
                    }}
                    sx={{
                      backgroundColor: activeReport === item ? "#d0e0ff" : "transparent",
                      cursor: "pointer",
                      padding: "6px",
                      borderRadius: "4px",
                      "&:hover": { backgroundColor: "#f0f0f0" },
                    }}
                  >
                    {item}
                  </ListItem>
                ))}
                <ListDivider />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Conditionally render the CaseListReport component */}
      {activeReport === "Case List Report" && <CaseListReport />}
      {activeReport === "User Time & Expenses" && <UserTimeExpenses/>}
      {activeReport === "Contact Report" && <ContactReport/>}
      {activeReport === "Statute of Limitations" && <StatuteReport/>}
      </Box>
    </>
  );
};

export default AllReport;