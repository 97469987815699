import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemContent,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  Modal,
  ModalDialog,
  ModalClose,
  TextField,
  Container,
  Sheet,
} from "@mui/joy";
import ContactInfoTab from "../components/ContactInfoTab";

const ContactDetails = () => {
  const { id } = useParams();
  const [contact, setContact] = useState(null);
  const [privateNotes, setPrivateNotes] = useState([]);
  const [associatedCases, setAssociatedCases] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const contactResponse = await axios.get(
          `/contacts/${id}`
        );
        const contactData = contactResponse.data;
        setContact(contactData);

        if (contactData.case_id) {
          const caseIds = contactData.case_id.split(",").map((id) => id.trim());
          const casesResponse = await axios.get(
            `/contacts/${id}/cases`,
            { params: { ids: caseIds } }
          );
          setAssociatedCases(casesResponse.data || []);
        }
      } catch (error) {
        console.error("Error fetching contact details or associated cases:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchContactDetails();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContact((prevContact) => ({ ...prevContact, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await axios.put(`/contacts/${id}`, contact);
      alert("Contact updated successfully!");
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating contact:", error);
      alert("Failed to update contact.");
    }
  };

  if (loading) {
    return <Typography align="center">Loading contact details...</Typography>;
  }

  if (!contact) {
    return <Typography align="center">Contact not found.</Typography>;
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {/* Sidebar Section */}
        <Grid item xs={12} md={4}>
          <Sheet variant="outlined" sx={{ p: 3 }}>
            <Typography level="h5">Contact Info</Typography>
            <Typography>
              <strong>Name:</strong> {contact.first_name} {contact.last_name}
            </Typography>
            <Typography>
              <strong>Email:</strong> {contact.email || "N/A"}
            </Typography>
            <Typography>
              <strong>Group:</strong> {contact.contact_group || "N/A"}
            </Typography>
            <Button
              fullWidth
              color="primary"
              sx={{ mt: 2 }}
              onClick={() => setIsModalOpen(true)}
            >
              Edit Contact
            </Button>
          </Sheet>

          {/* Associated Cases */}
          <Sheet variant="outlined" sx={{ p: 3, mt: 2 }}>
            <Typography level="h5">Associated Cases</Typography>
            <List>
              {associatedCases.length > 0 ? (
                associatedCases.map((caseItem) => (
                  <ListItem key={caseItem.case_id} component={Link} to={`/cases/${caseItem.case_id}`}>
                    <ListItemContent>
                      <Typography level="body1">
                        {caseItem.case_name || "Unnamed Case"}
                      </Typography>
                    </ListItemContent>
                  </ListItem>
                ))
              ) : (
                <Typography>No associated cases found.</Typography>
              )}
            </List>
          </Sheet>
        </Grid>

        {/* Main Content Section */}
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography level="h3">
                {contact.first_name} {contact.last_name}{" "}
                <Typography component="span" level="body2">
                  ({contact.contact_group || "No Group"})
                </Typography>
              </Typography>

              {/* Tabs */}
              <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)}>
                <TabList>
                  <Tab>Info</Tab>
                  <Tab>Cases</Tab>
                  <Tab>Activity</Tab>
                  <Tab>Notes</Tab>
                </TabList>

                {/* Tab Content */}
                <TabPanel value={0}>
                  <ContactInfoTab contact={contact} privateNotes={privateNotes} />
                </TabPanel>
                <TabPanel value={1}>
                  <Typography level="h5">Associated Cases</Typography>
                  <List>
                    {associatedCases.length > 0 ? (
                      associatedCases.map((caseItem) => (
                        <ListItem key={caseItem.case_id} component={Link} to={`/cases/${caseItem.case_id}`}>
                          <ListItemContent>
                            <Typography level="body1">
                              {caseItem.case_name || "Unnamed Case"}
                            </Typography>
                            <Typography level="body2">
                              Stage: {caseItem.case_stage || "N/A"} | Practice Area: {caseItem.practice_area || "N/A"}
                            </Typography>
                          </ListItemContent>
                        </ListItem>
                      ))
                    ) : (
                      <Typography>No associated cases found.</Typography>
                    )}
                  </List>
                </TabPanel>
                <TabPanel value={2}>
                  <Typography>Activity details go here.</Typography>
                </TabPanel>
                <TabPanel value={3}>
                  <Typography>Notes content goes here.</Typography>
                </TabPanel>
              </Tabs>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Edit Contact Modal */}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalDialog>
          <ModalClose onClick={() => setIsModalOpen(false)} />
          <Typography level="h5">Edit Contact Information</Typography>
          {Object.keys(contact).map((key) => (
            <TextField
              key={key}
              fullWidth
              margin="dense"
              label={key.replace(/_/g, " ")}
              name={key}
              value={contact[key] || ""}
              onChange={handleInputChange}
            />
          ))}
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 2 }}>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
            <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          </Box>
        </ModalDialog>
      </Modal>
    </Container>
  );
};

export default ContactDetails;