import React, { useEffect, useState } from "react";
import { Card, Typography, Box, Stack, IconButton, CircularProgress } from "@mui/joy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import axios from "axios";

const EventCard = ({ caseId }) => {
  const [expanded, setExpanded] = useState(false);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!caseId) return;

    const fetchEvents = async () => {
      setLoading(true);
      setError(null);
      setEvents([]); // Reset events before fetching

      try {
        const today = new Date().toISOString().split("T")[0];
        const nextYear = new Date();
        nextYear.setFullYear(nextYear.getFullYear() + 1);
        const nextYearDate = nextYear.toISOString().split("T")[0];

        const response = await axios.get(`/api/eventsCaseDetail`, {
          params: { case_id: caseId, start_date: today, end_date: nextYearDate },
        });

        if (!response.data || !Array.isArray(response.data.events)) {
          throw new Error("Invalid response format");
        }

        setEvents(response.data.events);
      } catch (err) {
        console.error("Error fetching events:", err);
        setError(err.response?.data?.message || "Failed to fetch events");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [caseId]);

  return (
    <Card sx={{ maxWidth: 400, p: 2, borderRadius: "md", boxShadow: "lg", mt: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography level="title-md" sx={{ fontWeight: "bold" }}>
          Events <Typography sx={{ fontSize: "sm", color: "gray" }}>(Next 365 days)</Typography>
        </Typography>
        <IconButton size="sm" onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="danger" mt={2}>
          {error}
        </Typography>
      ) : (
        <>
          <Typography level="h2" sx={{ fontWeight: "bold", mt: 1 }}>
            {events.length}
          </Typography>

          {expanded && (
            <Box mt={2} sx={{ maxHeight: 250, overflowY: "auto", borderRadius: "sm", p: 1, bgcolor: "background.level2" }}>
              {events.length > 0 ? (
                <Stack spacing={1}>
                  {events.map((event) => (
                    <Box key={event.id} sx={{ p: 1, borderRadius: "sm", bgcolor: "background.level1" }}>
                      <Typography level="body-md" sx={{ fontWeight: "bold" }}>
                        {event.title}
                      </Typography>
                      <Typography sx={{ fontSize: "sm", color: "gray" }}>{event.description}</Typography>
                      <Typography sx={{ fontSize: "xs", color: "gray" }}>
                        {event.start ? new Date(event.start).toLocaleDateString() : "No start date"} -{" "}
                        {event.end ? new Date(event.end).toLocaleDateString() : "No end date"}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              ) : (
                <Typography>No events available</Typography>
              )}
            </Box>
          )}
        </>
      )}
    </Card>
  );
};

export default EventCard;
