import React, { useState, useEffect } from "react";
import {
    Modal,
    Box,
    Button,
    Select,
    Option,
    Switch,
    Tab,
    TabList,
    Tabs,
    TabPanel,
    FormControl,
    FormLabel,
    Input,
    Typography,
    Autocomplete,
    TextField,
} from "@mui/joy";
import axios from "axios";
import AddCaseModal from "./AddCaseModal";
import { auth } from "../firebase/firebase";

export default function AddTimeEntryModal({ open, onClose, caseId, parentType, editData, singleCase, cases: initialCases, onSuccess, initialDescription, initialDuration, onSuccessModal }) {
    const [activeTab, setActiveTab] = useState(0);
    const [billable, setBillable] = useState(true);
    const [date, setDate] = useState("2025-03-01");
    const [rate, setRate] = useState("150.00");
    const [description, setDescription] = useState(initialDescription || "");
    // const [user, setUser] = useState("");
    const [activity, setActivity] = useState("");
    const [rateType, setRateType] = useState("/hr");
    const [duration, setDuration] = useState(initialDuration || "");
    const [customFields, setCustomFields] = useState([]);
    // const [cases, setCases] = useState([]);
    const [selectedCase, setSelectedCase] = useState(caseId);
    const [cases, setCases] = useState(initialCases || []);
    const [searchTerm, setSearchTerm] = useState('');
    const [caseColumns, setCaseColumns] = useState([]);
    const [customDetails, setCustomDetails] = useState({});
    const [showCustomFields, setShowCustomFields] = useState(false);
    const [isAddCaseModalOpen, setIsAddCaseModalOpen] = useState(false);
    const [errors, setErrors] = useState({});

    const handleTabChange = (event, newTab) => setActiveTab(newTab);
    const toggleCustomFields = () => setShowCustomFields((prev) => !prev);
    const [activities, setActivities] = useState([]);

    const [isAddingNewActivity, setIsAddingNewActivity] = useState(false);
    const [newActivity, setNewActivity] = useState('');
    const [user, setUser] = useState("");
    const [activeUsers, setActiveUsers] = useState([]);

    const loggedInUidd = auth.currentUser?.uid;
    const fetchUsers = async () => {
        try {
            const response = await axios.get("/active-users");
            setActiveUsers(response.data.activeUsers);

            const loggedInUser = response.data.activeUsers.find(
                (u) => u.uid === loggedInUidd
            );

            if (loggedInUser) {
                setUser(loggedInUser.staff_id);
            }
        } catch (error) {
            console.error("Error fetching active users:", error);
        }
    };
    useEffect(() => {
        if (open) {
            fetchUsers();
        }
fetchUsers();

    }, [open,loggedInUidd]);
    // Fetch activities from API
    useEffect(() => {
        axios.get('/activity')
            .then(response => {
                setActivities(response.data);
            })
            .catch(error => {
                console.error('Error fetching activities:', error);
            });
    }, []);

    const handleActivityChange = (event, value) => {
        setActivity(value);  // value will be the `activity_name` in this case
    };
    useEffect(() => {
        if (searchTerm) {
            fetchCases(searchTerm);
        } else {
            setCases(initialCases || []); // Reset to initial cases if search is empty
        }
    }, [searchTerm, initialCases]);

    const fetchCases = async (search) => {
        try {
            const response = await axios.get(`/cases?search=${encodeURIComponent(search)}`);
            setCases(response?.data?.cases || []);
        } catch (error) {
            console.error('Error fetching cases:', error);
            setCases([]);
        }
    };

    useEffect(() => {
        // const filteredCase = cases?.find(caseItem => Number(caseItem?.case_id) === Number(caseId));
        if (singleCase) {
            setSelectedCase(singleCase?.case_id || "");
        }
    }, [singleCase]);

    useEffect(() => {
        const fetchCaseColumns = async () => {
            try {
                const response = await axios.get(`/columns?parent_type=${parentType}`);
                const { table_columns, custom_fields } = response.data;

                const filteredColumns = table_columns.filter(
                    (col) => col !== "case_id" && col !== "created_at" && col !== "updated_at" && col !== "name"
                );

                setCaseColumns(filteredColumns || []);
                setCustomFields(custom_fields || []);
            } catch (error) {
                console.error("Error fetching case columns:", error);
            }
        };

        if (parentType) {
            fetchCaseColumns();
        }
    }, [parentType]);


    useEffect(() => {
        if (editData) {

            setSelectedCase(editData.case_id || "");
            setUser(editData.staff_id || "");
            setActivity(editData.activity_name || "");
            setRateType(editData.rate_type || "/hr");
            setDescription(editData.description || "");
            setDate(editData.entry_date ? editData.entry_date.split("T")[0] : "2025-03-01");
            setRate(editData.rate || "150.00");
            setBillable(editData.billable ?? true);
            setDuration(editData.hours || "");

            // Pre-populate custom fields if editData contains them
            const initialCustomDetails = {};
            (editData.custom_fields || []).forEach(field => {
                initialCustomDetails[field.custom_fields_id] = field.value;
            });
            setCustomDetails(initialCustomDetails);
        } else {
            // Clear fields when opening for new entry
            setUser("");
            setActivity("");
            setRateType("/hr");
            setDescription("");
            setDate("2025-03-01");
            setRate("150.00");
            setBillable(true);
            setDuration("");
            setCustomDetails({});
        }
    }, [editData, open]);

    const validateForm = () => {
        const newErrors = {};

        if (!selectedCase) newErrors.case = "Case is required.";
        if (!user) newErrors.user = "User is required.";
        if (!activity && !newActivity) newErrors.activity = "Activity is required.";
        if (!date) newErrors.date = "Date is required.";
        if (!rate) newErrors.rate = "Rate is required.";
        if (!rateType) newErrors.rateType = "Rate Type is required.";
        if (!duration) newErrors.duration = "Duration is required.";
        if (!description) newErrors.description = "Description is required.";

        setErrors(newErrors);

        // Return true if no errors
        return Object.keys(newErrors).length === 0;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            console.warn("Form validation failed!");
            return;
        }
        const activityNameToSend = isAddingNewActivity ? newActivity : activity;

        const formData = {
            case_id: selectedCase,
            staff_id: user,
            activity_name: activityNameToSend,
            rate_type: rateType,
            description,
            entry_date: date,
            rate,
            billable,
            hours: duration,
        };

        try {
            if (isAddingNewActivity) {
                await axios.post("/activity", { activity_name: newActivity });
            }

            if (editData && editData.time_entry_id) {
                await axios.put(`/time_entries/${editData.time_entry_id}`, formData);
            } else {
                await axios.post("/time_entries", { ...formData, uid: auth.currentUser.uid });
            }

            console.log("Entry submitted successfully!");
            onClose();    // Close the modal
            onSuccessModal();
            if (onSuccess) onSuccess();   // Call parent callback to refresh data
        } catch (error) {
            console.error("Error submitting time entry:", error);
            setErrors({ form: "Failed to save entry. Please try again." });
        }
    };

    useEffect(() => {
        if (open) {   // Only reset when the modal opens
            setDescription(initialDescription || "");
            setDuration(initialDuration || "");
        }
    }, [open, initialDescription, initialDuration]);

    useEffect(() => {
        if (editData) {
            setDescription(editData.description || "");
            setDuration(editData.hours || "");
        } else {
            setDescription(initialDescription || "");
            setDuration(initialDuration || "");
        }
    }, [editData, initialDescription, initialDuration]);

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box
                    sx={{
                        width: { xs: "90%", sm: "80%", md: "500px" },
                        bgcolor: "white",
                        p: 3,
                        m: "auto",
                        // mt: { xs: 2, sm: 10 },
                        borderRadius: 2,
                        maxHeight: "90vh",
                        overflowY: "auto",
                        boxShadow: 24,
                        border: "1px solid #e0e0e0",
                    }}
                >
                    <Typography level="h5" sx={{ mb: 2 }}>
                        {editData ? "Edit Time Entry" : "Add Time Entry"}

                    </Typography>

                    <Tabs value={activeTab} onChange={handleTabChange} sx={{ mt: 2 }}>
                        <TabList>
                            <Tab value={0}>Single</Tab>
                        </TabList>

                        <TabPanel value={0}>
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ mb: 2 }}>
                                    <FormControl fullWidth>
                                        <FormLabel>Case</FormLabel>
                                        <Autocomplete
                                            fullWidth
                                            options={cases.filter(caseItem =>
                                                caseItem.name.toLowerCase().includes(searchTerm.toLowerCase())
                                            )}
                                            getOptionLabel={(option) => option.name}
                                            value={cases.find((c) => c.case_id === selectedCase) || null}
                                            onChange={(e, value) => setSelectedCase(value?.case_id || "")}
                                            inputValue={searchTerm}
                                            onInputChange={(e, newInputValue) => setSearchTerm(newInputValue)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Search case..." placeholder="Type to search" error={!!errors.case} />
                                            )}
                                        />
                                        {errors.case && (
                                            <Typography sx={{ color: "#D32F2F", fontSize: "0.875rem", mt: 0.5 }}>
                                                {errors.case}
                                            </Typography>
                                        )}

                                    </FormControl>
                                    <Button variant="outlined" sx={{ mt: 1 }} onClick={() => setIsAddCaseModalOpen(true)}>
                                        Add Case
                                    </Button>
                                </Box>

                                <Box sx={{ mb: 2 }}>
                                    <FormControl fullWidth>
                                        <FormLabel>User</FormLabel>
                                        <Select
                                            value={user}
                                            onChange={(e, value) => setUser(value)}
                                        >
                                            {activeUsers &&
                                                activeUsers?.map((u) => (
                                                    <Option key={u.staff_id} value={u.staff_id}>
                                                        {u.first_name}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Box sx={{ mb: 2 }}>
                                    <FormControl fullWidth>
                                        <FormLabel>Activity</FormLabel>
                                        {isAddingNewActivity ? (
                                            <Input
                                                value={newActivity}
                                                onChange={(e) => setNewActivity(e.target.value)}
                                                placeholder="Enter new activity"
                                                fullWidth
                                            />
                                        ) : (
                                            <Autocomplete
                                                options={activities}
                                                getOptionLabel={(option) => option.activity_name}
                                                value={activities.find(act => act.activity_name === activity) || null}
                                                onChange={(e, newValue) => {
                                                    setActivity(newValue ? newValue.activity_name : '');
                                                }}
                                                renderInput={(params) => <TextField {...params} placeholder="Select activity" error={!!errors.activity} />}
                                            />
                                        )}
                                        {errors.activity && (
                                            <Typography
                                                sx={{ color: "#D32F2F", fontSize: "0.875rem", mt: 0.5 }}
                                            >
                                                {errors.activity}
                                            </Typography>
                                        )}
                                    </FormControl>

                                    <Button
                                        variant="outlined"
                                        sx={{ mt: 1 }}
                                        onClick={() => setIsAddingNewActivity(!isAddingNewActivity)}
                                    >
                                        {isAddingNewActivity ? 'Cancel' : 'Add New Activity'}
                                    </Button>
                                </Box>

                                <Box sx={{ mb: 2 }}>
                                    <FormControl>
                                        <label>
                                            <Switch
                                                checked={billable}
                                                onChange={() => setBillable(!billable)}
                                            /> This time entry is billable.
                                        </label>
                                    </FormControl>
                                </Box>

                                <Box sx={{ mb: 2 }}>
                                    <FormControl fullWidth>
                                        <FormLabel>Description</FormLabel>
                                        <Input
                                            multiline
                                            minRows={3}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            placeholder="Enter description"
                                            error={!!errors.description}
                                        />
                                        {errors.description && (
                                            <Typography
                                                sx={{ color: "#D32F2F", fontSize: "0.875rem", mt: 0.5 }}
                                            >
                                                {errors.description}
                                            </Typography>
                                        )}
                                    </FormControl>
                                    <Typography level="body2" sx={{ mt: 1 }}>
                                        This description will appear on invoices.
                                    </Typography>
                                </Box>

                                <Button variant="outlined" onClick={toggleCustomFields} sx={{ mt: 1 }}>
                                    {showCustomFields ? "Hide Custom Fields" : "Add Custom Fields"}
                                </Button>
                                {showCustomFields && (
                                    <Box sx={{ mt: 2 }}>
                                        {/* Render Table Columns */}
                                        {caseColumns.map((column) => (
                                            <Input
                                                key={column}
                                                placeholder={column.replace(/_/g, " ")} // Convert snake_case to readable format
                                                value={customDetails[column] || ""}
                                                onChange={(e) =>
                                                    setCustomDetails({ ...customDetails, [column]: e.target.value })
                                                }
                                                fullWidth
                                                sx={{ my: 1 }}
                                            />
                                        ))}

                                        {/* Render Custom Fields */}
                                        {customFields.map((field) => (
                                            <Box key={field.custom_fields_id} sx={{ my: 1 }}>
                                                <Typography level="body2" sx={{ mb: 1 }}>
                                                    {field.custom_fields_name}
                                                </Typography>

                                                {field.field_type === "list" ? (
                                                    <Select
                                                        value={customDetails[field.custom_fields_id] || ""}
                                                        onChange={(e, newValue) =>
                                                            setCustomDetails({ ...customDetails, [field.custom_fields_id]: newValue })
                                                        }
                                                        fullWidth
                                                    >
                                                        <Option value="">Select an option</Option>
                                                        {field.list_options.map((option) => (
                                                            <Option key={option.list_options_id} value={option.list_options_id}>
                                                                {option.option_value}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                ) : (
                                                    <Input
                                                        type={field.field_type === "date" ? "date" : "text"}
                                                        placeholder={field.custom_fields_name}
                                                        value={customDetails[field.custom_fields_id] || ""}
                                                        onChange={(e) =>
                                                            setCustomDetails({ ...customDetails, [field.custom_fields_id]: e.target.value })
                                                        }
                                                        fullWidth
                                                    />
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                                <Box sx={{ mt: 3 }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: { xs: "column", sm: "row" },
                                            alignItems: "center",
                                            gap: 2,
                                        }}
                                    >
                                        <FormControl sx={{ width: { xs: "100%", sm: 120 } }}>
                                            <FormLabel>Date</FormLabel>
                                            <Input
                                                value={date}
                                                onChange={(e) => setDate(e.target.value)}
                                                type="date"
                                            />
                                        </FormControl>

                                        <FormControl sx={{ width: { xs: "100%", sm: 120 } }}>
                                            <FormLabel>Rate</FormLabel>
                                            <Input
                                                value={rate}
                                                onChange={(e) => setRate(e.target.value)}
                                            />
                                        </FormControl>

                                        <FormControl sx={{ width: { xs: "100%", sm: 120 } }}>
                                            <FormLabel>Rate Type</FormLabel>
                                            <Select value={rateType} onChange={(e, value) => setRateType(value)}>
                                                <Option value="/hr">/hr</Option>
                                                <Option value="flat">flat</Option>
                                            </Select>
                                        </FormControl>

                                        <FormControl sx={{ width: { xs: "100%", sm: 120 } }}>
                                            <FormLabel>Duration</FormLabel>
                                            <Input
                                                value={duration}
                                                onChange={(e) => setDuration(e.target.value)}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ mt: 1, textAlign: "right" }}>0.1 = 6 minutes</Box>
                                </Box>

                                <Box
                                    sx={{
                                        mt: 3,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: { xs: "column", sm: "row" },
                                        gap: 2,
                                    }}
                                >
                                    <Button type="submit" variant="outlined" fullWidth={false}>
                                        {editData ? "Update" : "Save"}</Button>


                                    <Button variant="outlined" fullWidth={false}>
                                        Save and New
                                    </Button>
                                </Box>
                            </form>
                        </TabPanel>
                    </Tabs>
                </Box>
            </Modal>
            <AddCaseModal open={isAddCaseModalOpen}
                parentType="case"
                onClose={() => setIsAddCaseModalOpen(false)}
            />
        </>
    );
}