// AddDocumentModal.js
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Modal,
  ModalDialog,
  ModalClose,
  Typography,
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Option,
  Textarea
} from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import { auth } from "../firebase/firebase";

const AddDocumentModal = ({ open, onClose, defaultCaseId }) => {
  // States for document fields
  const [notLinked, setNotLinked] = useState(false); // "This document is not linked to a case"
  const [caseId, setCaseId] = useState("");             // Selected case ID
  const [docName, setDocName] = useState("");           // Document Name
  const [assignedDate, setAssignedDate] = useState("");   // Assigned Date
  const [fileSource, setFileSource] = useState(null);     // Single file to upload
  const [tags, setTags] = useState("");                 // Tags
  const [description, setDescription] = useState("");   // Description

  // For file input styling
  const fileInputRef = useRef(null);

  // Fetch real cases from backend for the case link dropdown
  const [cases, setCases] = useState([]);
  const [loadingCases, setLoadingCases] = useState(false);
  const [caseSearchTerm, setCaseSearchTerm] = useState("");
  const currentUser = auth.currentUser?.uid;

  // When the modal opens, set the default case ID if provided
  useEffect(() => {
    if (open && defaultCaseId) {
      setCaseId(defaultCaseId);
    }
  }, [open, defaultCaseId]);

  useEffect(() => {
    if (!open) return;
    const fetchCases = async () => {
      setLoadingCases(true);
      try {
        const API_BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3001";
        const res = await axios.get(`${API_BASE_URL}/cases`, { params: { search: caseSearchTerm } });
        setCases(res.data.cases || []);
      } catch (error) {
        console.error("Error fetching cases:", error);
      } finally {
        setLoadingCases(false);
      }
    };
    fetchCases();
  }, [open, caseSearchTerm]);

  // Handler for file selection; only one file is allowed.
  const handleFileChange = (e) => {
    setFileSource(e.target.files[0] || null);
  };

  // Handler for uploading the document
  const handleUpload = async () => {
    try {
      const finalCaseId = notLinked ? "unlinked" : caseId;
      if (!finalCaseId) {
        alert("Please select a case or check 'not linked' to proceed.");
        return;
      }
      if (!fileSource) {
        alert("Please select a file to upload.");
        return;
      }
      // Build FormData and append file and metadata
      const formData = new FormData();
      formData.append("document", fileSource);
      formData.append("name", docName);
      formData.append("description", description);
      formData.append("assigned_date", assignedDate);
      formData.append("tags", tags);

      const API_BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3001";
      const endpoint = `/cases/${finalCaseId}/documents`;

      const response = await axios.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-user-uid": currentUser
        },
      });
      console.log("Upload response:", response.data);
      alert("Document uploaded successfully!");

      // Reset form fields
      setNotLinked(false);
      setCaseId(defaultCaseId || "");
      setDocName("");
      setAssignedDate("");
      setFileSource(null);
      setTags("");
      setDescription("");
      onClose();
    } catch (error) {
      console.error("Error uploading document:", error);
      alert("Error uploading document. Check console for details.");
    }
  };

  // Toggle "not linked" checkbox
  const handleNotLinkedCheck = (checked) => {
    setNotLinked(checked);
    if (checked) {
      setCaseId("");
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        sx={{
          maxWidth: "80vw",
          maxHeight: "80vh",
          overflowY: "auto",
          borderRadius: "md",
          p: 2,
        }}
      >
        <ModalClose onClick={onClose} />
        <Typography level="h5" mb={2} fontWeight="bold">
          Upload New Document
        </Typography>

        {/* Case Link */}
        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Case Link</FormLabel>
          <Checkbox
            label="This document is not linked to a case"
            checked={notLinked}
            onChange={(e) => handleNotLinkedCheck(e.target.checked)}
            sx={{ mb: 1 }}
          />
          <Input
            placeholder="Search for a case..."
            value={caseSearchTerm}
            onChange={(e) => setCaseSearchTerm(e.target.value)}
            sx={{ mb: 1 }}
          />
          <Select
            disabled={notLinked || loadingCases}
            placeholder={loadingCases ? "Loading cases..." : "No case selected"}
            value={caseId}
            onChange={(event, newValue) => setCaseId(newValue)}
          >
            <Option value="">No case selected</Option>
            {cases.map((c) => (
              <Option key={c.case_id} value={String(c.case_id)}>
                {c.name || `Case #${c.case_id}`}
              </Option>
            ))}
          </Select>
        </FormControl>

        {/* Document Name */}
        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Document Name</FormLabel>
          <Input
            placeholder="Enter document name"
            value={docName}
            onChange={(e) => setDocName(e.target.value)}
          />
        </FormControl>

        {/* Assigned Date */}
        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Assigned Date</FormLabel>
          <Input
            type="date"
            value={assignedDate}
            onChange={(e) => setAssignedDate(e.target.value)}
          />
        </FormControl>

        {/* Source – File Input */}
        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Source</FormLabel>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Button variant="outlined" onClick={() => fileInputRef.current.click()}>
              Choose File
            </Button>
            <Typography level="body2">
              {fileSource ? fileSource.name : "No file selected"}
            </Typography>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Box>
        </FormControl>

        {/* Tags */}
        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Tags</FormLabel>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Input
              placeholder="Enter tags"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
            />
            <Button variant="plain" size="sm">
              Browse All
            </Button>
          </Box>
        </FormControl>

        {/* Description */}
        <FormControl sx={{ mb: 2 }}>
          <FormLabel>Description</FormLabel>
          <Textarea
            minRows={2}
            placeholder="This description will be viewable by anyone this document is shared with."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormControl>

        {/* Actions */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button variant="soft" onClick={onClose}>
            Cancel
          </Button>
          <Button startDecorator={<AddIcon />} onClick={handleUpload}>
            Upload New Document
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default AddDocumentModal;