import React, { useState, useEffect } from 'react';
import axios from 'axios';
import IconButton from '@mui/joy/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import './NotesTab.css';
import AddNoteModal from './AddNoteModal';
import EditNoteModal from './EditNoteModa';
import { Button } from '@mui/joy';

const NotesTab = ({ caseId, refreshNotes, cases, case_id_time }) => {
  // Local state for notes.
  const [notes, setNotes] = useState([]);
  const [page, setPage] = useState(1);
const [totalNotes, setTotalNotes] = useState(0);
const limit = 20;
  // Track which note is expanded.
  const [expandedIndex, setExpandedIndex] = useState(null);
  // State for editing a note.
  const [editingNote, setEditingNote] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  // State to control AddNoteModal.
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [singleCase, setSingleCase]= useState(null);
  // Fetch notes from /case_notes endpoint using caseId.
  const fetchNotes = async () => {
    try {
      const response = await axios.get('/case_notes', {
        params: { case_id: caseId, page, limit: 20 } // Add pagination parameters
      });
      console.log("Fetched notes:", response.data);
      setNotes(response.data.caseNotes);
      setTotalNotes(response.data.totalNotes); // Store total notes for pagination
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };
  const fetchCases = async () => {
    try {
        const response = await axios.get(`/cases/${case_id_time}`);
        
        setSingleCase(response.data);
    } catch (error) {
        console.error("Error fetching cases:", error);
        setSingleCase([]);
    }
};
useEffect(()=>{
  fetchCases()
},[])
  // Re-fetch notes when `caseId` or `page` changes
  useEffect(() => {
    if (caseId) {
      fetchNotes();
    }
  }, [caseId, page]); // Add `page` as a dependency
  

  useEffect(() => {
    console.log("Current notes in state:", notes);
  }, [notes]);

  const toggleNote = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleEdit = (note) => {
    console.log("Attempting to edit note:", note);
    // Check for id in the note object.
    if (!note.id) {
      console.error("Cannot edit note: note.id is undefined", note);
      return;
    }
    setEditingNote(note);
    setEditModalOpen(true);
  };

  const handleDelete = async (noteId) => {
    if (!noteId) {
      console.error("No note id provided for deletion.");
      return;
    }
    try {
      await axios.delete(`/case_notes/${noteId}`);
      setNotes(notes.filter(n => n.id !== noteId));
      if (refreshNotes) refreshNotes();
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  // Handler for updated note from the edit modal.
  const handleNoteUpdate = async (updatedNote) => {
    try {
      const response = await axios.put(`/case_notes/${updatedNote.id}`, {
        subject: updatedNote.subject,
        note: updatedNote.note,
        date: new Date().toISOString().slice(0, 10),
        case_id:updatedNote.case_id,
      });
      fetchNotes();
      setNotes(notes.map(n => (n.id === updatedNote.id ? response.data : n)));
      if (refreshNotes) refreshNotes();
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };

  return (
    <div className="notes-container">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography level="h2">Notes</Typography>
        <IconButton onClick={() => setAddModalOpen(true)}>
          <AddIcon />
        </IconButton>
      </Box>

      <div>
    {notes?.length > 0 ? (
      notes?.map((item, index) => (
        <div key={item.id || index} className="note-item">
          <div className="note-header" onClick={() => toggleNote(index)}>
            <div className="note-subject">{item.subject}</div>
            <div className="note-date">{item.date}</div>
            <div className="note-toggle-icon">
              {expandedIndex === index ? "▲" : "▼"}
            </div>
          </div>
          {expandedIndex === index && (
            <div className="note-content">
              <p dangerouslySetInnerHTML={{ __html: item.note }} />
              <div className="note-actions">
                <IconButton onClick={() => handleEdit(item)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(item.id)} color="error">
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      ))
    ) : (
      <Typography>No notes available.</Typography>
    )}

    {/* Pagination Controls */}
    <div className="pagination">
      <Button
        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
        disabled={page === 1}
      >
        Previous
      </Button>
      <span> Page {page} of {Math.ceil(totalNotes / limit)} </span>
      <Button
        onClick={() => setPage((prev) => prev + 1)}
        disabled={page * limit >= totalNotes}
      >
        Next
      </Button>
    </div>
  </div>

      {/* Add Note Modal */}
      <AddNoteModal 
        caseId={caseId} 
        cases={cases}
        case_id_time={case_id_time}
        singleCase={singleCase}
        open={addModalOpen} 
        onClose={() => setAddModalOpen(false)} 
        onNoteAdded={(newNote) => {
          setNotes([...notes, newNote]);
          if (refreshNotes) refreshNotes(newNote);
        }}
      />

      {/* Edit Note Modal */}
      <EditNoteModal 
              caseId={caseId} 
              cases={cases}
              case_id_time={case_id_time}
              singleCase={singleCase}
        open={editModalOpen}
        note={editingNote}
        onClose={() => setEditModalOpen(false)}
        onNoteUpdated={(updatedNote) => {
          handleNoteUpdate(updatedNote);
          setEditModalOpen(false);
          setEditingNote(null);
        }}
      />
    </div>
  );
};

export default NotesTab;