import React, { useState } from "react";
import axios from "axios";
import {
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Input,
  FormControl,
  FormLabel,
  Link,
  Stack,
} from "@mui/joy";

const AddContactModal = ({ open, onClose, onContactAdded }) => {
  const API_BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3001";

  // Initial state matching your DB schema:
  const initialContactState = {
    first_name: "",
    middle_name: "",
    last_name: "",
    company: "",
    job_title: "",
    home_street: "",
    home_street_2: "",
    home_city: "",
    home_state: "",
    home_postal_code: "",
    home_country: "",
    home_fax: "",
    work_phone: "",
    home_phone: "",
    mobile_phone: "",
    contact_group: "",
    email: "",
    birthday: "",
    private_notes: "",
    contact_notes: "",
    case_name: "",
    case_id: "",
    preferred_language: "",
    insurance_company: "",
    insured_property: "",
    brief_description_of_the_loss: "",
    mailing_address_if_different_from_above: "",
    have_the_claim_been_reported: "",
    policy_number: "",
    claim_number: "",
    date_of_loss: "",
    public_adjuster_if_applicable: "",
    created_date: "",
    created_by: ""
  };

  const [newContact, setNewContact] = useState(initialContactState);
  const [showMore, setShowMore] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleChange = (e) => {
    setNewContact({
      ...newContact,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // If created_date is not provided, set it to today's date (YYYY-MM-DD)
    const currentDate = new Date().toISOString().split("T")[0];
    const contactToSubmit = {
      ...newContact,
      created_date: newContact.created_date ? newContact.created_date : currentDate,
      created_by: newContact.created_by ? newContact.created_by : "admin"
    };

    try {
      await axios.post(`${API_BASE_URL}/contacts`, contactToSubmit);
      if (onContactAdded) {
        onContactAdded();
      }
      // Reset form and close the add-contact modal.
      setNewContact(initialContactState);
      onClose();
      // Open the success pop-up modal.
      setOpenSuccess(true);
    } catch (error) {
      console.error("Error adding contact:", error);
      // Optionally, you can show an error notification here.
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          sx={{
            width: 600,
            maxHeight: "80vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DialogTitle>Add New Contact</DialogTitle>
          <DialogContent
            sx={{
              flex: 1,
              overflowY: "auto",
            }}
          >
            <form id="add-contact-form" onSubmit={handleSubmit}>
              <Stack spacing={2}>
                {/* Row: First Name, Middle Name, Last Name */}
                <Stack direction="row" spacing={2}>
                  <FormControl required sx={{ flex: 1 }}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      placeholder="First name"
                      name="first_name"
                      value={newContact.first_name}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl sx={{ flex: 1 }}>
                    <FormLabel>Middle Name</FormLabel>
                    <Input
                      placeholder="Middle name"
                      name="middle_name"
                      value={newContact.middle_name}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl required sx={{ flex: 1 }}>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      placeholder="Last name"
                      name="last_name"
                      value={newContact.last_name}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>

                {/* Email */}
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input
                    placeholder="Enter email"
                    name="email"
                    type="email"
                    value={newContact.email}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Phones */}
                <FormControl required>
                  <FormLabel>Cell Phone</FormLabel>
                  <Input
                    placeholder="Enter cell phone"
                    name="mobile_phone"
                    value={newContact.mobile_phone}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Work Phone</FormLabel>
                  <Input
                    placeholder="Enter work phone"
                    name="work_phone"
                    value={newContact.work_phone}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Home Phone</FormLabel>
                  <Input
                    placeholder="Enter home phone"
                    name="home_phone"
                    value={newContact.home_phone}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Address */}
                <FormControl>
                  <FormLabel>Address</FormLabel>
                  <Input
                    placeholder="Enter address"
                    name="home_street"
                    value={newContact.home_street}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Address 2</FormLabel>
                  <Input
                    placeholder="Enter address line 2"
                    name="home_street_2"
                    value={newContact.home_street_2}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Row: City, State, Zip Code */}
                <Stack direction="row" spacing={2}>
                  <FormControl sx={{ flex: 1 }}>
                    <FormLabel>City</FormLabel>
                    <Input
                      placeholder="City"
                      name="home_city"
                      value={newContact.home_city}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl sx={{ flex: 1 }}>
                    <FormLabel>State</FormLabel>
                    <Input
                      placeholder="State"
                      name="home_state"
                      value={newContact.home_state}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl sx={{ flex: 1 }}>
                    <FormLabel>Zip Code</FormLabel>
                    <Input
                      placeholder="Zip Code"
                      name="home_postal_code"
                      value={newContact.home_postal_code}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>

                <FormControl>
                  <FormLabel>Country</FormLabel>
                  <Input
                    placeholder="Country"
                    name="home_country"
                    value={newContact.home_country}
                    onChange={handleChange}
                  />
                </FormControl>

                {/* Toggle Additional Information */}
                <Link
                  component="button"
                  onClick={() => setShowMore(!showMore)}
                  sx={{ mt: 1 }}
                >
                  {showMore ? "Hide additional information" : "Add more Information"}
                </Link>

                {/* Additional Fields */}
                {showMore && (
                  <Stack spacing={2}>
                    <FormControl>
                      <FormLabel>Company</FormLabel>
                      <Input
                        placeholder="Enter company"
                        name="company"
                        value={newContact.company}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Job Title</FormLabel>
                      <Input
                        placeholder="Enter job title"
                        name="job_title"
                        value={newContact.job_title}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Home Fax</FormLabel>
                      <Input
                        placeholder="Enter home fax"
                        name="home_fax"
                        value={newContact.home_fax}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Contact Group</FormLabel>
                      <Input
                        placeholder="Enter contact group"
                        name="contact_group"
                        value={newContact.contact_group}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Birthday</FormLabel>
                      <Input
                        placeholder="Enter birthday"
                        name="birthday"
                        value={newContact.birthday}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Private Notes</FormLabel>
                      <Input
                        placeholder="Enter private notes"
                        name="private_notes"
                        value={newContact.private_notes}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Contact Notes</FormLabel>
                      <Input
                        placeholder="Enter contact notes"
                        name="contact_notes"
                        value={newContact.contact_notes}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Case Name</FormLabel>
                      <Input
                        placeholder="Enter case name"
                        name="case_name"
                        value={newContact.case_name}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Case ID</FormLabel>
                      <Input
                        placeholder="Enter case ID"
                        name="case_id"
                        value={newContact.case_id}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Preferred Language</FormLabel>
                      <Input
                        placeholder="Enter preferred language"
                        name="preferred_language"
                        value={newContact.preferred_language}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Insurance Company</FormLabel>
                      <Input
                        placeholder="Enter insurance company"
                        name="insurance_company"
                        value={newContact.insurance_company}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Insured Property</FormLabel>
                      <Input
                        placeholder="Enter insured property"
                        name="insured_property"
                        value={newContact.insured_property}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Brief Description of the Loss</FormLabel>
                      <Input
                        placeholder="Enter description"
                        name="brief_description_of_the_loss"
                        value={newContact.brief_description_of_the_loss}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Mailing Address if Different</FormLabel>
                      <Input
                        placeholder="Enter mailing address if different"
                        name="mailing_address_if_different_from_above"
                        value={newContact.mailing_address_if_different_from_above}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Have the Claim Been Reported?</FormLabel>
                      <Input
                        placeholder="Yes/No"
                        name="have_the_claim_been_reported"
                        value={newContact.have_the_claim_been_reported}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Policy Number</FormLabel>
                      <Input
                        placeholder="Enter policy number"
                        name="policy_number"
                        value={newContact.policy_number}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Claim Number</FormLabel>
                      <Input
                        placeholder="Enter claim number"
                        name="claim_number"
                        value={newContact.claim_number}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Date of Loss</FormLabel>
                      <Input
                        placeholder="Enter date of loss"
                        name="date_of_loss"
                        value={newContact.date_of_loss}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Public Adjuster (if applicable)</FormLabel>
                      <Input
                        placeholder="Enter public adjuster"
                        name="public_adjuster_if_applicable"
                        value={newContact.public_adjuster_if_applicable}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Created Date</FormLabel>
                      <Input
                        placeholder="YYYY-MM-DD"
                        name="created_date"
                        value={newContact.created_date}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Created By</FormLabel>
                      <Input
                        placeholder="Enter created by"
                        name="created_by"
                        value={newContact.created_by}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Stack>
                )}
              </Stack>
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant="plain" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              form="add-contact-form"
              variant="solid"
              onClick={handleSubmit}
            >
              Add Contact
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      {/* Success Pop-Up Modal */}
      <Modal open={openSuccess} onClose={() => setOpenSuccess(false)}>
        <ModalDialog
          sx={{
            width: 400,
          }}
        >
          <DialogTitle>Contact Added Successfully</DialogTitle>
          <DialogContent>
            <p>Do you want to add a case for this contact?</p>
          </DialogContent>
          <DialogActions>
            <Button variant="plain" onClick={() => setOpenSuccess(false)}>
              Close
            </Button>
            <Button variant="solid" onClick={() => { /* Leave blank for now */ }}>
              Add Case
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default AddContactModal;