import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Input,
  Button,
  Divider,
  Grid,
  FormLabel
} from "@mui/joy";
import { auth } from "../../firebase/firebase";

function ProfileEdit({ onSubmit }) {
  const location = useLocation();
  const { userId, user, imageUrl } = location.state || {};

  const currentUser = auth.currentUser?.uid;

  const [firstName, setFirstName] = useState(user?.first_name || "");
  const [lastName, setLastName] = useState(user?.last_name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [profileImage, setProfileImage] = useState(imageUrl || "");
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setProfileImage(reader.result); // Preview
      reader.readAsDataURL(file);
      setSelectedImage(file); // Store file for upload
    }
  };

  // useEffect(() => {
  //   const fetchProfileImage = async () => {
  //     try {
  //       const response = await axios.get(`/users/${currentUser}/profile-image`);
  //       if (response.data.imageUrl) {
  //         setProfileImage(response.data.imageUrl);
  //       }
  //     } catch (error) {
  //       console.error("Failed to fetch profile image:", error);
  //     }
  //   };

  //   if (!imageUrl && currentUser) {
  //     fetchProfileImage();
  //   }
  // }, [currentUser, imageUrl]);

  const handleSubmit = async () => {
    if (!selectedImage) {
      alert("Please select an image to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("media", selectedImage);
    formData.append("name", `${firstName} ${lastName}`);
    formData.append("description", "Profile Image");
    formData.append("assigned_date", new Date().toISOString());
    formData.append("uid", currentUser);

    try {
      const response = await axios.post(`/cases/${user?.staff_id}/media`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status) {
        alert("Image uploaded successfully!");
        if (onSubmit) {
            onSubmit(response.data);
          }
      } else {
        alert("Error uploading image: " + response.data.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Error uploading image");
    }
  };

  return (
    <Grid container spacing={2} sx={{ p: 4 }}>
      {/* Left Column */}
      <Grid xs={12} md={4}>
        <Card sx={{ alignItems: "center", textAlign: "center", p: 3 }}>
          <label htmlFor="upload-photo">
            <input
              style={{ display: "none" }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
            <Avatar
              id="profile-avatar"
              size="lg"
              src={profileImage}
              sx={{ width: 100, height: 100, mx: "auto", mb: 1, cursor: "pointer" }}
            />
          </label>
          <Typography level="title-md" sx={{ color: "#6c63ff" }}>
            @{firstName.toLowerCase() || "user"}
          </Typography>
          <Typography level="body-sm">{email}</Typography>
        </Card>

        <Card sx={{ mt: 2, p: 3 }}>
          <Typography level="title-sm" mb={1}>
            <strong>Information</strong>
          </Typography>
          <Typography level="body-sm">Name: {firstName} {lastName}</Typography>
          <Typography level="body-sm">Email: {email}</Typography>
        </Card>
      </Grid>

      {/* Right Column */}
      <Grid xs={12} md={8}>
        <Card sx={{ p: 3 }}>
          <Typography level="title-md" mb={2}>
            User Settings
          </Typography>

          <Typography level="title-sm" mb={1}>Details</Typography>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormLabel>Name</FormLabel>
              <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} fullWidth />
            </Grid>
            <Grid xs={6}>
              <FormLabel>Last Name</FormLabel>
              <Input value={lastName} onChange={(e) => setLastName(e.target.value)} fullWidth />
            </Grid>
            <Grid xs={12}>
              <FormLabel>Email</FormLabel>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />
            </Grid>
          </Grid>

          <Button sx={{ mt: 2, backgroundColor: "#6c63ff" }} onClick={handleSubmit}>
            Save changes
          </Button>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ProfileEdit;
