import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import AddDocumentModal from "../components/AddDocumentModal";

import {
  Box,
  Grid,
  Card,
  Typography,
  Button,
  Tabs,
  TabList,
  TabPanel,
  Sheet,
  Input,
  Container,
  Divider,
  IconButton,
  CircularProgress,
  Tooltip,
  Table,
  Select,
  MenuItem,
  Option,
} from "@mui/joy";
import { auth } from "../firebase/firebase";

import {
  Tab,
  ListItem as JoyListItem,
  ListItemContent as JoyListItemContent,
  List as JoyList,
} from "@mui/joy";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import NotesTab from "../components/NotesTab";
import AddEventForm from "../components/AddEventForm";
import TimeEntriesTab from "./Case/TimeAndBilling/TimeEntriesTab";
import AddCaseModal from "../components/AddCaseModal";
import TaskCard from "./Case/CaseDetail/Info/Task";
import EventCard from "./Case/CaseDetail/Info/Event";
import CaseRecentActivity from "./CaseRecentActivity";

const API_BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3001";

const CaseDetails = ({ onCaseUpdates }) => {
  const { id } = useParams();
  // Define case_id_time based on the id from the URL
  const case_id_time = id;
  const location = useLocation();
  const { cases } = location.state || [];
  const navigate = useNavigate();

  // Main states
  const [caseDetails, setCaseDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [customOpen, setCustomOpen] = useState(false);
  // Nested Info sub-tab state (0 = Info, 1 = Documents)
  const [infoSubTab, setInfoSubTab] = useState(0);
  // Documents associated with the case and document search term for filtering
  const [documents, setDocuments] = useState([]);
  const [docSearchTerm, setDocSearchTerm] = useState("");
  const caseId = id;
  const [openModal, setOpenModal] = useState(false);
  const [eventsa, setEvents] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [caseStages, setCaseStages] = useState("");
  const [caseStage, setCaseStage] = useState(caseDetails?.case_stage || "");
  const [isEditing, setIsEditing] = useState(false);
  const currentUser = auth.currentUser?.uid;
  const [customFields, setCustomFields] = useState([]);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  useEffect(() => {
    axios
      .get("/custom_fields?parent_type=case")
      .then((response) => {
        setCustomFields(response.data);
      })
      .catch((error) => {
        console.error("Error fetching custom fields:", error);
      });
  }, []);
  useEffect(() => {
    axios
      .get("/case_stages")
      .then((response) => {
        console.log("Fetched stages:", response.data);
        setCaseStages(response.data);
      })
      .catch((error) => console.error("Error fetching case stages:", error));
  }, []);

  const handleSelectChange = (event, value) => {
    if (!value) return; // prevent empty updates

    const selectedStage = value;
    setCaseStage(selectedStage);
    setIsEditing(false);

    const payload = {
      case_stage: selectedStage,
    };

    axios
      .put(`/cases/${caseDetails.case_id}`, payload, {
        headers: {
          "x-user-uid": currentUser,
        },
      })
      .then(() => {
        console.log("Case stage updated!");
        fetchCaseDetails();
        onCaseUpdates(); // Refresh case if needed
      })
      .catch((error) => console.error("Error updating case stage:", error));
  };

  const handleEditClick = () => setIsEditing(true);

  useEffect(() => {
    if (!caseId) return;

    axios
      .get(`/api/events?case_id=${caseId}`)
      .then((response) => {
        setEvents(response.data.events);
        console.log("events", response.data.events);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
        setLoading(false);
      });
  }, [caseId]);

  const today = new Date(); // Today's date for comparison

  const upcomingEvents = eventsa.filter(
    (event) => new Date(event.start) >= today
  );
  const pastEvents = eventsa.filter((event) => new Date(event.start) < today);
  const handleEditCase = () => {
    setOpenModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  };
  // Style for truncating long text
  const truncateStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
  };

  // Handler for "Add Document" button
  const handleAddDocument = () => {
    // Open the modal and pass the current case ID as the default
    setAddDocumentModalOpen(true);
  };
  // Handler for "Add Document Template" button
  const handleTemplateSelect = (templateName) => {
    setTemplateModalOpen(false);
    axios({
      url: `${API_BASE_URL}/generate-document`,
      method: "POST",
      responseType: "blob",
      data: { case_id: caseId },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", templateName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetchCaseDocuments();
      })
      .catch((error) => {
        console.error("Error generating document:", error);
      });
  };

  // New function to delete a document
  const deleteDocument = (docName) => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      axios
        .delete(`${API_BASE_URL}/cases/${caseId}/documents/${docName}`)
        .then(() => {
          fetchCaseDocuments();
        })
        .catch((error) => {
          console.error("Error deleting document:", error);
        });
    }
  };
  // Function to fetch case details
  const fetchCaseDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/cases/${id}`);
      setCaseDetails(response.data);
    } catch (error) {
      console.error("Error fetching case details:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCaseDocuments = async () => {
    try {
      const res = await axios.get(`/cases/${id}/documents`);
      setDocuments(res.data.documents || []);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const [addDocumentModalOpen, setAddDocumentModalOpen] = useState(false);



  // Fetch case details and documents
  useEffect(() => {
    fetchCaseDetails();
    fetchCaseDocuments();
  }, [id]);

  if (loading) {
    return (
      <Typography align="center" sx={{ color: "#ffffff" }}>
        Loading case details...
      </Typography>
    );
  }
  if (!caseDetails) {
    return <Typography align="center">No case details found.</Typography>;
  }

  // Destructure fields from caseDetails
  const {
    name,
    case_id,
    case_number,
    practice_area,
    case_stage,
    opened_date,
    description,
    assigned_attorney,
    origination_credit,
    petitioner,
    paralegal_assignment,
    notes: caseNotes = [],
    events = [],
    applicable_deductible = "N/A",
    assigned_attorneys_email = "N/A",
    attorneys_fee_settlement = "N/A",
    calendar_call = "N/A",
    case_costs = "N/A",
    claim_number = "N/A",
    clients_email = "N/A",
    clients_phone_number = "N/A",
    closed_date = "N/A",
    county = "N/A",
    coverage_determination = "N/A",
    date_of_damage = "N/A",
    defendant_discovery_responses_received = "N/A",
    defense_attorney = "N/A",
    defense_attorney_firm = "N/A",
    property_address = "N/A",
    defs_agreed_order_disco = "N/A",
    defs_mfext_filed_complaint = "N/A",
    defs_mfext_filed_disco = "N/A",
    depo_request_cr = "N/A",
    depo_request_fa = "N/A",
    division = "N/A",
    expert_fees_1 = "N/A",
    hearing_request_mtc = "N/A",
    indemnity_settlement = "N/A",
    insurance_policy_number = "N/A",
    last_offer_of_settlement = "N/A",
    msj_hearing_date = "N/A",
    ocs_direct_email = "N/A",
    ocs_fax_number = "N/A",
    ocs_phone_number = "N/A",
    ocs_service_email = "N/A",
    pa_estimate = "N/A",
    pa_fee = "N/A",
    plaintiffs_agreed_order_disco = "N/A",
    plaintiffs_disco_responses_sent = "N/A",
    plaintiffs_mfext_filed_disco = "N/A",
    public_adjusters = "N/A",
    respondent = "N/A",
    responses_to_defendants_discovery_due = "N/A",
    responses_to_plaintiffs_discovery_due = "N/A",
    retainer_type = "N/A",
    schedulers_email = "N/A",
    scheduling_assignment = "N/A",
    time_entry_amount = "N/A",
    trial_period_start_date = "N/A",
    type_of_damage = "N/A",
    type_of_loss_automated = "N/A",
  } = caseDetails;
  const todayDate = new Date();
  // const pastEvents = events.filter(
  //   (event) => new Date(event.start_event) < todayDate
  // );
  const futureEvents = events.filter(
    (event) => new Date(event.start_event) >= todayDate
  );

  return (
    <Container maxWidth="xl" sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {/* Sidebar – Basic Case Details */}
        <Grid xs={12} md={4}>
          <Sheet sx={{ p: 2, borderRadius: "md", boxShadow: "lg" }}>
            <Typography level="h4">{name || "Case Title"}</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography level="body1">
              <strong>Case ID:</strong> {case_id}
            </Typography>
            <Typography level="body1">
              <strong>Case Number:</strong> {case_number}
            </Typography>
            <Typography level="body1">
              <strong>Practice Area:</strong> {practice_area}
            </Typography>
            <Typography level="body1">
              <strong>Case Stage:</strong>{" "}
              {isEditing ? (
                <Select
                  value={caseStage}
                  onChange={handleSelectChange}
                  size="sm"
                  autoFocus
                >
                  {caseStages.map((stage) => (
                    <Option
                      key={stage.case_stage_id}
                      value={stage.case_stage_name}
                    >
                      {stage.case_stage_name}
                    </Option>
                  ))}
                </Select>
              ) : (
                <>
                  {case_stage || "Not Specified"}{" "}
                  <IconButton size="sm" onClick={() => setIsEditing(true)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </>
              )}
            </Typography>

            <Typography level="body1">
              <strong>Opened Date:</strong> {opened_date}
            </Typography>
            <Typography level="body1">
              <strong>Description:</strong> {description}
            </Typography>
            <Button
              fullWidth
              variant="solid"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleEditCase}
            >
              Edit Case
            </Button>
            <Box
  sx={{
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    mt: 2,
  }}
>
  <Button
    variant="outlined"
    color="danger"
    disabled={!!caseDetails.closed_date} // Disable if closed_date exists

    sx={{
      width: "100%",
      borderRadius: "999px",
      borderWidth: "1px",
      borderColor: "red",
      color: "red",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "rgba(255, 0, 0, 0.87)",
        color: "#ffffff",
      },
    }}
    onClick={() => {
      const payload = {
        closed_date: new Date().toISOString(),
      };

      axios
        .put(`/cases/${caseDetails.case_id}`, payload, {
          headers: {
            "x-user-uid": currentUser,
          },
        })
        .then(() => {
          console.log("Case closed successfully!");
          fetchCaseDetails();
          onCaseUpdates?.(); // Safe call if exists
        })
        .catch((error) => {
          console.error("Error closing case:", error);
        });
    }}
  >
    Close Case
  </Button>
</Box>

          </Sheet>

          {/* Attorney & Staff Details */}
          <Sheet sx={{ p: 2, mt: 2, borderRadius: "md", boxShadow: "lg" }}>
            <Typography level="h5">Assigned Staff</Typography>
            <Typography>
              <strong>Lead Attorney:</strong> {assigned_attorney || "N/A"}
            </Typography>
            <Typography>
              <strong>Originating Attorney:</strong>{" "}
              {origination_credit || "N/A"}
            </Typography>
            <Typography>
              <strong>Paralegal:</strong> {paralegal_assignment || "N/A"}
            </Typography>
          </Sheet>
          <Sheet sx={{ p: 2, mt: 2, borderRadius: "md", boxShadow: "lg" }}>
            <CaseRecentActivity case_id={case_id_time} />
          </Sheet>
        </Grid>
        <AddDocumentModal
      open={addDocumentModalOpen}
      onClose={() => setAddDocumentModalOpen(false)}
      defaultCaseId={caseId}  // Pass the current case ID
    />

        {/* Main Content */}
        <Grid xs={12} md={8}>
          <Card variant="outlined" sx={{ width: "100%" }}>
            <Typography level="h2" sx={{ p: 2 }}>
              {name || "Case Details"}
            </Typography>

            <Tabs
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}
            >
              <TabList>
                <Tab>Info</Tab>
                <Tab>Time and Billing</Tab>
                <Tab>Notes</Tab>
                <Tab>Events</Tab>
                <Tab>Documents</Tab>
              </TabList>

              {/* Main TabPanel for "Info" */}
              <TabPanel value={0}>
                {/* Nested Tabs for Info and Documents */}
                <Tabs
                  value={infoSubTab}
                  onChange={(_, newSubValue) => setInfoSubTab(newSubValue)}
                >
                  <TabList>
                    <Tab>Info</Tab>
                  </TabList>

                  {/* Sub-tab "Info" */}
                  <TabPanel>
                    <Typography level="h3" sx={{ mb: 2 }}>
                      Case Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={8} md={8} lg={8}>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                            gap: 2,
                          }}
                        >
                          <Box>
                            <Typography sx={{ mb: 1 }}>
                              <strong>Name:</strong> {name || "N/A"}
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                              <strong>Case Number:</strong>{" "}
                              {case_number || "N/A"}
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                              <strong>Practice Area:</strong>{" "}
                              {practice_area || "N/A"}
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                              <strong>Case Stage:</strong> {case_stage || "N/A"}
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                              <strong>Petitioner:</strong> {petitioner || "N/A"}
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                              <strong>Description:</strong>{" "}
                              {description || "N/A"}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography sx={{ mb: 1 }}>
                              <strong>Date Opened:</strong>{" "}
                              {opened_date || "N/A"}
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                              <strong>Date Closed:</strong>{" "}
                              {closed_date || "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={4} md={4} lg={4}>
                        <TaskCard caseId={caseId} />
                        <EventCard caseId={caseId} />
                      </Grid>
                    </Grid>
                    <Box sx={{ borderBottom: "2px solid #ddd", my: 2 }} />
                    {/* Custom Fields Section */}
                    <Sheet
                      sx={{
                        mt: 2,
                        borderRadius: "md",
                        backgroundColor: "#fff",
                      }}
                    >
                      {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
      <Typography level="h3">Custom Fields</Typography>
      <IconButton onClick={() => setCustomOpen(!customOpen)}>
        {customOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </Box> */}

                      {/* {customOpen && ( */}

                      <Box>
                        <Typography level="h3" sx={{ mb: 2 }}>
                          Custom Fields
                        </Typography>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                            gap: 1,
                          }}
                        >
                          {customFields.length > 0 ? (
                            customFields
                              .map((field) => {
                                // Convert the field name to match the caseDetails key
                                const fieldKey = Object.keys(caseDetails).find(
                                  (key) =>
                                    key.toLowerCase().replace(/\s+/g, "_") ===
                                    field.custom_fields_name
                                      .toLowerCase()
                                      .replace(/\s+/g, "_")
                                );

                                const fieldValue = caseDetails[fieldKey];

                                // Exclude fields with empty values, "unknown", or "N/A"
                                if (
                                  !fieldValue ||
                                  fieldValue.toLowerCase() === "unknown" ||
                                  fieldValue.toLowerCase() === "n/a"
                                ) {
                                  return null;
                                }

                                return (
                                  <Box key={fieldKey}>
                                    <Typography level="body1" sx={{ mb: 1 }}>
                                      <strong>
                                        {field.custom_fields_name}:
                                      </strong>{" "}
                                      {fieldValue}
                                    </Typography>
                                  </Box>
                                );
                              })
                              .filter(Boolean) // Remove null values from the array
                          ) : (
                            <Typography>No custom fields available.</Typography>
                          )}
                        </Box>
                      </Box>
                    </Sheet>
                  </TabPanel>

                  {/* Sub-tab "Documents" */}
                </Tabs>
              </TabPanel>

              {/* Main TabPanel for "Time and Billing" */}
              <TabPanel value={1}>
                <TimeEntriesTab case_id_time={case_id_time} cases={cases} />
              </TabPanel>

              {/* Main TabPanel for "Notes" */}
              <TabPanel value={2}>
                <NotesTab
                  notes={caseNotes}
                  caseId={case_id}
                  cases={cases}
                  case_id_time={case_id_time}
                />
              </TabPanel>

              {/* Main TabPanel for "Events" */}
              <TabPanel value={3}>
                <AddEventForm caseId={case_id} cases={cases} />
                <div>
                  <Typography level="h5" sx={{ mt: 2 }}>
                    Upcoming Events
                  </Typography>
                  {loading ? (
                    <p>Loading events...</p>
                  ) : (
                    <ul>
                      {upcomingEvents.length > 0 ? (
                        upcomingEvents.map((event) => (
                          <li key={event.id}>
                            {event.title} -{" "}
                            {new Date(event.start).toLocaleString()}
                          </li>
                        ))
                      ) : (
                        <li>No upcoming events</li>
                      )}
                    </ul>
                  )}

                  <Typography level="h5" sx={{ mt: 2 }}>
                    Past Events
                  </Typography>
                  {loading ? (
                    <p>Loading events...</p>
                  ) : (
                    <ul>
                      {pastEvents.length > 0 ? (
                        pastEvents.map((event) => (
                          <li key={event.id}>
                            {event.title} -{" "}
                            {new Date(event.start).toLocaleString()}
                          </li>
                        ))
                      ) : (
                        <li>No past events</li>
                      )}
                    </ul>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={4}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Input
                    placeholder="Search documents..."
                    value={docSearchTerm}
                    onChange={(e) => setDocSearchTerm(e.target.value)}
                    sx={{ flex: 1 }}
                  />
               <Button
  startDecorator={<AddIcon />}
  variant="solid"
  size="sm"
  onClick={() => setAddDocumentModalOpen(true)}
  sx={{ ml: 1 }}
>
  Add Document
</Button>
                  <Button
                    startDecorator={<InsertDriveFileIcon />}
                    variant="solid"
                    size="sm"
                    onClick={() => setTemplateModalOpen(true)}
                    sx={{ ml: 1 }}
                  >
                    Add Document Template
                  </Button>
                </Box>
                {documents.length > 0 ? (
                  <Table sx={{ width: "100%", borderRadius: 2, boxShadow: 2 }}>
                    <thead>
                      <tr>
                        <th style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "left" }}>
                          Document
                        </th>
                        <th style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {documents
                        .filter((doc) =>
                          doc.toLowerCase().includes(docSearchTerm.toLowerCase())
                        )
                        .map((doc, index) => (
                          <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                            <td
                              style={{
                                padding: "8px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                window.open(
                                  `${API_BASE_URL}/cases/${case_id}/documents/${doc}?preview=1`,
                                  "_blank"
                                )
                              }
                            >
                              <InsertDriveFileIcon sx={{ mr: 1 }} />
                              <Tooltip title={doc}>
                                <span style={truncateStyle}>{doc}</span>
                              </Tooltip>
                            </td>
                            <td style={{ padding: "8px", textAlign: "center" }}>
                              <Button
                                variant="plain"
                                onClick={() =>
                                  window.open(
                                    `${API_BASE_URL}/cases/${case_id}/documents/${doc}`,
                                    "_blank"
                                  )
                                }
                              >
                                <DownloadIcon />
                              </Button>
                              <Button
                                variant="plain"
                                onClick={() =>
                                  window.open(
                                    `${API_BASE_URL}/cases/${case_id}/documents/${doc}?preview=1`,
                                    "_blank"
                                  )
                                }
                              >
                                <RemoveRedEyeIcon />
                              </Button>
                              <Button variant="plain" onClick={() => deleteDocument(doc)}>
                                <DeleteIcon />
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                ) : (
                  <Typography>No documents found for this case.</Typography>
                )}
              </TabPanel>
            </Tabs>
          </Card>

          
        </Grid>
      </Grid>
       {/* Modal for selecting a document template */}
       <Modal open={templateModalOpen} onClose={() => setTemplateModalOpen(false)}>
        <ModalDialog
          variant="outlined"
          sx={{
            width: 400,
            p: 3,
            borderRadius: "md",
            boxShadow: "xl",
            bgcolor: "background.surface",
          }}
        >
          <ModalClose sx={{ bgcolor: "neutral.200", borderRadius: "50%" }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid",
              borderColor: "neutral.300",
              pb: 2,
            }}
          >
            <DownloadIcon sx={{ fontSize: 32 }} />
            <Typography level="h4" sx={{ ml: 1, fontWeight: "bold" }}>
              Select Template
            </Typography>
          </Box>
          <JoyList sx={{ p: 0 }}>
            <JoyListItem
              onClick={() =>
                handleTemplateSelect("Notice-of-Compliance-Discovery-Disclosure.docx")
              }
              sx={{
                p: 2,
                borderRadius: "sm",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: 2,
                transition: "background-color 0.3s ease",
                "&:hover": { backgroundColor: "primary.soft" },
                border: "1px solid",
                borderColor: "neutral.200",
                mb: 1,
              }}
            >
              <DownloadIcon sx={{ fontSize: 24 }} />
              <JoyListItemContent>
                <Typography level="body1" sx={{ fontWeight: "medium", color: "#0b6bcb" }}>
                  Notice-of-Compliance-Discovery-Disclosure.docx
                </Typography>
              </JoyListItemContent>
            </JoyListItem>
            {/* Additional templates can be added here */}
          </JoyList>
        </ModalDialog>
      </Modal>

      {/* Event Modal */}
      <Modal open={!!selectedEvent} onClose={() => setSelectedEvent(null)}>
        <ModalDialog>
          <ModalClose onClick={() => setSelectedEvent(null)} />
          <Typography level="h4">Edit Event</Typography>
          <Input
            fullWidth
            placeholder="Event Name"
            value={selectedEvent?.event_name || ""}
            onChange={(e) =>
              setSelectedEvent((prev) => ({
                ...prev,
                event_name: e.target.value,
              }))
            }
          />
          <Input
            fullWidth
            type="date"
            value={
              selectedEvent?.start_event
                ? selectedEvent.start_event.split("T")[0]
                : ""
            }
            onChange={(e) =>
              setSelectedEvent((prev) => ({
                ...prev,
                start_event: e.target.value,
              }))
            }
          />
          <Button onClick={() => setSelectedEvent(null)}>Cancel</Button>
          <Button variant="solid" color="primary">
            Save Changes
          </Button>
        </ModalDialog>
      </Modal>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box>
          <AddCaseModal
            caseId={2}
            open={openModal}
            onClose={handleCloseModal}
            caseDetails={caseDetails}
            parentType="case"
            onCaseUpdates={fetchCaseDetails}
            onUpdateCase={fetchCaseDetails}
          />
        </Box>
      </Modal>
    </Container>
  );
};

export default CaseDetails;