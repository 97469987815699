import React, { useState } from "react";
import { Box, Tabs, TabList, Tab, TabPanel } from "@mui/joy";

import TimeEntries from "./TimeEntries";
import Info from "./Info";
import Expenses from "./Expenses";
import { InformationCircleIcon, ClockIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";

export default function TimeEntriesTab({ case_id_time, cases }) {

  const [activeTab, setActiveTab] = useState(0);


  return (
    <Box sx={{ p: 0 }}>
      <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
      <TabList>
    <Tab>
    <div className="flex items-center">
                <InformationCircleIcon width={20} height={20}  />
                <span style={{position:"relative", bottom:"4px", marginLeft:"0.3rem"}}>Info</span>
                
              </div>
    </Tab>
    <Tab>
    <div className="flex items-center">
                <ClockIcon width={20} height={20}  />
                <span style={{position:"relative", bottom:"4px", marginLeft:"0.3rem"}}>Time Entries</span>
                
              </div>
      </Tab>
     <Tab>
     <div className="flex items-center">
                <CurrencyDollarIcon width={20} height={20}  />
                <span style={{position:"relative", bottom:"4px", marginLeft:"0.3rem"}}>Expenses</span>
                
              </div>
      </Tab>
   {/* <Tab>Invoices</Tab>
    <Tab>Payment Activity</Tab> */}
  </TabList>

<TabPanel value={0}>
   <Info  case_id_time={case_id_time} cases={cases}/>
  </TabPanel>
  <TabPanel value={1}>
      <TimeEntries case_id_time={case_id_time} cases={cases}/>
  </TabPanel>
  <TabPanel value={2}>
  <Expenses case_id_time={case_id_time} cases={cases}/>
    {/* Expenses Content */}
  </TabPanel>
  <TabPanel value={3}>
    {/* Invoices Content */}
  </TabPanel>
  <TabPanel value={4}>
    {/* Payment Activity Content */}
  </TabPanel>
  </Tabs>
    </Box>
  );
}
