import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Input,
  Typography,
  Select,
  MenuItem,
  Textarea,
  FormControl,
  FormLabel,
  Autocomplete,
  TextField,
} from "@mui/joy";
import { auth } from "../firebase/firebase";

const AddEventForm = ({ caseId,cases: initialCases, onEventAdd = () => {} }) => {
  const [eventData, setEventData] = useState({
    case_id: "",
    case_name: "",
    event_name: "",
    event_description: "",
    start_event: "",
    end_event: "",
    location: "",
    private_event: false,
    event_color: "#000000",
    event_type: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [cases, setCases] = useState([]);
    const [selectedCase, setSelectedCase] = useState(caseId);
    const currentUser = auth.currentUser?.uid;

    useEffect(() => {
      if (cases.length === 0) {
          fetchCases(""); // Fetch cases when modal opens for the first time
      }
  }, []);
   useEffect(() => {
     if (searchTerm) {
       fetchCases(searchTerm);
     } else {
       setCases(initialCases || []); // Reset to initial cases if search is empty
     }
   }, [searchTerm, initialCases]);
 
   const fetchCases = async (search) => {
     try {
       const response = await axios.get(
         `/cases?search=${encodeURIComponent(search)}`
       );
       setCases(response?.data?.cases || []);
     } catch (error) {
       console.error("Error fetching cases:", error);
       setCases([]);
     }
   };

  useEffect(() => {
    const fetchEventTypes = async () => {
      try {
        const response = await axios.get("/event-types");
        setEventTypes(response.data);
      } catch (error) {
        console.error("Error fetching event types:", error);
      }
    };
    fetchEventTypes();
  }, []);

  const handleColorChange = (event) => {
    const selectedId = event?.target?.value;
    const selectedEvent = eventTypes.find((ev) => ev.id === selectedId);

    if (selectedEvent) {
      setEventData((prevData) => ({
        ...prevData,
        event_color: selectedEvent.color_code,
        event_type: selectedEvent.event_type_name,
      }));
    }
  };

  const handleChange = (event) => {
    if (!event || !event.target) return;
    const { name, value } = event.target;
    setEventData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCaseSelect = (event, selectedCase) => {
    if (!selectedCase) return;
    setEventData((prevData) => ({
      ...prevData,
      case_id: selectedCase.case_id,
      case_name: selectedCase.name,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting event data: ", eventData);
  
    if (!eventData.event_name || !eventData.start_event || !eventData.end_event) {
      alert("Please fill out all required fields.");
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const response = await axios.post("/events", { 
        ...eventData, 
        case_id: selectedCase,  // Ensure case_id is included 
        case_name: searchTerm   // Include case_name
      }, {
        headers: {
          'x-user-uid': currentUser  
        }
      });
  
      onEventAdd(response.data);
  
      // Reset eventData but preserve case_id if needed
      setEventData((prevData) => ({
        ...prevData,
        event_name: "",
        event_description: "",
        start_event: "",
        end_event: "",
        location: "",
        private_event: false,
        event_color: "#000000",
        event_type: "",
        case_id: selectedCase, // Preserve the selected case
        case_name: searchTerm  // Preserve the case name
      }));
  
      alert("Event added successfully.");
    } catch (error) {
      console.error("Error adding event:", error);
      alert("Failed to add event.");
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        p: 2,
        width: "100%",
        maxWidth: "600px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        background: "#fff",
        mx: "auto", // center horizontally
        boxSizing: "border-box",
      }}
    >
      <Typography level="h3" mb={2}>
        Add Event
      </Typography>
      <FormControl fullWidth>
        <FormLabel>Case</FormLabel>
        {/* <Autocomplete
          fullWidth
          options={cases}
          getOptionLabel={(option) => option.name}
          onChange={handleCaseSelect}
          inputValue={searchTerm}
          onInputChange={(e, newInputValue) => setSearchTerm(newInputValue)}
          renderInput={(params) => (
            <TextField {...params} label="Search case..." placeholder="Type to search" />
          )}
        /> */}
      <Autocomplete
  fullWidth
  options={cases}
  getOptionLabel={(option) => option.name}
  value={cases.find((c) => c.case_id === selectedCase) || null}
  onChange={(e, value) => {
    setSelectedCase(value?.case_id || ""); // Update selected case
    setSearchTerm(value?.name || ""); // Sync input value with selection

    // Ensure eventData is updated with selected case details
    setEventData((prevData) => ({
      ...prevData,
      case_id: value?.case_id || "",
      case_name: value?.name || "",
    }));
  }}
  inputValue={searchTerm}
  onInputChange={(e, newInputValue) => setSearchTerm(newInputValue)}
  renderInput={(params) => (
    <TextField {...params} label="Search case..." placeholder="Type to search" />
  )}
/>


      </FormControl>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 2 }}>
        <Input
          type="color"
          name="event_color"
          value={eventData.event_color}
          readOnly
          sx={{ width: 50, height: 40, cursor: "pointer" }}
        />
        <Select
          name="event_type"
          value={eventData.event_type}
          onChange={handleColorChange}
          placeholder="Select an event type"
          fullWidth
        >
          {eventTypes.map((ev) => (
            <MenuItem key={ev.id} value={ev.id}>
              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  backgroundColor: ev.color_code,
                  mr: 1,
                }}
              />
              {ev.event_type_name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Input
        name="event_name"
        value={eventData.event_name}
        onChange={handleChange}
        required
        placeholder="Event Name"
        fullWidth
        sx={{ mt: 2 }}
      />
      <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
        <Input
          type="datetime-local"
          name="start_event"
          value={eventData.start_event}
          onChange={handleChange}
          required
          fullWidth
        />
        <Input
          type="datetime-local"
          name="end_event"
          value={eventData.end_event}
          onChange={handleChange}
          required
          fullWidth
        />
      </Box>
      <Select
        name="location"
        value={eventData.location}
        onChange={handleChange}
        fullWidth
        sx={{ mt: 2 }}
      >
        <MenuItem value="">Select Location</MenuItem>
        {/* Add more locations as needed */}
      </Select>
      <Textarea
        name="event_description"
        value={eventData.event_description}
        onChange={handleChange}
        placeholder="Description"
        minRows={3}
        fullWidth
        sx={{ mt: 2 }}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
        <Button variant="outlined" disabled={isSubmitting}>
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting} variant="solid">
          {isSubmitting ? "Adding..." : "Save Event"}
        </Button>
      </Box>
    </Box>
  );
};

export default AddEventForm;