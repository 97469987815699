import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddTimeEntryModal from "./AddTimeEntryModal"; // Import modal
import TimerIcon from "@mui/icons-material/Timer"; // Import Timer Icon from MUI
import { Autocomplete, FormControl, FormLabel, TextField } from "@mui/joy";

const AddNoteModal = ({
  caseId,
  open,
  onClose,
  onNoteAdded,
  case_id_time,
  editData,
  singleCase,
  cases: initialCases,
  fetchTimeEntries,
}) => {
  const today = new Date().toISOString().split("T")[0];
  const [subject, setSubject] = useState("");
  const [note, setNote] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false); // State for validation error
  const [cases, setCases] = useState(initialCases || []);
  const [selectedCase, setSelectedCase] = useState(caseId);

  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    if (searchTerm) {
      fetchCases(searchTerm);
    } else {
      setCases(initialCases || []); // Reset to initial cases if search is empty
    }
  }, [searchTerm, initialCases]);

  const fetchCases = async (search) => {
    try {
      const response = await axios.get(
        `/cases?search=${encodeURIComponent(search)}`
      );
      setCases(response?.data?.cases || []);
    } catch (error) {
      console.error("Error fetching cases:", error);
      setCases([]);
    }
  };

  useEffect(() => {
    // const filteredCase = cases?.find(caseItem => Number(caseItem?.case_id) === Number(caseId));
    if (singleCase) {
      setSelectedCase(singleCase?.case_id || "");
    }
  }, [singleCase]);

  const handleSubmit = async (openTimeEntry = false) => {
    if (!subject.trim() || !note.trim()) {
      setError(true);
      return;
    }

    if (!caseId) {
      console.error("caseId is required to add a note.");
      return;
    }

    const payload = {
      case_id: selectedCase,
      subject,
      note,
      date: today,
    };

    try {
      const response = await axios.post("/case_notes", payload);
      if (onNoteAdded) {
        onNoteAdded(response.data);
      }

      // Reset fields and close modal
      setSubject("");
      setNote("");
      setError(false);
      onClose();

      // If openTimeEntry is true, open the AddTimeEntryModal
      if (openTimeEntry) {
        setModalOpen(true);
      }
    } catch (error) {
      console.error("Error creating note:", error);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <ModalDialog
          sx={{
            width: "90%",
            maxWidth: "800px",
            display: "flex",
            flexDirection: "column",
            p: 3,
          }}
        >
          <ModalClose onClick={onClose} />
          <Typography level="h4" mb={2}>
            Add Note
          </Typography>

          <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <FormControl fullWidth>
              <FormLabel>Case</FormLabel>
              <Autocomplete
                fullWidth
                options={cases.filter((caseItem) =>
                  caseItem.name.toLowerCase().includes(searchTerm.toLowerCase())
                )}
                getOptionLabel={(option) => option.name}
                value={cases.find((c) => c.case_id === selectedCase) || null}
                onChange={(e, value) => setSelectedCase(value?.case_id || "")}
                inputValue={searchTerm}
                onInputChange={(e, newInputValue) =>
                  setSearchTerm(newInputValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search case..."
                    placeholder="Type to search"
                  />
                )}
              />
            </FormControl>
            <Input
              placeholder="Subject"
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
                setError(false);
              }}
              fullWidth
              sx={{ mb: 2, mt: 2 }}
              error={error && !subject.trim()}
            />
            <Typography level="body2" mb={1}>
              Note
            </Typography>
            <Box sx={{ flex: 1 }}>
              <ReactQuill
                value={note}
                onChange={(content) => {
                  setNote(content);
                  setError(false);
                }}
                style={{ height: "100%", marginBottom: "16px" }}
              />
              {error && !note.trim() && (
                <Typography color="danger" sx={{ mt: 1, fontSize: "14px" }}>
                  Note content is required.
                </Typography>
              )}
            </Box>
          </Box>

          {/* Buttons row */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            {/* Save + Timer Button */}
            <Button
              variant="soft"
              onClick={() => handleSubmit(true)}
              sx={{ mr: 1 }}
              startDecorator={<TimerIcon />}
              disabled={!subject.trim() || !note.trim()}
            >
              Add Note +
            </Button>
            {/* Save Button */}
            <Button
              variant="solid"
              onClick={() => handleSubmit(false)}
              disabled={!subject.trim() || !note.trim()}
            >
              Add Note
            </Button>
          </Box>
        </ModalDialog>
      </Modal>

      {/* Add Time Entry Modal */}
      <AddTimeEntryModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        caseId={case_id_time}
        parentType="timeExpense"
        editData={editData}
        singleCase={singleCase}
        cases={cases}
        onSuccess={fetchTimeEntries}
      />
    </>
  );
};

export default AddNoteModal;
