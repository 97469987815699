import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Select,
  Option,
  Table,
  Typography,
  Checkbox,
  Input,
  Stack,
  MenuItem,
  Menu,
} from "@mui/joy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";

const CaseListReport = () => {
  const [practiceAreas, setPracticeAreas] = useState([]);
  const [caseStages, setCaseStages] = useState([]);
  const [dateRange, setDateRange] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [cases, setCases] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const [exportAnchor, setExportAnchor] = useState(null);

  const handleExportClick = (event) => {
    setExportAnchor(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchor(null);
  };

  const exportAsCSV = () => {
    const csvData = [
      [
        "Case",
        "Case Number",
        "Open Date",
        "Closed Date",
        "Statute of Limitations Date",
        "SOL Satisfied?",
        "Practice Area",
        "Case Stage",
        "Your Next Event",
        "Your Next Task",
        "Last Status Update",
      ],
      ...cases.map((item) => [
        item.name,
        item.case_number,
        item.open_date,
        item.close_date,
        item.limitation_date,
        item.sql_satisfied,
        item.practice_area,
        item.case_stage,
        item.next_event,
        item.next_task,
        item.last_status,
      ]),
    ];
    // regex
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "case_list_report.csv");
    document.body.appendChild(link);
    link.click();
  };

  const exportAsPDF = () => {
    import("jspdf").then((jsPDF) => {
      const doc = new jsPDF.default();
      doc.text("Case List Report", 20, 10);

      const tableData = cases.map((item) => [
        item.name,
        item.case_number,
        item.open_date,
        item.close_date,
        item.limitation_date,
        item.sql_satisfied,
        item.practice_area,
        item.case_stage,
        item.next_event,
        item.next_task,
        item.last_status,
      ]);

      import("jspdf-autotable").then((autoTable) => {
        autoTable.default(doc, {
          head: [
            [
              "Case",
              "Case Number",
              "Open Date",
              "Closed Date",
              "Statute of Limitations Date",
              "SOL Satisfied?",
              "Practice Area",
              "Case Stage",
              "Your Next Event",
              "Your Next Task",
              "Last Status Update",
            ],
          ],
          body: tableData,
          startY: 20,
        });

        doc.save("case_list_report.pdf");
      });
    });
  };

  const [filters, setFilters] = useState({
    search: "",
    practiceArea: "",
    stages: "",
    assignedAttorney: "",
  });

  const parseDate = (dateString) => {
    if (!dateString || !dateString.includes("/")) return null;
    const [month, day, year] = dateString.split("/");
    return new Date(`20${year}-${month}-${day}`);
  };

  const fetchCases = async () => {
    try {
      const params = {
        page: currentPage,
        search: filters.search,
        practice_area: filters.practiceArea,
        case_stage: filters.stages,
        assigned_attorney: filters.assignedAttorney,
      };

      if (dateRange === "Open Date" || dateRange === "Close Date") {
        params.start_date = filters.startDate || "";
        params.end_date = filters.endDate || "";
      }

      const response = await axios.get("/cases", { params });
      const formattedCases = response.data.cases.map((item) => ({
        ...item,
        parsedDate: parseDate(item.opened_date),
      }));
      setCases(formattedCases);
      setTotalPages(Math.ceil(response.data.totalCases / 20));
    } catch (error) {
      console.error("Error fetching cases:", error);
    }
  };

  const fetchPracticeArea = async () => {
    try {
      const response = await axios.get("/practice_areas");
      setPracticeAreas(response.data);
    } catch (error) {
      console.error("Error fetching practice areas:", error);
    }
  };

  const fetchStages = async () => {
    try {
      const response = await axios.get("/case_stages");
      setCaseStages(response.data);
    } catch (error) {
      console.error("Error fetching case stages:", error);
    }
  };

  useEffect(() => {
    fetchPracticeArea();
    fetchStages();
  }, []);

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchCases();
  }, [currentPage, filters]);

  return (
    <Box p={3}>
      <Box mt={2} mb={1} display="flex" justifyContent="space-between" gap={2}>
        <Typography level="h3" sx={{ color: "#000" }}>
          Case List Report
        </Typography>
        <div>
          <Button variant="outlined" sx={{ color: "#000" }}>
            Save Report
          </Button>

          <Button
            variant="outlined"
            sx={{ color: "#000" }}
            endDecorator={<MoreVertIcon />}
            onClick={handleExportClick}
          >
            Export
          </Button>
          <Menu
            anchorEl={exportAnchor}
            open={Boolean(exportAnchor)}
            onClose={handleExportClose}
          >
            <MenuItem
              onClick={() => {
                handleExportClose();
                exportAsPDF();
              }}
            >
              Export as PDF
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleExportClose();
                exportAsCSV();
              }}
            >
              Export as CSV
            </MenuItem>
          </Menu>
        </div>
      </Box>
      <Card variant="outlined" sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          {[
            "Case Status",
            "Firm Users",
            // "Lead Attorney",
            "Practice Area",
            "Case Stage",
            // "Offices",
            // "Originating Attorney",
            "Group By",
            "Date Range",
          ].map((label) => (
            <Grid key={label} xs={12} sm={6} md={4} lg={3}>
              <Typography level="body1" sx={{ mb: 1 }}>
                {label}
              </Typography>
              {label === "Practice Area" ? (
                <Select
                  value={filters.practiceArea}
                  onChange={(event, newValue) =>
                    handleFilterChange("practiceArea", newValue)
                  }
                >
                  <Option value="">All</Option>
                  {practiceAreas.map((area) => (
                    <Option key={area.id} value={area.practice_area_name}>
                      {area.practice_area_name}
                    </Option>
                  ))}
                </Select>
              ) : label === "Case Stage" ? (
                <Select
                  value={filters.stages}
                  onChange={(event, newValue) =>
                    handleFilterChange("stages", newValue)
                  }
                >
                  <Option value="">All</Option>
                  {caseStages.map((stage) => (
                    <Option key={stage.id} value={stage.case_stage_name}>
                      {stage.case_stage_name}
                    </Option>
                  ))}
                </Select>
              ) : label === "Date Range" ? (
                <Stack direction="row" spacing={2} alignItems="center">
                  <Select
                    value={dateRange}
                    onChange={(e, newValue) => {
                      setDateRange(newValue);
                      if (newValue === "All") {
                        handleFilterChange("startDate", "");
                        handleFilterChange("endDate", "");
                        fetchCases(); // Call your API with empty values
                      }
                    }}
                    sx={{ minWidth: 150 }}
                  >
                    {" "}
                    <Option value="All">All</Option>
                    <Option value="Open Date">Open Date</Option>
                    <Option value="Close Date">Close Date</Option>
                  </Select>
                  {(dateRange === "Open Date" ||
                    dateRange === "Close Date") && (
                    <>
                    <Typography variant="body2">Start Date</Typography>
                      <Input
                        type="date"
                        placeholder="Start Date"
                        sx={{ minWidth: 150 }}
                        onChange={(e) =>
                          handleFilterChange("startDate", e.target.value)
                        }
                      />
                      <Typography variant="body2">End Date</Typography>
                      <Input
                        type="date"
                        placeholder="End Date"
                        sx={{ minWidth: 150 }}
                        onChange={(e) =>
                          handleFilterChange("endDate", e.target.value)
                        }
                      />
                    </>
                  )}
                </Stack>
              ) : (
                <Select defaultValue="All">
                  <Option value="All">All</Option>
                </Select>
              )}
            </Grid>
          ))}
        </Grid>
        <Box mt={2} display="flex" alignItems="center" gap={2}>
          <Button color="primary" onClick={fetchCases}>
            Run Report
          </Button>
          {/* <Checkbox label="Save as Default View" /> */}
        </Box>
      </Card>
      {/* <Button variant="outlined">Customize Columns</Button> */}
      <div style={{ overflowX: 'auto' }}>

      <Table sx={{ minWidth: 600, borderRadius: 2, boxShadow: 2, padding: 2 }}>
        <thead>
          <tr>
            {[
              "Case",
              "Case Number",
              "Open Date",
              "Closed Date",
              "Statute of Limitations Date",
              "SOL Satisfied?",
              "Practice Area",
              "Case Stage",
              "Your Next Event",
              "Your Next Task",
              "Last Status Update",
            ].map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {cases.length ? (
            cases.map((item) => (
              <tr key={item.case_id} style={{ cursor: "pointer" }}>
                <td>{item.name}</td>
                <td>{item.case_number}</td>
                <td>{item.opened_date}</td>
                <td>{item.close_date}</td>
                <td>{item.limitation_date}</td>
                <td>{item.sql_satisfied}</td>

                <td>{item.practice_area}</td>
                <td>{item.case_stage}</td>
                <td>{item.next_event}</td>
                <td>{item.next_task}</td>
                <td>{item.last_status}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{ textAlign: "center", padding: "20px" }}>
                No cases found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Button
          variant="soft"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => prev - 1)}
        >
          Previous
        </Button>
        <Typography sx={{ color: "#000" }}>
          Page {currentPage} of {totalPages}
        </Typography>
        <Button
          variant="soft"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default CaseListReport;
