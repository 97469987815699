import * as React from "react";
import { Tabs, Tab, TabList, TabPanel } from "@mui/joy";
import Cases from "./Cases";
import PracticeAreas from "./Case/PracticeArea/PracticeArea";


const CasesTab = () => {
  return (
    
    <Tabs aria-label="Cases Tabs" defaultValue={0}>
      <TabList>
        <Tab value={0}>Cases</Tab>
        <Tab value={1}>Practice Areas</Tab>
        <Tab value={2}>Case Insights</Tab>
      </TabList>

      <TabPanel value={0}>
        <Cases />
      </TabPanel>
      <TabPanel value={1}>
        <PracticeAreas/>
        {/* <PracticeAreasComponent /> */}
      </TabPanel>
      <TabPanel value={2}>
        {/* <CaseInsightsComponent /> */}
      </TabPanel>
    </Tabs>
  );
};

export default CasesTab;
