import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, ModalDialog, Typography, Button, Input } from '@mui/joy';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import "./CaseStage.css";

const CaseStagesComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [stages, setStages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newStageName, setNewStageName] = useState('');

  useEffect(() => {
    fetchStages();
  }, []);

  const fetchStages = async () => {
    try {
      const response = await axios.get('/case_stages');
      // Assume backend sorts by stage_order
      setStages(response.data);
    } catch (error) {
      console.error('Error fetching stages:', error);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    fetchStages();
  };

  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);
      // Send updated stages (which include stage_order) to the backend
      await axios.put('/case_stages', stages);
      setIsEditing(false);
      fetchStages();
    } catch (error) {
      console.error('Error saving changes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStageChange = (id, newValue) => {
    setStages(
      stages.map(stage =>
        stage.case_stage_id === id ? { ...stage, case_stage_name: newValue } : stage
      )
    );
  };

  const handleDeleteStage = async (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this stage?");
    if (isConfirmed) {
      try {
        await axios.delete(`/case_stages/${id}`);
        fetchStages();
      } catch (error) {
        console.error("Error deleting stage:", error);
      }
    }
  };

  const handleModalAddStage = async () => {
    try {
      const newStage = { name: newStageName };
      const response = await axios.post('/case_stages', newStage);
      // Append the new stage and assign its stage_order to the current end of the list
      const updatedStages = [...stages, { ...response.data, stage_order: stages.length }];
      setStages(updatedStages);
      setNewStageName('');
      setOpenModal(false);
    } catch (error) {
      console.error('Error adding new stage:', error);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;
    const oldIndex = stages.findIndex(stage => `${stage.case_stage_id}` === active.id);
    const newIndex = stages.findIndex(stage => `${stage.case_stage_id}` === over.id);
    const newStages = arrayMove(stages, oldIndex, newIndex);
    // Update stage_order for each stage based on its new position
    const updatedStages = newStages.map((stage, index) => ({
      ...stage,
      stage_order: index,
    }));
    setStages(updatedStages);
  };

  return (
    <div className="case-stages-component">
      <div className="header" style={{ textAlign: 'left' }}>
        <h2 style={{color:"#fff"}}>Case Stages</h2>
      </div>
      <div style={{ padding: "25px", border: "1px solid #ddd", boxShadow: "0 .125rem .25rem rgba(0, 0, 0, .075)", backgroundClip:"border-box",  background:"#fff", borderRadius:"25px" }}>
        <p>Manage your case stages. Click Edit Stages to create or reorder stages.</p>
        <div className="button-group" style={{ display: 'flex', justifyContent: 'end', marginBottom: '16px', gap:'1rem' }}>
          {isEditing ? (
            <>
              <button onClick={handleCancel} className='cancel_stages_case_stage'>Cancel</button>
              <button onClick={() => setOpenModal(true)} className='addnew_stages_case_stage'>Add New Stage</button>
              <button 
                onClick={handleSaveChanges} 
                className='save_stages_case_stage' 
                disabled={isLoading}
              >
                {isLoading ? 'Saving...' : 'Save Changes'}
              </button>
            </>
          ) : (
            <>
              <button onClick={handleEditClick} className='edit_stages_case_stage'>Edit Stages</button>
              <button className='tellUs_stages_case_stage'>Tell us what you think</button>
            </>
          )}
        </div>

        {isEditing ? (
          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={stages.map(stage => `${stage.case_stage_id}`)} strategy={verticalListSortingStrategy}>
              <div className="stages-list">
                {stages.map(stage => (
                  <SortableItem 
                    key={stage.case_stage_id}
                    id={`${stage.case_stage_id}`}
                    stage={stage}
                    handleStageChange={handleStageChange}
                    handleDeleteStage={handleDeleteStage}
                  />
                ))}
              </div>
            </SortableContext>
          </DndContext>
        ) : (
          <div className="stages-list">
            {stages.map(stage => (
              <div 
                key={stage.case_stage_id} 
                className="stage-item" 
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  background: '#f7f7f9',
                  border: '1px solid #f7f7f9',
                  margin: '0.5rem 0',
                  padding: '0.5rem',
                  boxSizing: 'border-box'
                }}
              >
                <span style={{ margin: '0px 2rem', width: '50px', textAlign: 'left' }}>
                  {stage.case_stage_id}.
                </span>
                <span style={{ marginLeft: '5rem', flex: '1', textAlign: 'left' }}>
                  {stage.case_stage_name}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <ModalDialog aria-labelledby="add-new-stage-title" aria-describedby="add-new-stage-description" sx={{ width: 400 }}>
          <Typography id="add-new-stage-title" component="h2">
            Add New Stage
          </Typography>
          <Input 
            placeholder="Stage Name"
            value={newStageName}
            onChange={(e) => setNewStageName(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
          <Button onClick={handleModalAddStage} sx={{ mt: 2 }}>
            Submit
          </Button>
          <Button onClick={() => { setOpenModal(false); setNewStageName(''); }} sx={{ mt: 1 }}>
            Cancel
          </Button>
        </ModalDialog>
      </Modal>
    </div>
  );
};

export default CaseStagesComponent;
