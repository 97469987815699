import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import Stepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import { Autocomplete, TextField } from "@mui/joy";
import Switch from '@mui/joy/Switch';
import { auth } from "../firebase/firebase";
const steps = ["Contact", "Basic", "Billing", "Staff"];

const AddCaseModal = ({ open, onClose, onCaseAdded, onOpenAddContact, parentType,caseDetails,caseId,onCaseUpdates,onUpdateCase }) => {
  const [step, setStep] = useState(1);
  const [showCustomFields, setShowCustomFields] = useState(true);
  const [customFields, setCustomFields] = useState([]);

  // Step 1: Contact selection
  const [contactSearch, setContactSearch] = useState("");
  const [contactResults, setContactResults] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [caseColumns, setCaseColumns] = useState([]); // Store fetched columns
  const [customDetails, setCustomDetails] = useState({}); // Store field values
  const currentUser = auth.currentUser?.uid;

  useEffect(() => {
    if (caseId === 2) {
      setStep(2);
    }
  }, [caseId]);
  const [caseStages, setCaseStages] = useState([]);

  useEffect(() => {
    // Fetch case stages from the API
    axios
      .get("/case_stages") // Update with your actual API endpoint
      .then((response) => {
        // Extract the names and sort by stage_order
        const sortedStages = response.data.sort(
          (a, b) => a.stage_order - b.stage_order
        );
        setCaseStages(
          sortedStages.map((stage) => ({
            label: stage.case_stage_name,
            id: stage.case_stage_id,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching case stages:", error);
      });
  }, []);
  useEffect(() => {
    const fetchCaseColumns = async () => {
      try {
        const response = await axios.get(`/columns?parent_type=${parentType}`);

        const { table_columns, custom_fields } = response.data;


        const filteredColumns = table_columns.filter(
          (col) => col !== "case_id" && col !== "created_at" && col !== "updated_at" && col !== "name"
        );

        setCaseColumns(filteredColumns || []);
        setCustomFields(custom_fields || []); // Store custom fields separately
      } catch (error) {
        console.error("Error fetching case columns:", error);
      }
    };

    if (parentType) {
      fetchCaseColumns();
    }
  }, [parentType]);

  // Step 2: Basic Case Details – keys match DB columns exactly.
  const [basicDetails, setBasicDetails] = useState({
    name: "",
    case_number: "",
    practice_area: "",
    case_stage: "",
    opened_date: "",
    petitioner: ""
  });
  useEffect(() => {
    if (caseDetails && customFields) {
      setBasicDetails({
        name: caseDetails?.name || "",
        case_number: caseDetails?.case_number || "",
        practice_area: caseDetails?.practice_area || "",
        case_stage: caseDetails?.case_stage || "",
        opened_date: caseDetails?.opened_date || "",
        petitioner: caseDetails?.petitioner || "",
      });
  
      const initialCustomDetails = {};
      customFields.forEach((field) => {
        const fieldKey = field.custom_fields_id;
        let fieldValue = caseDetails[field.custom_fields_name.toLowerCase().replace(/ /g, "_")] || "";
        // Convert value for dropdowns (map option_value to list_options_id)
        if (field.field_type === "list") {
          const selectedOption = field.list_options.find(option => option.option_value === fieldValue);
          fieldValue = selectedOption ? selectedOption.list_options_id : "";
        }
        if (field.field_type === "date" && fieldValue) {
          fieldValue = new Date(fieldValue).toISOString().split("T")[0];
        }
        initialCustomDetails[fieldKey] = fieldValue;
      });
  
      setCustomDetails(initialCustomDetails);
    }
  }, [customFields, caseDetails]);
  // Step 2 (Custom Fields): Additional fields (only a subset shown; add more as needed)
  // const [customDetails, setCustomDetails] = useState({
  //   applicable_deductible: "",
  //   assigned_attorneys_email: "",
  //   attorneys_fee_settlement: "",
  //   calendar_call: "",
  //   case_costs: "",
  //   claim_number: "",
  //   clients_email: "",
  //   clients_phone_number: "",
  //   closed_date: "",
  //   county: "",
  //   coverage_determination: "",
  //   date_of_damage: "",
  //   defendant_discovery_responses_received: "",
  //   defense_attorney: "",
  //   defense_attorney_firm: "",
  //   description: "",
  //   property_address: ""
  //   // ...add the remaining custom fields as desired
  // });

  // Step 3: Billing Information – key must match DB column
  const [billing, setBilling] = useState({
    billing_method: ""
  });

  // Step 4: Staff – keys match DB columns exactly
  const [staff, setStaff] = useState({
    assigned_attorney: "",
    origination_credit: ""
  });

  // Fetch contacts based on search term
  const fetchContacts = async (query) => {
    try {
      const response = await axios.get("/contacts", { params: { search: query } });
      setContactResults(response.data.contacts || []);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  useEffect(() => {
    if (contactSearch.trim() !== "") {
      fetchContacts(contactSearch);
    } else {
      setContactResults([]);
    }
  }, [contactSearch]);

  // Navigation functions
  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  // Toggle custom fields display in Step 2
  const toggleCustomFields = () => setShowCustomFields((prev) => !prev);

  // Submit new case – merge all state objects into one payload
  const handleSubmit = async () => {
    const payload = {
      contact_id: selectedContact?.contact_id,
      ...basicDetails,
      ...billing,
      ...staff,
      ...Object.fromEntries(
        Object.entries(customDetails).map(([key, value]) => {
          const field = customFields.find(field => field.custom_fields_id.toString() === key);
  
          if (field) {
            const formattedFieldName = field.custom_fields_name
              .toLowerCase()
              .replace(/\s+/g, '_')
              .replace(/[^a-z0-9_]/gi, ''); // Clean the field name
  
            if (field.field_type === "list") {
              const option = field.list_options.find(option => option.list_options_id.toString() === value.toString());
              if (option) {
                return [formattedFieldName, option.option_value];
              }
            }
            return [formattedFieldName, value];
          }
          return [key, value]; 
        })
      )
    };
  
    try {
      
      await axios.post("/cases", payload, {
        headers: {
          'x-user-uid': currentUser,
        },
      });
      onCaseAdded(); 
      onClose(); 
    } catch (error) {
      console.error("Error creating case:", error);
    }
  };
  const handleUpdate = async (a) => {
    if (!caseDetails?.case_id) {
      console.error("No Case ID provided for updating case");
      return;
    }
  
    const payload = {
      contact_id: selectedContact?.contact_id,
      ...basicDetails,
      ...billing,
      ...staff,
      ...Object.fromEntries(
        Object.entries(customDetails).map(([key, value]) => {
          const field = customFields.find(field => field.custom_fields_id.toString() === key);
  
          if (field) {
            const formattedFieldName = field.custom_fields_name
              .toLowerCase()
              .replace(/\s+/g, '_')
              .replace(/[^a-z0-9_]/gi, ''); // Clean the field name
  
            if (field.field_type === "list") {
              const option = field.list_options.find(option => option.list_options_id.toString() === value.toString());
              if (option) {
                return [formattedFieldName, option.option_value];
              }
            }
            return [formattedFieldName, value];
          }
          return [key, value];
        })
      )
    };
  
    try {
      await axios.put(`/cases/${caseDetails.case_id}`, payload, {
        headers: {
          'x-user-uid': currentUser 
        }
      });
 
      onClose(); // Close the form/modal
      onCaseUpdates();
      if (onUpdateCase) onUpdateCase(); 

    } catch (error) {
      console.error("Error updating case:", error);
    }
  };

  // Render step content
  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <Box>
            <Typography level="h6" sx={{ mb: 1 }}>
              Step 1: Select a Contact
            </Typography>
            <Input
              placeholder="Search Contacts"
              value={contactSearch}
              onChange={(e) => setContactSearch(e.target.value)}
              fullWidth
              sx={{ my: 1 }}
            />
            <Box
              sx={{
                maxHeight: 200,
                overflowY: "auto",
                mt: 1,
                border: "1px solid",
                borderColor: "neutral.outlined",
                p: 1
              }}
            >
              {contactResults.length > 0 ? (
                contactResults.map((contact) => (
                  <Box
                    key={contact.contact_id}
                    sx={{
                      p: 1,
                      borderBottom: "1px solid",
                      borderColor: "neutral.outlined",
                      cursor: "pointer",
                      backgroundColor:
                        selectedContact?.contact_id === contact.contact_id
                          ? "neutral.softHoverBg"
                          : "transparent"
                    }}
                    onClick={() => setSelectedContact(contact)}
                  >
                    {contact.first_name} {contact.last_name} - {contact.email}
                  </Box>
                ))
              ) : (
                <Typography level="body2">No contacts found.</Typography>
              )}
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
              <Button variant="outlined" onClick={onOpenAddContact}>
                Add New Contact
              </Button>
              <Button variant="solid" onClick={nextStep} disabled={!selectedContact}>
                Next
              </Button>
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Typography level="h6" sx={{ mb: 1 }}>
              Step 2: Basic Case Details
            </Typography>
            <Input
              placeholder="Name"
              value={basicDetails.name}
              onChange={(e) => setBasicDetails({ ...basicDetails, name: e.target.value })}
              fullWidth
              sx={{ my: 1 }}
            />
            <Input
              placeholder="Case Number"
              value={basicDetails.case_number}
              onChange={(e) => setBasicDetails({ ...basicDetails, case_number: e.target.value })}
              fullWidth
              sx={{ my: 1 }}
            />
            <Input
              placeholder="Practice Area"
              value={basicDetails.practice_area}
              onChange={(e) => setBasicDetails({ ...basicDetails, practice_area: e.target.value })}
              fullWidth
              sx={{ my: 1 }}
            />
             <Autocomplete
              options={caseStages}
              getOptionLabel={(option) => option.label}
              value={
                caseStages.find(
                  (stage) => stage.label === basicDetails.case_stage
                ) || null
              }
              onChange={(event, newValue) =>
                setBasicDetails({
                  ...basicDetails,
                  case_stage: newValue ? newValue.label : "",
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Case Stage"
                  placeholder="Type to search"
                  variant="outlined"
                  fullWidth
                  sx={{ my: 1 }}
                />
              )}
            />
            <Typography level="body2" sx={{ mt: 1 }}>
              Opened Date
            </Typography>
            <Input
              type="date"
              value={basicDetails.opened_date}
              onChange={(e) => setBasicDetails({ ...basicDetails, opened_date: e.target.value })}
              fullWidth
              sx={{ my: 1 }}
            />
            <Input
              placeholder="Petitioner"
              value={basicDetails.petitioner}
              onChange={(e) => setBasicDetails({ ...basicDetails, petitioner: e.target.value })}
              fullWidth
              sx={{ my: 1 }}
            />
            {/* <Button variant="outlined" onClick={toggleCustomFields} sx={{ mt: 1 }}>
              {showCustomFields ? "Hide Custom Fields" : "Add Custom Fields"}
            </Button> */}
                <div onClick={toggleCustomFields} sx={{ mt: 1 }}>
      <Switch
        checked={showCustomFields}
        onChange={(event) => setShowCustomFields(event.target.checked)}
      />
      {/* <span style={{ marginLeft: '8px' }}>
        {showCustomFields ? "Hide Custom Fields" : "Add Custom Fields"}
      </span> */}
    </div>        
            {showCustomFields && (
              <Box sx={{ mt: 2 }}>
                {/* Render Table Columns */}
                {/* {caseColumns.map((column) => (
                  <Input
                    key={column}
                    placeholder={column.replace(/_/g, " ")} // Convert snake_case to readable format
                    value={customDetails[column] || ""}
                    onChange={(e) =>
                      setCustomDetails({ ...customDetails, [column]: e.target.value })
                    }
                    fullWidth
                    sx={{ my: 1 }}
                  />
                ))} */}

                {/* Render Custom Fields */}
                {customFields.map((field) => (
                  <Box key={field.custom_fields_id} sx={{ my: 1 }}>
                    <Typography level="body2" sx={{ mb: 1 }}>
                      {field.custom_fields_name}
                    </Typography>

                    {field.field_type === "list" ? (
                      <Select
                        value={customDetails[field.custom_fields_id] || ""}
                        onChange={(e, newValue) =>
                          setCustomDetails({ ...customDetails, [field.custom_fields_id]: newValue })
                        }
                        fullWidth
                      >
                        <Option value="">Select an option</Option>
                        {field.list_options.map((option) => (
                          <Option key={option.list_options_id} value={option.list_options_id}>
                            {option.option_value}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        type={field.field_type === "date" ? "date" : "text"}
                        placeholder={field.custom_fields_name}
                        value={customDetails[field.custom_fields_id] || ""}
                        onChange={(e) =>
                          setCustomDetails({ ...customDetails, [field.custom_fields_id]: e.target.value })
                        }
                        fullWidth
                      />
                    )}
                  </Box>
                ))}
              </Box>
            )}
<Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
        {caseId === 2 ? (
         <Button variant="solid" onClick={() => handleUpdate(caseDetails?.case_id)}>
         Update
       </Button>
       
        ) : (
          <>

            <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
              <Button variant="outlined" onClick={prevStep}>
                Back
              </Button>
              <Button variant="solid" onClick={nextStep}>
                Next
              </Button>
              </Box>
          </>
        )}
            </Box>
          </Box>
        );
      case 3:
        return (
          <Box>
            <Typography level="h6" sx={{ mb: 1 }}>
              Step 3: Billing Information
            </Typography>
            <Select
              placeholder="Billing Method"
              value={billing.billing_method}
              onChange={(e, newValue) => setBilling({ ...billing, billing_method: newValue })}
              fullWidth
              sx={{ my: 1 }}
            >
              <Option value="hourly">Hourly</Option>
              <Option value="contingency">Contingency</Option>
              <Option value="flat fee">Flat Fee</Option>
              <Option value="mix">Mix of Flat Fee and Hourly</Option>
              <Option value="pro bono">Pro Bono</Option>
            </Select>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
              <Button variant="outlined" onClick={prevStep}>
                Back
              </Button>
              <Button variant="solid" onClick={nextStep} disabled={!billing.billing_method}>
                Next
              </Button>
            </Box>
          </Box>
        );
      case 4:
        return (
          <Box>
            <Typography level="h6" sx={{ mb: 1 }}>
              Step 4: Staff
            </Typography>
            <Input
              placeholder="Assigned Attorney"
              value={staff.assigned_attorney}
              onChange={(e) => setStaff({ ...staff, assigned_attorney: e.target.value })}
              fullWidth
              sx={{ my: 1 }}
            />
            <Input
              placeholder="Origination Credit"
              value={staff.origination_credit}
              onChange={(e) => setStaff({ ...staff, origination_credit: e.target.value })}
              fullWidth
              sx={{ my: 1 }}
            />
            <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
              <Button variant="outlined" onClick={prevStep}>
                Back
              </Button>
              <Button variant="solid" onClick={handleSubmit} disabled={!staff.assigned_attorney || !staff.origination_credit}>
                Create Case
              </Button>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  // Reset state when modal closes
  useEffect(() => {
    if (!open) {
      setStep(1);
      setSelectedContact(null);
      setContactSearch("");
      setContactResults([]);
      setBasicDetails({
        name: "",
        case_number: "",
        practice_area: "",
        case_stage: "",
        opened_date: "",
        petitioner: ""
      });
      setBilling({ billing_method: "" });
      setStaff({ assigned_attorney: "", origination_credit: "" });
      setCustomDetails({
        applicable_deductible: "",
        assigned_attorneys_email: "",
        attorneys_fee_settlement: "",
        calendar_call: "",
        case_costs: "",
        claim_number: "",
        clients_email: "",
        clients_phone_number: "",
        closed_date: "",
        county: "",
        coverage_determination: "",
        date_of_damage: "",
        defendant_discovery_responses_received: "",
        defense_attorney: "",
        defense_attorney_firm: "",
        description: "",
        property_address: "",
        defs_agreed_order_disco: "",
        defs_mfext_filed_complaint: "",
        defs_mfext_filed_disco: "",
        depo_request_cr: "",
        depo_request_fa: "",
        division: "",
        expert_fees_1: "",
        hearing_request_mtc: "",
        indemnity_settlement: "",
        insurance_policy_number: "",
        judge: "",
        last_offer_of_settlement: "",
        msj_hearing_date: "",
        ocs_direct_email: "",
        ocs_fax_number: "",
        ocs_phone_number: "",
        ocs_service_email: "",
        pa_estimate: "",
        pa_fee: "",
        paralegal_assignment: "",
        plaintiffs_agreed_order_disco: "",
        plaintiffs_disco_responses_sent: "",
        plaintiffs_mfext_filed_disco: "",
        public_adjusters: "",
        respondent: "",
        responses_to_defendants_discovery_due: "",
        responses_to_plaintiffs_discovery_due: "",
        retainer_type: "",
        schedulers_email: "",
        scheduling_assignment: "",
        settlement_status: "",
        time_entry_amount: "",
        trial_period_start_date: "",
        type_of_damage: "",
        type_of_loss_automated: ""
      });
      setShowCustomFields(false);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Sheet
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", md: 600 },
          bgcolor: "background.body",
          boxShadow: "lg",
          p: 4,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Stepper activeStep={step - 1} sx={{ mb: 2 }}>
          {steps.map((label, index) => (
            <Step
              key={index}
              indicator={
                <StepIndicator
                  variant={step - 1 === index ? "solid" : "outlined"}
                  color="neutral"
                >
                  {index + 1}
                </StepIndicator>
              }
            >
              <Typography level="body2">{label}</Typography>
            </Step>
          ))}
        </Stepper>
        {renderStepContent()}
      </Sheet>
    </Modal>
  );
};

export default AddCaseModal;