import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  IconButton,
  Menu,
  MenuItem,
  ListItemDecorator,
  Box,
  Typography,
  Card,
  CardContent,
  Modal,
  ModalDialog,
  Stack
} from "@mui/joy";
import { Add, Delete, Palette, Edit } from "@mui/icons-material";
import axios from "axios";

// const API_URL = "http://localhost:3001/event-types"; 

const colors = [
  "#FFCDD2", "#F8BBD0", "#E1BEE7", "#D1C4E9", "#C5CAE9",
  "#BBDEFB", "#B3E5FC", "#B2EBF2", "#B2DFDB", "#C8E6C9",
  "#DCEDC8", "#F0F4C3", "#FFF9C4", "#FFECB3", "#FFE0B2",
];

const EventTypes = () => {
  const [eventTypes, setEventTypes] = useState([]);
  const [newEvent, setNewEvent] = useState("");
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedColor, setSelectedColor] = useState("#BBDEFB");
  const [editingEvent, setEditingEvent] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [colorAnchor, setColorAnchor] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  // Fetch event types from backend
  const fetchEvents = async () => {
    try {
      const response = await axios.get("/event-types");
      setEventTypes(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  // Add a new event typ
  const addEventType = async () => {
    if (!newEvent.trim()) return;
    try {
      const response = await axios.post("/event-types", {
        event_type_name: newEvent,
        color_code: selectedColor,
      });
      setEventTypes([...eventTypes, response.data]);
      setNewEvent("");
      setOpenModal(false);
    } catch (error) {
      console.error("Error adding event type:", error);
    }
  };

  // Update an event type
  const updateEventType = async (id, name) => {
    try {
      await axios.put(`${"/event-types"}/${id}`, { event_type_name: name, color_code: selectedColor });
      fetchEvents();
      setEditingEvent(null);
    } catch (error) {
      console.error("Error updating event type:", error);
    }
  };

  // Delete an event type
  const deleteEventType = async (id) => {
    try {
      await axios.delete(`${"/event-types"}/${id}`);
      setEventTypes(eventTypes.filter(event => event.id !== id));
    } catch (error) {
      console.error("Error deleting event type:", error);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, mx: "auto", mt: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Button variant="soft" startDecorator={<Add />} onClick={() => setOpenModal(true)}>
          Add Event Type
        </Button>
        <Typography level="h2">Manage your Event Types</Typography>
      </Box>

      <Card sx={{ p: 2, mt: 2 }}>
        <CardContent>
          {eventTypes.map((event) => (
            <Box key={event.id} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <IconButton
                sx={{ backgroundColor: event.color_code, borderRadius: "50%", width: 30, height: 30, mr: 1 }}
                onClick={(e) => setMenuAnchor(e.currentTarget)}
              >
                <Palette />
              </IconButton>

              <Input
                value={editingEvent?.id === event.id ? editingEvent.name : event.event_type_name}
                onChange={(e) =>
                  setEditingEvent({ id: event.id, name: e.target.value })
                }
                sx={{ flexGrow: 1, mr: 1 }}
                onFocus={() => setEditingEvent({ id: event.id, name: event.event_type_name })}
              />

              {editingEvent?.id === event.id && (
                <Button
                  variant="solid"
                  startDecorator={<Edit />}
                  onClick={() => updateEventType(event.id, editingEvent.name)}
                >
                  Update
                </Button>
              )}

              <IconButton color="danger" onClick={() => deleteEventType(event.id)}>
                <Delete />
              </IconButton>
            </Box>
          ))}

          {/* Color Picker Menu */}
          <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: 1, p: 1 }}>

            {colors.map((color) => (
              <MenuItem key={color} onClick={() => { setSelectedColor(color); setMenuAnchor(null); }}>
                <ListItemDecorator>
                  <Box sx={{ width: 20, height: 20, backgroundColor: color, borderRadius: "50%" }} />
                </ListItemDecorator>
              </MenuItem>
            ))}
            </Box>
          </Menu>

          {/* Add Event Type Modal */}
          {/* Add Event Type Modal */}
{/* Add Event Type Modal */}
<Modal open={openModal} onClose={() => setOpenModal(false)}>
  <ModalDialog>
    <Typography level="h3">Add Event Type</Typography>
    <Stack spacing={2}>
      <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
        {/* Color Picker Button */}
        <IconButton
          sx={{
            backgroundColor: selectedColor,
            borderRadius: "50%",
            width: 30,
            height: 30,
            mr: 1,
          }}
          onClick={(e) => {
            setColorAnchor(e.currentTarget);
          }}
        >
          <Palette />
        </IconButton>

        {/* Color Picker Menu (Fixed Positioning) */}
        <Menu
          anchorEl={colorAnchor}
          open={Boolean(colorAnchor)}
          onClose={() => setColorAnchor(null)}
          disablePortal
          placement="bottom-start"
          slotProps={{ root: { onClick: (e) => e.stopPropagation() } }}
        >
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: 1, p: 1 }}>
            {colors.map((color) => (
              <IconButton
                key={color}
                sx={{
                  backgroundColor: color,
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                }}
                onClick={() => {
                  setSelectedColor(color);
                  setColorAnchor(null);
                }}
              />
            ))}
          </Box>
        </Menu>

        {/* Event Type Input */}
        <Input
          placeholder="New Event Type"
          value={newEvent}
          onChange={(e) => setNewEvent(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
      </Box>

      {/* Add Event Button */}
      <Button onClick={addEventType} variant="solid">
        Add Event
      </Button>
    </Stack>
  </ModalDialog>
</Modal>


        </CardContent>
      </Card>
    </Box>
  );
};

export default EventTypes;
