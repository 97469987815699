import React, { useState, useEffect } from "react";
import { Box, Button, Input, Table, Sheet, Typography, Chip, IconButton, CircularProgress } from "@mui/joy";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import AddTimeEntryModal from "../../components/AddTimeEntryModal";
import AddExpenseModal from "../../components/AddExpenseModal";

const Expense = () => {
  const [expenses, setExpenses] = useState([]);
  const [filters, setFilters] = useState({
    case_id: "",
    description: "",
    start_date: "",
    end_date: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [cases, setCases] = useState([]);
 const [singleCase, setSingleCase]= useState(null);
 const [loading, setLoading] = useState(false); 

  useEffect(() => {
    fetchExpenses();
  }, [currentPage, filters]);

  const fetchExpenses = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/expenses", { params: { ...filters, page: currentPage } });
      setExpenses(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error("Error fetching expenses:", error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleFilterSubmit = () => {
    setCurrentPage(1);
    fetchExpenses();
  };

  const handleResetFilters = () => {
    setFilters({ case_id: "", description: "", start_date: "", end_date: "" });
    setCurrentPage(1);
  };
  const fetchCases = async (case_id) => {
    try {
        const response = await axios.get(`/casesbillexpense/${case_id}`);
        setCases(response.data.cases)
        setSingleCase(response.data);
    } catch (error) {
        console.error("Error fetching cases:", error);
        setSingleCase([]);
    }
};
  const handleAddClick=()=>{
    setEditData(null); 
    setModalOpen(true); 
    
  
            
            fetchCases();
  }
    const handleEditClick = (entry) => {
        fetchCases(entry.case_id)
      setEditData(entry); // Set selected entry data
      setModalOpen(true); // Open the modal
      
    };
    const handleDelete = async (entry) => {
   
      const confirmDelete = window.confirm("Are you sure you want to delete this entry?");
      if (confirmDelete) {
          try {
              // Send DELETE request to the API
              await axios.delete(`/expenses/${entry.expense_id}`);
              console.log("Entry deleted successfully");
              fetchExpenses()
              // Optionally, refresh the data or handle state change after deletion
          } catch (error) {
              console.error("Error deleting entry:", error);
          }
      }
  };

  return (
    <>
    <Sheet sx={{ overflowX: "auto", background: "transparent" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom:"2rem"}}>
            <Typography level="h4" gutterBottom>
              Time Entries
            </Typography>
            <div>
            {/* <Button variant="soft" disabled>
                Bulk Delete
              </Button> */}
              <Button variant="solid" onClick={handleAddClick}>Add Time Entry</Button>
            </div>
          
              </div>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
        <Input name="case_id" placeholder="Filter by case" value={filters.case_id} onChange={handleFilterChange} />
        <Input name="description" placeholder="Description" value={filters.description} onChange={handleFilterChange} />
        <Input type="date" name="start_date" value={filters.start_date} onChange={handleFilterChange} />
        <Input type="date" name="end_date" value={filters.end_date} onChange={handleFilterChange} />
        <Button variant="contained" color="primary" onClick={handleFilterSubmit}>Filter</Button>
        <Button variant="outlined" onClick={handleResetFilters}>Reset</Button>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
          <CircularProgress size="lg" />
        </Box>
      ) : (
        <Table sx={{ minWidth: 600, borderRadius: 2, boxShadow: 2, overflowX: "auto", display: "block"  }}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Activity</th>
            <th>Units</th>
            <th>Cost</th>
            <th>Description</th>
            <th>Billable</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {expenses.map((entry) => (
            <tr key={entry.expense_id}>
              <td>{new Date(entry.entry_date).toLocaleDateString()}</td>
              <td>{entry.activity_name}</td>
              <td>{entry.units}</td>
              <td>${parseFloat(entry.cost).toFixed(2)}</td>
              <td>{entry.description}</td>
              <td>
                <Chip color={entry.billable ? "success" : "neutral"}>
                  {entry.billable ? "Billable" : "Non-Billable"}
                </Chip>
              </td>
              <td>
                <IconButton size="sm" onClick={() => handleEditClick(entry)}>
                  <Edit />
                </IconButton>
                <IconButton size="sm" color="danger" onClick={() => handleDelete(entry)}>
                  <Delete />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
        <Button disabled={currentPage === 1} onClick={() => setCurrentPage((prev) => prev - 1)}>
          Previous
        </Button>
        <Typography>
          Page {currentPage} of {totalPages}
        </Typography>
        <Button disabled={currentPage === totalPages} onClick={() => setCurrentPage((prev) => prev + 1)}>
          Next
        </Button>
      </Box>
      <AddExpenseModal
     open={modalOpen} 
     onClose={() => setModalOpen(false)} 
     caseId={editData?.case_id}
     parentType="timeExpense" 
     editData={editData} 
     singleCase={editData?.case_id}
     cases={cases}
     onSuccess={fetchExpenses} 
   />    </Sheet>
    
    </>
  );
};

export default Expense;