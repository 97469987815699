import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Typography, Button, Select, Option, Card, IconButton } from "@mui/joy";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCustomFieldModal from "../../components/AddCustomFieldModal";

const tabs = ["Cases / Matters", "Contacts", "Companies", "Time & Expense"];

const CustomFields = () => {
  const [activeTab, setActiveTab] = useState("Cases / Matters");
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add"); // "add" or "edit"
  const [selectedField, setSelectedField] = useState(null);

  let parentType = "";
  if (activeTab === "Cases / Matters") parentType = "case";
  else if (activeTab === "Contacts") parentType = "contact";
  else if (activeTab === "Companies") parentType = "client";
  else if (activeTab === "Time & Expense") parentType = "timeExpense";


  const fetchData = () => {
    let parentType = "";
  
    if (activeTab === "Cases / Matters") {
      parentType = "case";
    } else if (activeTab === "Contacts") {
      parentType = "contact";
    } else if (activeTab === "Companies") {
      parentType = "client";
    } else if (activeTab === "Time & Expense") {
      parentType = "timeExpense";
    }
  
    axios
      .get("/custom_fields", {
        params: parentType ? { parent_type: parentType } : {}, // Pass query param only if needed
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching custom fields:", error);
        setData([]);
      });
  };

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  const handleDelete = (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      axios
        .delete(`/custom_fields/${id}`)
        .then((response) => {
          console.log("Deleted successfully:", response.data);
          fetchData();
        })
        .catch((error) => {
          console.error("Error deleting custom field:", error);
        });
    } else {
      console.log("Delete action canceled");
    }
  };

  const handleEdit = (field) => {
    setSelectedField(field);
    setModalMode("edit");
    setModalOpen(true);
  };

  const handleAdd = () => {
    setSelectedField(null);
    setModalMode("add");
    setModalOpen(true);
  };

  const handleModalSuccess = () => {
    fetchData();
  };

  return (
    <Box sx={{ p: 3, margin: "auto" }}>
      <Typography level="h4" sx={{ mb: 1 }}>Custom Fields</Typography>

      {/* Tabs */}
      <Box sx={{ display: "flex", gap: 2, borderBottom: "2px solid black", pb: 1, mb: 2 }}>
        {tabs.map((tab) => (
          <Typography
            key={tab}
            sx={{
              cursor: "pointer",
              fontWeight: activeTab === tab ? "bold" : "normal",
              borderBottom: activeTab === tab ? "2px solid black" : "none",
            }}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </Typography>
        ))}
      </Box>

      <Typography level="body1" sx={{ mb: 2 }}>
        Manage {activeTab} Custom Fields
      </Typography>
      <Typography level="body2" sx={{ mb: 2 }}>
        This is where you can add, edit, delete, and sort {activeTab} custom fields. The fields created here will be available when creating or editing {activeTab} in MyCase.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={handleAdd}
          sx={{ mb: 3, backgroundColor: "#007bff", color: "white", borderRadius: "20px" }}
        >
          Add Custom Field
        </Button>
      </Box>

      {/* Show Select Dropdown only for Cases / Matters */}
      {/* {activeTab === "Cases / Matters" && (
        <Select defaultValue="Global Settings" sx={{ mb: 3, width: 300 }}>
          <Option value="Global Settings">------ Global Settings ------</Option>
        </Select>
      )} */}

      {/* Display fetched data or "No data available" */}
      {data.length > 0 ? (
        data.map((field, index) => (
          <Card
            key={index}
            sx={{
              mb: 2,
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Box>
              <Typography fontWeight="bold">{field.custom_fields_name}</Typography>
              <Typography level="body2">
                {field.field_type ? field.field_type : "N/A"}
              </Typography>
            </Box>
            <Box>
              <IconButton variant="plain" onClick={() => handleEdit(field)}>
                <EditIcon />
              </IconButton>
              <IconButton variant="plain" onClick={() => handleDelete(field.custom_fields_id)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Card>
        ))
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
          <Typography level="h6" sx={{ color: "gray" }}>No data available</Typography>
        </Box>
      )}

      <AddCustomFieldModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        parentType={parentType}
        mode={modalMode}
        initialData={selectedField}
        onSuccess={handleModalSuccess}
      />
    </Box>
  );
};

export default CustomFields;