import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/joy/IconButton";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Typography,
  Button,
  Input,
  CircularProgress,
  Table,
  Select,
  Option,
} from "@mui/joy";
import AddCaseModal from "../components/AddCaseModal";

const Cases = () => {
  const [cases, setCases] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [practiceAreas, setPracticeAreas] = useState([]);
  const [stages, setStages] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    practiceArea: "",
    stages: "",
    assignedAttorney: "",
  });
  const [sortConfig, setSortConfig] = useState({
    key: "opened_date",
    direction: "desc",
  });
  const [openCaseModal, setOpenCaseModal] = useState(false);
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3001";

  const parseDate = (dateString) => {
    if (!dateString || !dateString.includes("/")) return null;
    const [month, day, year] = dateString.split("/");
    return new Date(`20${year}-${month}-${day}`);
  };

  const fetchPracticeArea = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/practice_areas");
      setPracticeAreas(response.data);
      console.log("response.data", response.data);
    } catch (error) {
      console.error("Error fetching practice areas:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPracticeArea();
  }, []);

  useEffect(() => {
    fetchStages();
  }, []);

  const fetchStages = async () => {
    try {
      const response = await axios.get("/case_stages");
      setStages(response.data);
      console.log("stages", response.data);
    } catch (error) {
      console.error("Error fetching stages:", error);
    }
  };

  const fetchCases = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/cases`, {
        params: {
          page: currentPage,
          search: filters.search,
          practice_area: filters.practiceArea,
          case_stage: filters.stages,
          assigned_attorney: filters.assignedAttorney,
        },
      });
      const formattedCases = response.data.cases.map((item) => ({
        ...item,
        parsedDate: parseDate(item.opened_date),
      }));
      setCases(formattedCases);
      setTotalPages(Math.ceil(response.data.totalCases / 20));
    } catch (error) {
      console.error("Error fetching cases:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters]);

  // Updated handleFilterChange to correctly capture the value from the search input or newValue from Selects.
  const handleFilterChange = (e, newValue) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: newValue !== undefined ? newValue : value });
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    // Toggle the sort direction if clicking the same key
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));

    // Note: since setSortConfig is async, use the current sortConfig.direction
    const sortedCases = [...cases].sort((a, b) => {
      if (key === "opened_date") {
        const dateA = parseDate(a.opened_date);
        const dateB = parseDate(b.opened_date);
        if (!dateA || !dateB) return 0;
        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        const valueA = a[key]?.toLowerCase() || "";
        const valueB = b[key]?.toLowerCase() || "";
        return sortConfig.direction === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
    });

    setCases(sortedCases);
  };

  const getSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? "▲" : "▼";
    }
    return "▲▼";
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          gap: 1,
        }}
      >
        <Typography level="h4">Cases</Typography>
        <Button
          startDecorator={<AddIcon />}
          variant="solid"
          size="sm"
          onClick={() => setOpenCaseModal(true)}
        >
          Add a Case
        </Button>
      </Box>

      {/* Filters */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          mb: 2,
        }}
      >
        <Input
          placeholder="Search by name or case number"
          name="search"
          value={filters.search}
          onChange={handleFilterChange}
          sx={{ minWidth: 200 }}
        />
        <Select
          placeholder="Practice Area"
          name="practiceArea"
          value={filters.practiceArea}
          onChange={(event, newValue) =>
            handleFilterChange({ target: { name: "practiceArea" } }, newValue)
          }
          sx={{ minWidth: 200 }}
        >
          <Option value="">All Practice Areas</Option>
          {practiceAreas.map(
            (area, index) =>
              area.practice_area_name && (
                <Option key={index} value={area.practice_area_name}>
                  {area.practice_area_name.length > 15
                    ? `${area.practice_area_name.substring(0, 15)}...`
                    : area.practice_area_name}
                </Option>
              )
          )}
        </Select>

        <Select
          placeholder="Case Stage"
          name="stages"
          value={filters.stages}
          onChange={(event, newValue) =>
            handleFilterChange({ target: { name: "stages" } }, newValue)
          }
          sx={{ minWidth: 200 }}
        >
          <Option value="">All Case Stages</Option>
          {stages.map(
            (stage) =>
              stage.case_stage_name && (
                <Option key={stage.case_stage_id} value={stage.case_stage_name}>
                  {stage.case_stage_name.length > 15
                    ? `${stage.case_stage_name.substring(0, 15)}...`
                    : stage.case_stage_name}
                </Option>
              )
          )}
        </Select>
        <Select
          placeholder="Lead Attorney"
          name="assignedAttorney"
          value={filters.assignedAttorney}
          onChange={handleFilterChange}
          sx={{ minWidth: 200 }}
        >
          <Option value="">All Lead Attorneys</Option>
          <Option value="Aaron D Melamed">Aaron D Melamed</Option>
          <Option value="Benaejah Simmonds">Benaejah Simmonds</Option>
          <Option value="Pierre Louis">Pierre Louis</Option>
          <Option value="Joseph S Wald">Joseph S Wald</Option>
        </Select>
      </Box>

      {/* Cases Table */}
      <Box sx={{ overflowX: "auto", mb: 2 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table sx={{ minWidth: 600, borderRadius: 2, boxShadow: 2 }}>
            <thead>
              <tr>
                <th
                  onClick={() => handleSort("name")}
                  style={{ cursor: "pointer" }}
                >
                  Name {getSortArrow("name")}
                </th>
                <th
                  onClick={() => handleSort("case_number")}
                  style={{ cursor: "pointer" }}
                >
                  Case Number {getSortArrow("case_number")}
                </th>
                <th
                  onClick={() => handleSort("practice_area")}
                  style={{ cursor: "pointer" }}
                >
                  Practice Area {getSortArrow("practice_area")}
                </th>
                <th
                  onClick={() => handleSort("case_stage")}
                  style={{ cursor: "pointer" }}
                >
                  Case Stage {getSortArrow("case_stage")}
                </th>
                <th
                  onClick={() => handleSort("assigned_attorney")}
                  style={{ cursor: "pointer" }}
                >
                  Lead Attorney {getSortArrow("assigned_attorney")}
                </th>
                <th
                  onClick={() => handleSort("opened_date")}
                  style={{ cursor: "pointer" }}
                >
                  Opened Date {getSortArrow("opened_date")}
                </th>
              </tr>
            </thead>
            <tbody>
              {cases.length ? (
                cases.map((item) => (
                  <tr
                    key={item.case_id}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/cases/${item.case_id}`, { state: { cases } })
                    }
                  >
                    <td>{item.name}</td>
                    <td>{item.case_number}</td>
                    <td>{item.practice_area}</td>
                    <td>{item.case_stage}</td>
                    <td>{item.assigned_attorney}</td>
                    <td>{item.opened_date}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="6"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    No cases found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </Box>

      {/* Pagination */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Button
          variant="soft"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => prev - 1)}
        >
          Previous
        </Button>
        <Typography>
          Page {currentPage} of {totalPages}
        </Typography>
        <Button
          variant="soft"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
        </Button>
      </Box>

      {/* Add Case Modal */}
      <AddCaseModal
        open={openCaseModal}
        parentType="case"
        onClose={() => setOpenCaseModal(false)}
        onCaseAdded={fetchCases}
      />
    </Box>
  );
};

export default Cases;