import React, { useState } from "react";
import Sidebar from "./Sidebar";
import { Box, Container } from "@mui/joy";
import SettingsSidebar from "./SettingSidebar";

const Layout = ({ children }) => {
  const [showSettingsSidebar, setShowSettingsSidebar] = useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      {/* Conditionally render Sidebar or SettingsSidebar */}
      {showSettingsSidebar ? (
        <SettingsSidebar setShowSettingsSidebar={setShowSettingsSidebar} />
      ) : (
        <Sidebar setShowSettingsSidebar={setShowSettingsSidebar} />
      )}

      {/* Main content */}
      <Container
      maxWidth="xl"
        sx={{
          flexGrow: 1,
          ml: { md: "0px",xs: "0px", md: "280px" },
          p: 3,
        }}
      >
        {children}
      </Container>
    </Box>
  );
};

export default Layout;