// src/components/StaffManagement.js
import React, { useState, useEffect } from "react";
import {
  Table,
  Sheet,
  Typography,
  Button,
  Input,
  Select,
  Option,
  Stack,
  Modal,
  ModalDialog,
  ModalClose,
  Box
} from "@mui/joy";
import { auth } from "../firebase/firebase";
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

// Get the backend URL from environment variables (ensure REACT_APP_BASE_URL is set in your .env file)
const backendUrl = process.env.REACT_APP_BASE_URL;

const StaffManagement = () => {
  const [staff, setStaff] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newUser, setNewUser] = useState({
    email: "",
    first_name: "",
    last_name: "",
    type: "Editor",
    title: "",
    // Note: We remove the password field from UI; it will be generated automatically
  });
  const [message, setMessage] = useState("");

  // Fetch active staff from the backend endpoint /active_users
  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await fetch(`${backendUrl}/active_users`);
        if (!response.ok) {
          throw new Error("Error fetching active staff");
        }
        const data = await response.json();
        // Filter for active staff entries (active = "Yes" or true)
        const activeStaff = data.filter((entry) => {
          if (typeof entry.active === "boolean") {
            return entry.active;
          } else if (typeof entry.active === "string") {
            return entry.active.toLowerCase() === "yes";
          }
          return false;
        });
        setStaff(activeStaff);
      } catch (error) {
        console.error("Error fetching staff from backend:", error);
      }
    };
    fetchStaff();
  }, []);

  // Helper function: Generate an 8-character random temporary password
  const generateRandomPassword = () => {
    return Math.random().toString(36).slice(-8);
  };

  // Create a new user in Firebase Auth and then add a record in the active_users table
  const handleCreateUser = async () => {
    try {
      // Generate a random temporary password
      const tempPassword = generateRandomPassword();

      // Create the user in Firebase Auth with the temporary password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newUser.email,
        tempPassword
      );
      const uid = userCredential.user.uid;

      // Send a password reset email so that the user can set their own password
      await sendPasswordResetEmail(auth, newUser.email);

      // Prepare payload for the backend (include uid so you can later match records)
      const payload = {
        uid: uid,
        email: newUser.email,
        first_name: newUser.first_name,
        middle_initial: "", // Add if applicable
        last_name: newUser.last_name,
        address_city: "",
        address_country: "",
        address_state: "",
        address_address1: "",
        address_address2: "",
        address_zip_code: "",
        cell_phone_number: "",
        work_phone_number: "",
        home_phone_number: "",
        type: newUser.type,
        title: newUser.title,
        active: "Yes", // Automatically mark new entries as active
        default_hourly_rate: 0, // Default value; adjust as needed
      };

      // Call the backend POST /active_users endpoint to store the record in your SQL table
      const response = await fetch(`${backendUrl}/active_users`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      const result = await response.json();
      setMessage("User created successfully! A password reset email has been sent.");
      // Append the new staff entry to the local state
      setStaff([...staff, result]);
      // Reset the form fields
      setNewUser({
        email: "",
        first_name: "",
        last_name: "",
        type: "Editor",
        title: "",
      });
      setOpenModal(false);
    } catch (error) {
      setMessage(`Error creating user: ${error.message}`);
      console.error("Error creating user:", error);
    }
  };

  // Delete a staff entry using the backend endpoint
  const handleDeleteStaff = async (staffId) => {
    if (!window.confirm("Are you sure you want to delete this staff entry?")) return;
    try {
      const response = await fetch(`${backendUrl}/active_users/${staffId}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
      setMessage("Staff entry deleted successfully!");
      setStaff(staff.filter((entry) => entry.staff_id !== staffId));
    } catch (error) {
      setMessage(`Error deleting staff: ${error.message}`);
      console.error("Error deleting staff:", error);
    }
  };

  return (
    <Sheet sx={{ p: 3, borderRadius: "md", boxShadow: "sm" }}>
      <Typography level="h4">Staff Management</Typography>
      {message && <Typography level="body2">{message}</Typography>}

      <Box sx={{ overflowX: "auto" }}>
  <Table borderAxis="both" sx={{ mt: 2, minWidth: 800, tableLayout: "fixed", width: "100%" }}>
    <thead>
      <tr>
        <th style={{ textAlign: "left", width: "20%" }}>Email</th>
        <th style={{ textAlign: "left", width: "15%" }}>First Name</th>
        <th style={{ textAlign: "left", width: "15%" }}>Last Name</th>
        <th style={{ textAlign: "left", width: "10%" }}>Type</th>
        <th style={{ textAlign: "left", width: "15%" }}>Title</th>
        <th style={{ textAlign: "left", width: "10%" }}>Active</th>
        <th style={{ textAlign: "left", width: "15%" }}>Actions</th>
      </tr>
    </thead>
    <tbody>
      {staff.length > 0 ? (
        staff.map((entry) => (
          <tr key={entry.staff_id}>
            <td style={{ textAlign: "left", wordBreak: "break-word", whiteSpace: "normal" }}>
              {entry.email}
            </td>
            <td style={{ textAlign: "left" }}>{entry.first_name}</td>
            <td style={{ textAlign: "left" }}>{entry.last_name}</td>
            <td style={{ textAlign: "left" }}>{entry.type}</td>
            <td style={{ textAlign: "left" }}>{entry.title}</td>
            <td style={{ textAlign: "left" }}>{entry.active}</td>
            <td style={{ textAlign: "left" }}>
              <Button
                color="danger"
                size="sm"
                sx={{ minWidth: "75px" }}
                onClick={() => handleDeleteStaff(entry.staff_id)}
              >
                Delete
              </Button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={7} style={{ textAlign: "center" }}>
            <Typography level="body2">No active staff entries found.</Typography>
          </td>
        </tr>
      )}
    </tbody>
  </Table>
</Box>

      <Button onClick={() => setOpenModal(true)} sx={{ mt: 2 }}>
        Add New Staff
      </Button>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalDialog>
          <ModalClose />
          <Typography level="h5">Create New Staff Entry</Typography>
          <Stack spacing={2}>
            <Input
              placeholder="Email"
              value={newUser.email}
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            />
            <Input
              placeholder="First Name"
              value={newUser.first_name}
              onChange={(e) =>
                setNewUser({ ...newUser, first_name: e.target.value })
              }
            />
            <Input
              placeholder="Last Name"
              value={newUser.last_name}
              onChange={(e) =>
                setNewUser({ ...newUser, last_name: e.target.value })
              }
            />
            <Select
              value={newUser.type}
              onChange={(e, newValue) =>
                setNewUser({ ...newUser, type: newValue })
              }
            >
              <Option value="Admin">Admin</Option>
              <Option value="Editor">Editor</Option>
              <Option value="Viewer">Viewer</Option>
            </Select>
            <Input
              placeholder="Title"
              value={newUser.title}
              onChange={(e) =>
                setNewUser({ ...newUser, title: e.target.value })
              }
            />
            <Button onClick={handleCreateUser}>Create Staff</Button>
          </Stack>
        </ModalDialog>
      </Modal>
    </Sheet>
  );
};

export default StaffManagement;