import React from "react";
import { CssBaseline, Box, Grid } from "@mui/joy";
import AddItemComponent from "./AddItemComponent";
import MyTask from "./MyTask";
import TodayEvents from "./TodayEvents";
import Timesheet from "./MyTimeSheet";
import RecentActivityAll from "./RecentActivity/RecentActivityAll";

const HomeTab = () => {
  return (
    <Box sx={{ p: 3 }}>
      <AddItemComponent />
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid xs={12} md={8} sx={{ mt: 2 }}>
          <Timesheet />
          <RecentActivityAll/>
        </Grid>
        <Grid xs={12} md={4}>
          <MyTask />
          <Box sx={{ mt: 2 }}>
            <TodayEvents />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeTab;