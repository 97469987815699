import React, { useState, useEffect } from "react";
import { Box, Button, Input, Table, Sheet, Typography, Chip, IconButton, CircularProgress } from "@mui/joy";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import AddTimeEntryModal from "../../components/AddTimeEntryModal";
import { Link } from "react-router-dom";

const TimeEntries = () => {
  const [timeEntries, setTimeEntries] = useState([]);
  const [filters, setFilters] = useState({
    case_id: "",
    description: "",
    start_date: "",
    end_date: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [modalOpen, setModalOpen] = useState(false); 
  const [editData, setEditData] = useState(null); 
  const [singleCase, setSingleCase]= useState(null);
const [cases, setCases]=useState([])
const [loading, setLoading] = useState(false); 

   const fetchCases = async (case_id) => {
     try {
      setLoading(true);
         const response = await axios.get(`/casesbillexpense/${case_id}`);
         setCases(response.data.cases)
         setSingleCase(response.data);
     } catch (error) {
         console.error("Error fetching cases:", error);
         setSingleCase([]);
     }
     finally {
      setLoading(false);
    }
 };
  const handleAddClick=()=>{
    setEditData(null); 
    setModalOpen(true); 
    
  
            
            fetchCases();
  }
    const handleEditClick = (entry) => {
        fetchCases(entry.case_id)
      setEditData(entry); // Set selected entry data
      setModalOpen(true); // Open the modal
    };
    const handleDelete = async (entry) => {
      setLoading(true);

      const confirmDelete = window.confirm("Are you sure you want to delete this entry?");
      if (confirmDelete) {
          try {
              // Send DELETE request to the API
              await axios.delete(`/time_entries/${entry.time_entry_id}`);
              console.log("Entry deleted successfully");
              fetchTimeEntries()
              // Optionally, refresh the data or handle state change after deletion
          } catch (error) {
              console.error("Error deleting entry:", error);
          } finally {
            setLoading(false);
          }
      }
  };
  useEffect(() => {
    fetchTimeEntries();
  }, [currentPage, filters]); // Add filters to the dependency array

  const fetchTimeEntries = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/time_entries", {
        params: { ...filters, page: currentPage },
      });
      setTimeEntries(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error("Error fetching time entries:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleFilterSubmit = () => {
    setCurrentPage(1);
    fetchTimeEntries();
  };

  const handleResetFilters = () => {
    setFilters({
      case_id: "",
      description: "",
      start_date: "",
      end_date: "",
    });
    setCurrentPage(1);
    // No need to call fetchTimeEntries here because useEffect will trigger it
  };

  return (
    <Sheet sx={{ overflowX: "auto", background: "transparent" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom:"2rem"}}>
      <Typography level="h4" gutterBottom>
        Time Entries
      </Typography>
      <div>
      {/* <Button variant="soft" disabled>
          Bulk Delete
        </Button> */}
 <Button variant="solid" onClick={handleAddClick} disabled={loading}>
          {loading ? <CircularProgress size="sm" /> : "Add Time Entry"}
        </Button>      </div>
    
        </div>
      <Box
      
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        alignItems: "center",
        mb: 2,
        justifyContent: "end",
      }}
    >
      {/* <Input
        name="case_id"
        placeholder="Filter time entries by case..."
        value={filters.case_id}
        sx={{ flex: 1, minWidth: "200px" }}
        onChange={handleFilterChange}
      />
      <Input
        name="description"
        placeholder="Description or activity"
        value={filters.description}
        sx={{ flex: 1, minWidth: "200px" }}
        onChange={handleFilterChange}
      /> */}
      <Input
        type="date"
        name="start_date"
        value={filters.start_date}
        sx={{ minWidth: "150px" }}
        onChange={handleFilterChange}
      />
      <Input
        type="date"
        name="end_date"
        value={filters.end_date}
        sx={{ minWidth: "150px" }}
        onChange={handleFilterChange}
      />
      <Button
  variant="contained"
  color="primary"
  onClick={handleFilterSubmit}
  sx={{ borderRadius: "20px", backgroundColor: "#27bfad" }}
>
{loading ? <CircularProgress size="sm" /> : "Filter"}

</Button>

      <Button variant="outlined" color="secondary" onClick={handleResetFilters} sx={{ borderRadius: "20px" }}>
        Reset Dates
      </Button>
      <Button variant="text">My Entries</Button>
      <Button variant="contained" sx={{ backgroundColor: "#d0e4ff" }}>All Entries</Button>
    </Box>
    {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
          <CircularProgress size="lg" />
        </Box>
      ) : (
      <Box sx={{ overflowX: "auto" }}>
      <Table sx={{ minWidth: 600, borderRadius: 2, boxShadow: 2, overflowX: "auto", display: "block"  }}>
      <thead>
            <tr>
              <th>Date</th>
              <th>Activity</th>
              <th>Duration</th>
              <th>Description</th>
              <th>Rate</th>
              <th>Total</th>
              <th>Status</th>
              <th>User</th>
              <th>Case</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {timeEntries.map((entry, index) => (
              <tr key={index}>
                <td>{new Date(entry.entry_date).toLocaleDateString()}</td>
                <td>{entry.activity_name}</td>
                <td>{entry.hours}</td>
                <td>{entry.description}</td>
                <td>{entry.rate}</td>
                <td>{(entry.rate * entry.hours).toFixed(2)}</td>
                <td>
                  <Chip color="primary" variant="soft">
                    {entry.billable ? "Billable" : "Non-Billable"}
                  </Chip>
                </td>
                <td>
  <Link to={`/cases/${entry?.case_id || entry?.case_id}`} style={{ textDecoration: 'none', color: 'blue', cursor: 'pointer' }}>
    {entry?.active_user_staff_name || entry?.staff_table_staff_name}
  </Link>
</td>

                <td>{entry.case_name}</td>
                <td>
                  <IconButton size="sm"  onClick={() => handleEditClick(entry)}>
                    <Edit />
                  </IconButton>
                  <IconButton size="sm" color="danger" onClick={() => handleDelete(entry)}>
                    <Delete />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2, mt: 2 }}>
        <Button variant="soft" disabled={currentPage === 1} onClick={() => setCurrentPage((prev) => prev - 1)}>
          Previous
        </Button>
        <Typography>
          Page {currentPage} of {totalPages}
        </Typography>
        <Button variant="soft" disabled={currentPage === totalPages} onClick={() => setCurrentPage((prev) => prev + 1)}>
          Next
        </Button>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2, flexWrap: "wrap", gap: 2 }}>
        
      </Box>
     <AddTimeEntryModal 
             open={modalOpen} 
             onClose={() => setModalOpen(false)} 
             caseId={editData?.case_id} 
            //  parentType="timeExpense" 
             editData={editData} 
             singleCase={editData?.case_id}
             cases={cases}
            //  onSuccess={fetchTimeEntries} 
           />
    </Sheet>
  );
};

export default TimeEntries;