import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./components/Login";
import Home from "./pages/Home";
import Cases from "./pages/Cases";
import CaseDetails from "./pages/CaseDetails";
import Contacts from "./pages/Contacts";
import ContactDetails from "./pages/ContactDetails";
import CalendarPage from "./pages/CalendarPage";
import Settings from "./pages/Settings"; // Import Settings Page
import { ThemeProvider } from "./context/ThemeContext"; // Import Theme Context

import "./App.css";
import TasksDashboard from "./pages/Task";
import CaseStagesComponent from "./pages/Setting/CaseStage";
import CustomFields from "./pages/Setting/CustomField";
import TimeEntries from "./pages/Case/TimeAndBilling/TimeEntries";
import BillingTab from "./pages/Billing/BillingTab";
import CasesTab from "./pages/CasesTab";
import AllReport from "./pages/Report/AllReport";
import CaseListReport from "./pages/Report/CaseContactReport/CaseListReport";
import EventTypes from "./pages/Calender/EventTypes";
// Import DocumentsList page
import DocumentsList from "./pages/DocumentsList";
import HomeTab from "./pages/Dashboard/Home";
import ProfileEdit from "./pages/Profile/ProfileEdit";

function App() {
  return (
    <ThemeProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <Layout>
                  <Routes>
                  <Route index element={<HomeTab/>} />
                    <Route index element={<Cases />} />
                    <Route path="cases" element={<CasesTab />} />
                    <Route path="task" element={<TasksDashboard />} />
                    <Route path="cases/:id" element={<CaseDetails />} />
                    <Route path="custom-field" element={<CustomFields/>} />
                    <Route path="reports" element={<AllReport/>} />
                    <Route path="/case-list" element={<CaseListReport/>} />
                    <Route path="/court_case/:caseId" element={<TimeEntries/>} />
                    <Route path="/billing" element={<BillingTab/>} />
                    <Route path="/event-types" element={<EventTypes/>} />
                    <Route path="/profile/edit" element={<ProfileEdit/>} />

                    <Route path="case-stages" element={<CaseStagesComponent/>} />
                    <Route path="custom-field" element={<CustomFields />} />
                    <Route path="/court_case/:caseId" element={<TimeEntries />} />
                    <Route path="/billing" element={<BillingTab />} />
                    <Route path="case-stages" element={<CaseStagesComponent />} />
                    <Route path="contacts" element={<Contacts />} />
                    <Route path="contacts/:id" element={<ContactDetails />} />
                    <Route path="calendar" element={<CalendarPage />} />
                    <Route path="firm-users" element={<Settings />} />
                    {/* New route for Documents */}
                    <Route path="documents" element={<DocumentsList />} />
                  </Routes>
                </Layout>
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;