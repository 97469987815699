import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Input,
  FormControl,
  FormLabel,
} from "@mui/joy";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "background.level1",
      }}
    >
      <Box
        component="form"
        onSubmit={handleLogin}
        sx={{
          width: 320,
          p: 4,
          borderRadius: 3,
          boxShadow: "md",
          background: "linear-gradient(135deg, #ffffff, #f0f4f8)",
        }}
      >
        <Typography level="h4" component="h1" sx={{ mb: 3, textAlign: "center" }}>
          Sign In
        </Typography>
        {error && (
          <Typography level="body2" color="danger" sx={{ mb: 2, textAlign: "center" }}>
            {error}
          </Typography>
        )}
        <FormControl sx={{ mb: 3 }}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormControl>
        <FormControl sx={{ mb: 3 }}>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </FormControl>
        <Button type="submit" variant="solid" fullWidth>
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default Login;