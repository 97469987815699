import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Modal,
  ModalDialog,
  Typography,
  Button,
  Input,
  Stack,
  Select,
  Option,
  IconButton,
  Alert,
} from '@mui/joy';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const AddEditCustomFieldModal = ({
  open,
  handleClose,
  parentType,
  mode, // "add" or "edit"
  initialData, // Provided when editing
  onSuccess, // Callback after successful add/edit
}) => {
  const [fieldName, setFieldName] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [listOptions, setListOptions] = useState([]);
  const [showListOptionInput, setShowListOptionInput] = useState(false);
  const [currentListOption, setCurrentListOption] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingValue, setEditingValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (open) {
      if (mode === 'edit' && initialData) {
        setFieldName(initialData.custom_fields_name || '');
        setFieldType(initialData.field_type === 'list' ? 'list' : initialData.field_type || '');

        setListOptions(
          initialData.list_options
            ? initialData.list_options.map(opt => ({
                id: opt.list_options_id,
                value: opt.option_value,
              }))
            : []
        );
      } else {
        // Reset states when switching to "add" mode
        setFieldName('');
        setFieldType('');
        setListOptions([]);
      }
      
      // Ensure these are reset whenever the modal is opened
      setShowListOptionInput(false);
      setCurrentListOption('');
      setEditingIndex(null);
      setEditingValue('');
      setErrorMessage('');
    }
  }, [open, mode, initialData]);

  const handleFieldTypeChange = (e, newValue) => {
    setFieldType(newValue);
    if (newValue !== 'list') {
      setListOptions([]);
      setShowListOptionInput(false);
      setEditingIndex(null);
      setEditingValue('');
    }
  };

  const handleAddListOption = () => {
    setShowListOptionInput(true);
  };

  const handleSubmitListOption = () => {
    if (currentListOption.trim() !== '') {
      setListOptions([...listOptions, { id: null, value: currentListOption.trim() }]);
      setCurrentListOption('');
    }
    setShowListOptionInput(false);
  };

  const handleCancelListOption = () => {
    setCurrentListOption('');
    setShowListOptionInput(false);
  };

  const handleEditOption = (index) => {
    setEditingIndex(index);
    setEditingValue(listOptions[index].value);
  };

  const handleSaveEditOption = () => {
    if (editingValue.trim() !== '') {
      const updatedOptions = [...listOptions];
      updatedOptions[editingIndex] = { ...updatedOptions[editingIndex], value: editingValue.trim() };
      setListOptions(updatedOptions);
    }
    setEditingIndex(null);
    setEditingValue('');
  };

  const handleCancelEditOption = () => {
    setEditingIndex(null);
    setEditingValue('');
  };

  const handleDeleteOption = (index) => {
    const updatedOptions = listOptions.filter((_, i) => i !== index);
    setListOptions(updatedOptions);
  };

  const handleSave = () => {
    const customFieldPayload = {
      custom_fields_name: fieldName,
      field_type: fieldType === 'list' ? 'list' : fieldType,
      parent_type: parentType,
      list_options: fieldType === 'list' 
        ? listOptions.map(opt => ({ option_value: opt.value, list_options_id:opt.id }))
        : [],
    };

    if (mode === 'add') {
      axios
        .post('/custom_fields', customFieldPayload)
        .then((response) => {
          console.log('Custom field added:', response.data);
          onSuccess(response.data);
          handleClose();
        })
        .catch((error) => {
          console.error('Error adding custom field:', error);
          setErrorMessage('Failed to add custom field. Please try again.');
        });
    } else if (mode === 'edit' && initialData) {
      axios
        .put(`/custom_fields/${initialData.custom_fields_id}/full_update`, customFieldPayload)
        .then((response) => {
          console.log('Custom field updated:', response.data);
          onSuccess(response.data);
          handleClose();
        })
        .catch((error) => {
          console.error('Error updating custom field:', error);
          setErrorMessage('Failed to update custom field. Please try again.');
        });
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog
        aria-labelledby="add-custom-field-title"
        aria-describedby="add-custom-field-description"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '60%', lg: '40%' }, 
          maxWidth: 500,
          minWidth: 300, 
          overflowY: 'auto',
        }}
      >
        <Typography id="add-custom-field-title" component="h2" level="h5" mb={2}>
          {mode === 'edit' ? 'Edit Custom Field' : 'Add Custom Field'}
        </Typography>
        {errorMessage && (
          <Alert color="danger" sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
        )}
        <Stack spacing={2}>
          <Input
            placeholder="Field Name"
            fullWidth
            value={fieldName}
            onChange={(e) => setFieldName(e.target.value)}
          />
          <Select
            placeholder="Select Field Type"
            value={fieldType}
            onChange={(e, newValue) => handleFieldTypeChange(e, newValue)}
            fullWidth
            disabled={mode === 'edit'} 
          >
            <Option value="short_text">Short Text</Option>
            <Option value="long_text">Long Text</Option>
            <Option value="Yes/no">Yes/no</Option>
            <Option value="Number">Number</Option>
            <Option value="currency">Currency</Option>
            <Option value="list">Single Select</Option>
            <Option value="date">Date</Option>
          </Select>
          {fieldType === 'list' && (
            <Stack spacing={2}>
              <Typography level="body2">List Options:</Typography>
              {listOptions.map((option, index) => (
                <Stack key={index} direction="row" spacing={1} alignItems="center">
                  {editingIndex === index ? (
                    <>
                      <Input
                        placeholder="Edit option"
                        value={editingValue}
                        onChange={(e) => setEditingValue(e.target.value)}
                      />
                      <IconButton onClick={handleSaveEditOption}>
                        <CheckIcon />
                      </IconButton>
                      <IconButton onClick={handleCancelEditOption}>
                        <CloseIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <Typography level="body2">{option.value}</Typography>
                      <IconButton size="sm" onClick={() => handleEditOption(index)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton size="sm" onClick={() => handleDeleteOption(index)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </>
                  )}
                </Stack>
              ))}
              {showListOptionInput ? (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Input
                    placeholder="Enter list option"
                    value={currentListOption}
                    onChange={(e) => setCurrentListOption(e.target.value)}
                  />
                  <IconButton onClick={handleSubmitListOption}>
                    <CheckIcon />
                  </IconButton>
                  <IconButton onClick={handleCancelListOption}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
              ) : (
                <Button variant="outlined" onClick={handleAddListOption}>
                  Add List Option
                </Button>
              )}
            </Stack>
          )}
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-end" mt={2}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="solid">
            {mode === 'edit' ? 'Update' : 'Save'}
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};

export default AddEditCustomFieldModal;