import React, { useState, useEffect } from 'react';
import { Typography, List, ListItem, ListItemDecorator, Tabs, TabList, Tab, Card } from '@mui/joy';
import axios from 'axios';
import { CalendarDaysIcon, PencilSquareIcon, PaperClipIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

const tabs = [
  { label: 'All', value: 'all' },
  { label: 'Events', value: 'events' },
  { label: 'Documents', value: 'documents' },
  { label: 'Tasks', value: 'tasks' },

];

const RecentActivityAll = () => {
  const [selectedTab, setSelectedTab] = useState('all');
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(false); // Added loading state
  const [visibleCount, setVisibleCount] = useState(5);

  const fetchActivity = async () => {
    setLoading(true); // Set loading to true before fetching data

    try {
        let allActivities = [];

        if (selectedTab === 'events' || selectedTab === 'all') {
            const eventRes = await axios.get('/events/logs');
            const eventActivities = eventRes.data.map(log => {
                const fullName = `${log.first_name} ${log.last_name}`;
                const timeObj = new Date(log.timestamp);
                const time = timeObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                const date = timeObj.toLocaleDateString(undefined, { day: '2-digit', month: 'short' });

                let actionDescription = '';

                const eventLink = (
                  <Link
                    to={`/cases/${log.case_id}`}
                    style={{ color: '#1976d2', textDecoration: 'none' }}
                  >
                    {log.event_name}
                  </Link>
                );
                
                if (log.action === 'create') {
                  actionDescription = <>{fullName} created {eventLink} on {date} at {time}</>;
                } else if (log.action === 'update') {
                  actionDescription = (
                    <>
                      {fullName} updated "{log.field_name}" from "{log.old_value}" to "{log.new_value}" on {date} at {time} of {eventLink}
                    </>
                  );
                }
                

                return {
                    type: 'events',
                    user: fullName,
                    role: 'Event Log',
                    action: actionDescription,
                    subject: '',
                    time: '',
                    icon: log.action === 'create' ? <CalendarDaysIcon  style={{width:"1.5rem"}} /> : <PencilSquareIcon  style={{width:"1.5rem"}} />,
                    timestamp: log.timestamp, // 👈 This line added

                  };
            });

            allActivities = [...allActivities, ...eventActivities];
        }

        if (selectedTab === 'documents' || selectedTab === 'all') {
            const docRes = await axios.get('/documents/activity');
            const documentActivities = docRes.data.activities.map(log => {
                const formattedTime = new Date(log.timestamp).toLocaleString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true,
                });
                const docLink = <Link to={`/cases/${log.case_id}`} style={{ color: '#1976d2', textDecoration: 'none' }}>{log.document_name}</Link>;

                return {
                    type: 'documents',
                    user: log.first_name && log.last_name ? `${log.first_name} ${log.last_name}` : 'System',
                    role: 'Document Log',
                    action: <>{log.first_name} {log.last_name} {log.action} the document {docLink} on {formattedTime}</>,
                    subject: log.document_name,
                    time: formattedTime,
                    icon: log.action === 'upload' ? <PaperClipIcon style={{width:"1.5rem"}} /> : <PencilSquareIcon style={{width:"1.5rem"}}  />,
                    timestamp: log.timestamp, // Add this line

                  };
            });

            allActivities = [...allActivities, ...documentActivities];
        }
        if (selectedTab === 'tasks' || selectedTab === 'all') {
          const taskRes = await axios.get('/tasks/activity');
          const taskActivities = taskRes.data.activities.map(log => {
              const fullName = `${log.first_name} ${log.last_name}`;
              const timeObj = new Date(log.timestamp);
              const time = timeObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
              const date = timeObj.toLocaleDateString(undefined, { day: '2-digit', month: 'short' });
      
              let actionDescription = '';
      
              const taskLink = <Link to={`/cases/${log.case_id}`} style={{ color: '#1976d2', textDecoration: 'none' }}>{log.task_name}</Link>;

              if (log.action === 'create') {
                  actionDescription = <>{fullName} created task {taskLink} on {date} at {time}</>;
              } else if (log.action === 'update' && log.field_name) {
                  actionDescription = <>{fullName} updated "{log.field_name}" from "{log.old_value}" to "{log.new_value}" on {date} at {time} of task {taskLink}</>;
              }
              
            
      
              return {
                  type: 'tasks',
                  user: fullName,
                  role: 'Task Log',
                  action: actionDescription,
                  subject: log.task_name,
                  time: `${date} ${time}`,
                  icon: log.action === 'create' ? <CalendarDaysIcon style={{ width: "1.5rem" }} /> : <PencilSquareIcon style={{ width: "1.5rem" }} />,
                  timestamp: log.timestamp, // Add this line

              };
          });
      
          allActivities = [...allActivities, ...taskActivities];
      }
      
      if (selectedTab === 'all') {
        allActivities.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      }
      setActivityData(allActivities);
          } catch (err) {
        console.error("Failed to load activity data", err);
        setActivityData([]);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
};

  useEffect(() => {
    fetchActivity();
  }, [selectedTab]);

  return (
    <Card variant="outlined" sx={{ width: '100%', padding: 2 , marginTop:2}}>
      <Typography level="h4" sx={{ mb: 2 }}>Recent Activity</Typography>
      <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value}>
              {tab.label}
            </Tab>
          ))}
        </TabList>
      </Tabs>

      <List sx={{ mt: 2 }}>
  {loading ? (
    <Typography level="body-sm" sx={{ mt: 2, color: '#888' }}>
      Loading...
    </Typography>
  ) : activityData.length > 0 ? (
    <>
      {activityData.slice(0, visibleCount).map((activity, index) => (
        <ListItem
          key={index}
          sx={{ flexDirection: 'row', alignItems: 'flex-start', borderTop: '1px solid #eee', paddingY: 1 }}
        >
          <ListItemDecorator>{activity.icon}</ListItemDecorator>
          <Typography level="body-sm">{activity.action}</Typography>
        </ListItem>
      ))}

      {/* Show More Button */}
      {visibleCount < activityData.length && (
        <Typography
          level="body-sm"
          sx={{ mt: 2, color: '#1976d2', cursor: 'pointer', textAlign: 'center' }}
          onClick={() => setVisibleCount(prev => prev + 5)}
        >
          Show More
        </Typography>
      )}
    </>
  ) : (
    <Typography level="body-sm" sx={{ mt: 2, color: '#888' }}>
      No activity for this tab.
    </Typography>
  )}
</List>
    </Card>
  );
};

export default RecentActivityAll;