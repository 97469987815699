// DocumentsList.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Table,
  Input,
  Tooltip,
  Modal,
  ModalDialog,
  ModalClose
} from "@mui/joy";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AddIcon from "@mui/icons-material/Add";
import AddDocumentModal from "../components/AddDocumentModal";

const API_BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3001";

const DocumentDetailsModal = ({ doc, open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog sx={{ maxWidth: 500, borderRadius: "md", p: 2 }}>
        <ModalClose onClick={onClose} />
        <Typography level="h5" mb={2}>
          Document Details
        </Typography>
        <Typography>
          <strong>File Name:</strong> {doc.fileName}
        </Typography>
        <Typography>
          <strong>Case:</strong> {doc.caseId || doc.case_id}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 2 }}>
          <Button
            onClick={() =>
              window.open(
                `${API_BASE_URL}/cases/${doc.caseId || doc.case_id}/documents/${doc.fileName}`,
                "_blank"
              )
            }
          >
            Download
          </Button>
          <Button
            onClick={() =>
              window.open(
                `${API_BASE_URL}/cases/${doc.caseId || doc.case_id}/documents/${doc.fileName}`,
                "_blank"
              )
            }
          >
            View
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

const DocumentsList = () => {
  const [documents, setDocuments] = useState([]);
  const [casesMapping, setCasesMapping] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // Controls AddDocumentModal
  const navigate = useNavigate();
  const limit = 20;

  // Function to fetch documents.
  // If searchTerm is provided, we first query /cases to find matching case(s)
  // and then use the first match's case_id to filter documents.
  const fetchDocuments = async () => {
    setLoading(true);
    try {
      let params = { page, limit };

      if (searchTerm.trim() !== "") {
        // Search for a case by name using /cases endpoint.
        const casesRes = await axios.get(`${API_BASE_URL}/cases`, {
          params: { search: searchTerm }
        });
        if (casesRes.data.cases && casesRes.data.cases.length > 0) {
          const matchedCase = casesRes.data.cases[0];
          // Use the matched case's ID and also store its name in the mapping
          params.case_id = matchedCase.case_id;
          setCasesMapping({ [String(matchedCase.case_id)]: matchedCase.name });
        } else {
          setDocuments([]);
          setTotalDocs(0);
          setLoading(false);
          return;
        }
      }

      const res = await axios.get(`${API_BASE_URL}/documents`, { params });
      setDocuments(res.data.documents);
      setTotalDocs(res.data.totalDocuments);

      // If no search term, fetch mapping for all unique case IDs from documents.
      if (searchTerm.trim() === "") {
        const uniqueIds = Array.from(
          new Set(res.data.documents.map(doc => String(doc.caseId || doc.case_id)))
        );
        if (uniqueIds.length > 0) {
          const mappingRes = await axios.get(`${API_BASE_URL}/cases/mapping`, {
            params: { ids: uniqueIds.join(",") }
          });
          setCasesMapping(mappingRes.data);
        }
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [page, searchTerm]);

  const totalPages = Math.ceil(totalDocs / limit);

  // Style for truncating text with ellipsis
  const truncateStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    display: "inline-block",
  };

  // Open the Add Document modal
  const handleAddDocument = () => {
    setModalOpen(true);
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Header: search bar on the left, "Add Document" button on the right */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Input
          placeholder="Search by case name"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setPage(1); // Reset to page 1 on new search
          }}
          sx={{ minWidth: 300 }}
        />
        <Button
          startDecorator={<AddIcon />}
          variant="solid"
          size="sm"
          onClick={handleAddDocument}
        >
          Add Document
        </Button>
      </Box>

      <Typography level="h4" sx={{ mb: 2 }}>Documents</Typography>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Table sx={{ minWidth: 900, borderRadius: 2, boxShadow: 2 }}>
            <thead>
              <tr>
                <th style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "left" }}>
                  Document
                </th>
                <th style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "left" }}>
                  Case
                </th>
                <th style={{ padding: "8px", borderBottom: "1px solid #ddd", textAlign: "center" }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {documents.length > 0 ? (
                documents.map((doc, index) => {
                  const caseKey = String(doc.caseId || doc.case_id);
                  const displayCaseName = casesMapping[caseKey] || doc.caseId || "Unknown Case";
                  return (
                    <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                      {/* Document Column */}
                      <td
                        style={{ padding: "8px", display: "flex", alignItems: "center", cursor: "pointer" }}
                        onClick={() => setSelectedDocument(doc)}
                      >
                        <InsertDriveFileIcon sx={{ mr: 1 }} />
                        <Tooltip title={doc.fileName}>
                          <span style={truncateStyle}>{doc.fileName}</span>
                        </Tooltip>
                      </td>
                      {/* Case Column */}
                      <td style={{ padding: "8px" }}>
                        <Button variant="plain" onClick={() => navigate(`/cases/${doc.caseId || doc.case_id}`)}>
                          <Tooltip title={displayCaseName}>
                            <span style={truncateStyle}>{displayCaseName}</span>
                          </Tooltip>
                        </Button>
                      </td>
                      {/* Actions Column */}
                      <td style={{ padding: "8px", display: "flex", justifyContent: "center", gap: "8px" }}>
                        <Button variant="plain" onClick={() =>
                          window.open(
                            `${API_BASE_URL}/cases/${doc.caseId || doc.case_id}/documents/${doc.fileName}`,
                            "_blank"
                          )
                        }>
                          <DownloadIcon />
                        </Button>
                        <Button variant="plain" onClick={() =>
  window.open(
    `${API_BASE_URL}/cases/${doc.caseId || doc.case_id}/documents/${doc.fileName}/view`,
    "_blank"
  )
}>
  <RemoveRedEyeIcon />
</Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3" style={{ textAlign: "center", padding: "20px" }}>
                    No documents found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 2, gap: 2 }}>
            <Button disabled={page === 1} onClick={() => setPage((prev) => prev - 1)}>
              Previous
            </Button>
            <Typography>
              Page {page} of {totalPages}
            </Typography>
            <Button disabled={page === totalPages} onClick={() => setPage((prev) => prev + 1)}>
              Next
            </Button>
          </Box>
        </>
      )}

      {/* Document Details Modal */}
      {selectedDocument && (
        <DocumentDetailsModal 
          doc={selectedDocument}
          open={Boolean(selectedDocument)}
          onClose={() => setSelectedDocument(null)}
        />
      )}

      {/* Add Document Modal */}
      {modalOpen && (
        <AddDocumentModal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
            fetchDocuments(); // Refresh list after upload
          }}
        />
      )}
    </Box>
  );
};

export default DocumentsList;