import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Select,
  Option,
  Button,
  Input,
  Table,
  Sheet,
  Menu,
  MenuItem,
  CircularProgress,
  Tooltip
} from "@mui/joy";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import axios from "axios";

const UserTimeExpenses = () => {
  const [selectedUser, setSelectedUser] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [users, setUsers] = useState([]);
  const [loadingTime, setLoadingTime] = useState(false); // Loading state

  const [exportMenuOpen, setExportMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userReport, setUserReport] = useState({
    time_entries: [],
    expenses: [],
  });
  const [totalAmount, setTotalAmount] = useState(null);
  const [totalBillableAmount, setTotalBillableAmount] = useState(0);
const [totalNonBillableAmount, setTotalNonBillableAmount] = useState(0);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("/active-users");
      const { activeUsers, staff } = response.data;

      // Merge both arrays
      const combinedUsers = [...activeUsers, ...staff];

      setUsers(combinedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchUserReport = async () => {
    try {
        setLoadingTime(true);
      const response = await axios.get(
        `/user_reports?selected_user=${selectedUser}&start_date=${startDate}&end_date=${endDate}`
      );
      const data = response.data;
setTotalAmount(response.data.total_expenses)
      // Filter time_entries based on selected user and date range
      const filteredTimeEntries = data.time_entries.filter(
        (entry) =>
          (!selectedUser || entry.staff_id === selectedUser) &&
          (!startDate || entry.entry_date >= startDate) &&
          (!endDate || entry.entry_date <= endDate)
      );
     
      const billableExpenses = data.expenses.filter(expense => expense.billable === 1);
      const nonBillableExpenses = data.expenses.filter(expense => expense.billable === 0);
      
      setTotalBillableAmount(
        billableExpenses.reduce(
          (sum, expense) => sum + parseFloat(expense.cost) * parseFloat(expense.units),
          0
        )
      );
      
      setTotalNonBillableAmount(
        nonBillableExpenses.reduce(
          (sum, expense) => sum + parseFloat(expense.cost) * parseFloat(expense.units),
          0
        )
      );
      
      // setTotalAmount(filteredTimeEntries.cost)
      setUserReport({ ...data });
    } catch (error) {
      console.error("Error fetching user report:", error);
    }
    finally {
        setLoadingTime(false);
      }
  };
  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
    setExportMenuOpen(true);
  };

  const handleExportClose = () => {
    setExportMenuOpen(false);
  };

  const exportAsPDF = () => {
    import("jspdf").then((jsPDF) => {
      const doc = new jsPDF.default();
      doc.text("User Time & Expenses Report", 20, 10);
  
      import("jspdf-autotable").then((autoTable) => {
        // Time Entries Table
        const timeEntriesData = userReport.time_entries.map((entry) => [
          entry.entry_date,
          entry.activity_name,
          entry.rate,
          entry.hours,
          `$${entry.total}`,
          entry.billable ? "Yes" : "No",
          entry.case_name,
          entry.case_number || "--",
          entry.status,
        ]);
  
        autoTable.default(doc, {
          head: [["Date", "Activity", "Rate", "Duration", "Total", "Billable", "Case Link", "Case Number", "Status"]],
          body: timeEntriesData,
          startY: 20,
        });
  
        // Move to next section for Expenses
        let nextY = doc.lastAutoTable.finalY + 10;
        doc.text("Expenses", 20, nextY);
  
        const expensesData = userReport.expenses.map((expense) => [
          expense.entry_date,
          expense.activity_name,
          expense.billable ? "Yes" : "No",
          expense.description,
          `$${expense.cost}`,
          expense.units,
          `$${expense.cost * expense.units}`,
        ]);
  
        autoTable.default(doc, {
          head: [["Date", "Activity", "Billable", "Description", "Cost", "Quantity", "Total"]],
          body: expensesData,
          startY: nextY + 10, // Adjust position for the next table
        });
  
        doc.save("user_time_expenses.pdf");
      });
    });
  };
  
  

  const exportToCSV = () => {
    // Time Entries CSV Content
    let csvContent = "Date,Activity,Rate,Duration,Total,Billable,Case Link,Case Number,Status\n"; // Headers for Time Entries
    userReport.time_entries.forEach((entry) => {
      csvContent += `${entry.entry_date},${entry.activity_name},${entry.rate},${entry.hours},$${entry.total},${entry.billable ? "Yes" : "No"},${entry.case_name},${entry.case_number || "--"},${entry.status}\n`;
    });
  
    // Add a separator between Time Entries and Expenses
    csvContent += "\n";
  
    // Expenses CSV Content
    csvContent += "Date,Activity,Billable,Description,Cost,Quantity,Total\n"; // Headers for Expenses
    userReport.expenses.forEach((expense) => {
      csvContent += `${expense.entry_date},${expense.activity_name},${expense.billable ? "Yes" : "No"},${expense.description},$${expense.cost},${expense.units},$${expense.cost * expense.units}\n`;
    });
  
    // Create and download the CSV file
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "User_Report.csv";
    link.click();
  };

  return (
    <Box p={3} mt={1}>
      <Typography level="h3">User Time & Expenses</Typography>
      <Sheet variant="outlined" sx={{ p: 2, my: 2 }}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Input
            type="date"
            startDecorator={<CalendarTodayIcon />}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <Input
            type="date"
            startDecorator={<CalendarTodayIcon />}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <Select
            value={selectedUser}
            onChange={(e, newValue) => setSelectedUser(newValue)}
          >
            {users.map((user) => (
              <Option key={user.staff_id} value={user.staff_id}>
                {user.first_name} {user.last_name}
              </Option>
            ))}
          </Select>
          <Button onClick={fetchUserReport}>Run Report</Button>
          <Button onClick={handleExportClick}>Export</Button>

{/* Export Menu */}
{exportMenuOpen && (
  <Menu anchorEl={anchorEl} open={exportMenuOpen} onClose={handleExportClose}>
    <MenuItem  onClick={() => {
                handleExportClose();
                exportAsPDF();
              }}>Export as PDF</MenuItem>
    <MenuItem onClick={()=>{exportToCSV();
        handleExportClose();
    }}>Export as CSV</MenuItem>
  </Menu>
)}

        </Box>
      </Sheet>

      <Sheet variant="soft" sx={{ p: 2, mb: 2 }}>
        <Typography level="h5">User</Typography>
        <Typography>
          {selectedUser
            ? users.find((u) => u.staff_id === selectedUser)?.first_name +
              " " +
              users.find((u) => u.staff_id === selectedUser)?.last_name
            : "No user selected"}
        </Typography>
        <Table sx={{ mt: 2 }}>
          <thead>
            <tr>
              <th>Billable Flat Fees</th>
              <th>Billable Hours</th>
              <th>Total Billable Amount</th>
              <th>Billable Expenses</th>
              <th>Non-Billable Expenses</th>
              <th>Non-Billable Hours</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${userReport.billable_flat_fees || "0.00"}</td>
              <td>{userReport.billable_hours || "0.0"} hours</td>
              <td>${totalAmount || "0.0"}</td>
              <td>${totalBillableAmount.toFixed(2) || "0.0"}</td>

              <td>${totalNonBillableAmount.toFixed(2) || "0.0"}</td>
              <td>{userReport.non_billable_hours || "0.0"} hours</td>
            </tr>
          </tbody>
        </Table>
      </Sheet>

      {/* <Typography level="h5">Flat Fees</Typography> */}
      {/* <Typography>
        {userReport.flat_fees?.length > 0
          ? "Flat fees available"
          : "This user has no flat fees for the selected period."}
      </Typography> */}

      <Typography level="h5" sx={{ mt: 2 }}>
        Time Entries
      </Typography>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Activity</th>
            <th>Rate</th>
            <th>Duration</th>
            <th>Total</th>

            <th>Billable</th>
            <th>Case Link</th>
            <th>Case Number</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
        {loadingTime ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
          <CircularProgress size="lg" />
        </Box>
      ) : (
        <>
          {userReport.time_entries.length > 0 ? (
            userReport.time_entries.map((entry, index) => (
              <tr key={index}>
                <td>{new Date(entry.entry_date).toLocaleDateString()}</td>

                <td>{entry.activity_name}</td>
                <td>{entry.rate}</td>

                <td>{entry.hours}</td>
                <td>${((entry.rate || 0) * (entry.hours || 0)).toFixed(2)}</td>

                <td>{entry.billable ? "Yes" : "No"}</td>
                <td>
                  {/* <a href="#"> */}
                  {entry.case_name}
                  {/* </a> */}
                </td>
                <td>{entry.case_number || "--"}</td>
                <td>{entry.status}</td>

              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" style={{ textAlign: "center" }}>
                No records available
              </td>
            </tr>
          )}
          </>
        )}
        </tbody>
      </Table>

      <Typography level="h5" sx={{ mt: 2 }}>
        Expenses
      </Typography>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Activity</th>
            <th>Billable</th>
            <th>Description</th>
            <th>Cost</th>
            <th>Quantity</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
        {loadingTime ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
          <CircularProgress size="lg" />
        </Box>
      ) : (
        <>
         {userReport.expenses && userReport.expenses.length > 0 ? (
  userReport.expenses.map((expense, index) => {
    const truncatedText =
      expense.description.length > 25
        ? expense.description.substring(0, 25) + "..."
        : expense.description;

    return (
      <tr key={index}>
        <td>{new Date(expense.entry_date).toLocaleDateString()}</td>
        <td>{expense.activity_name}</td>
        <td>{expense.billable ? "Yes" : "No"}</td>
        <td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "150px",
          }}
        >
          <Tooltip title={expense.description} variant="soft">
            <Typography
              sx={{
                cursor: "pointer",
                display: "inline-block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "150px", // Adjust width as needed
              }}
            >
              {truncatedText}
            </Typography>
          </Tooltip>
        </td>
        <td>{expense.cost}</td>
        <td>{expense.units}</td>
        <td>{expense.cost * expense.units}</td>
      </tr>
    );
  })
) : (
  <tr>
    <td colSpan="7" style={{ textAlign: "center" }}>No records available</td>
  </tr>
)}
          </>
        )}
        </tbody>
      </Table>
    </Box>
  );
};

export default UserTimeExpenses;
