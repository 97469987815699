import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Avatar,
  Sheet,
  Drawer,
  ButtonGroup,
  List,
  ListItem,
  ListItemButton,
  ListItemDecorator,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/joy";
import { Menu, LightMode, DarkMode } from "@mui/icons-material";
import { FolderIcon } from "@heroicons/react/24/solid";
import { HomeIcon } from "@heroicons/react/24/outline";
import { CurrencyDollarIcon } from "@heroicons/react/24/solid";
import { CalendarIcon } from "@heroicons/react/24/solid";
import { ClipboardIcon } from "@heroicons/react/24/solid";
import { UsersIcon } from "@heroicons/react/24/solid";
import { CogIcon } from "@heroicons/react/24/solid";
import { ClockIcon } from "@heroicons/react/24/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "../context/ThemeContext";
import TimerSidebar from "./TimerSidebar";
import sidebar from "../assets/sidebar/logo.png";
import { auth } from "../firebase/firebase";
import axios from "axios";
import { signOut } from "firebase/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import AssessmentIcon from "@mui/icons-material/Assessment"; // For Report
import DescriptionIcon from "@mui/icons-material/Description"; // For Documents
import ProfileEdit from "../pages/Profile/ProfileEdit";

const Sidebar = ({ setShowSettingsSidebar }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (key) => {
    setExpanded((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <IconButton
        onClick={() => setOpen(true)}
        sx={{
          display: { xs: "block", md: "none" },
          position: "fixed",
          top: 16,
          left: 16,
          zIndex: 1300,
        }}
      >
        <Menu />
      </IconButton>

      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <SidebarContent
          expanded={expanded}
          toggleExpand={toggleExpand}
          setShowSettingsSidebar={setShowSettingsSidebar}
        />
      </Drawer>

      <Box sx={{ display: { xs: "none", md: "block" }, flexShrink: 0 }}>
        <SidebarContent
          expanded={expanded}
          toggleExpand={toggleExpand}
          setShowSettingsSidebar={setShowSettingsSidebar}
        />
      </Box>
    </Box>
  );
};

const SidebarContent = ({ expanded, toggleExpand, setShowSettingsSidebar }) => {
  // Local state for the timer
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;
  const { mode, toggleTheme } = useContext(ThemeContext);
  const [profileImage, setProfileImage] = useState("");

  const [user, setUser] = useState("");
  const [activeUsers, setActiveUsers] = useState([]);
  const loggedInUidd = auth.currentUser?.uid;

  const fetchUsers = async () => {
    try {
      const response = await axios.get("/active-users");
      setActiveUsers(response.data.activeUsers);
      const loggedInUser = response.data.activeUsers.find(
        (u) => u.uid === loggedInUidd
      );
      if (loggedInUser) {
        setUser(loggedInUser);
      }
    } catch (error) {
      console.error("Error fetching active users:", error);
    }
  };
  const fetchProfileImage = async () => {
    try {
      const response = await axios.get(`/users/${loggedInUidd}/profile-image`);
      if (response.data.imageUrl) {
        setProfileImage(response.data.imageUrl); // Load image preview
        
      }
    } catch (error) {
      console.error("Failed to fetch profile image:", error);
    }
  };
  useEffect(() => {
    
  
    if (loggedInUidd) {
      fetchProfileImage();
    }
  }, [loggedInUidd]);
  useEffect(() => {
    fetchUsers();
  }, []);

  const handleSettingsClick = () => {
    setShowSettingsSidebar(true);
    navigate("/custom-field");
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <>
      <Sheet
        sx={{
          width: { xs: "90%", md: "250px" },
          height: "100vh",
          position: "fixed",
          backgroundColor: "#1a2b49",
          color: "white",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          borderRadius: "0 45px 0 0",
        }}
      >
        <Typography level="h4" sx={{ fontWeight: "bold", marginTop: 2 }}>
          <img
            src={sidebar}
            alt="Logo"
            style={{ width: "80px", height: "auto" }}
          />
        </Typography>

        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            marginTop: 2,
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Typography level="body2" sx={{ marginBottom: 1, color: "white" }}>
            Dashboards
          </Typography>
          <List>
             {/* Home dashboard */}
             <ListItem>
              <ListItemButton
                component={Link}
                to="/"
                sx={{
                  color: isActive("/") ? "black" : "white",
                  backgroundColor: isActive("/") ? "white" : "transparent",
                  "&:hover .home-icon": {
                    color: "#1a2b49",
                    transition: "color 0.3s ease",
                  },
                }}
              >
                <ListItemDecorator>
                  <FolderIcon
                    className="home-icon"
                    style={{
                      color: isActive("/") ? "black" : "white",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </ListItemDecorator>
                Home
              </ListItemButton>
            </ListItem>
            {/* Cases */}
            <ListItem>
              <ListItemButton
                component={Link}
                to="/cases"
                sx={{
                  color: isActive("/cases") ? "black" : "white",
                  backgroundColor: isActive("/cases") ? "white" : "transparent",
                  "&:hover .cases-icon": {
                    color: "#1a2b49",
                    transition: "color 0.3s ease",
                  },
                }}
              >
                <ListItemDecorator>
                  <FolderIcon
                    className="cases-icon"
                    style={{
                      color: isActive("/cases") ? "black" : "white",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </ListItemDecorator>
                Cases
              </ListItemButton>
            </ListItem>

            {/* Contacts */}
            <ListItem>
              <ListItemButton
                component={Link}
                to="/contacts"
                sx={{
                  color: isActive("/contacts") ? "black" : "white",
                  backgroundColor: isActive("/contacts")
                    ? "white"
                    : "transparent",
                  "&:hover .contacts-icon": {
                    color: "#1a2b49",
                    transition: "color 0.3s ease",
                  },
                }}
              >
                <ListItemDecorator>
                  <UsersIcon
                    className="contacts-icon"
                    style={{
                      color: isActive("/contacts") ? "black" : "white",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </ListItemDecorator>
                Contacts
              </ListItemButton>
            </ListItem>

            {/* Calendar */}
            <ListItem>
              <ListItemButton
                component={Link}
                to="/calendar"
                sx={{
                  color: isActive("/calendar") ? "black" : "white",
                  backgroundColor: isActive("/calendar")
                    ? "white"
                    : "transparent",
                  "&:hover .calendar-icon": {
                    color: "#1a2b49",
                    transition: "color 0.3s ease",
                  },
                }}
              >
                <ListItemDecorator>
                  <CalendarIcon
                    className="calendar-icon"
                    style={{
                      color: isActive("/calendar") ? "black" : "white",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </ListItemDecorator>
                Calendar
              </ListItemButton>
            </ListItem>

            {/* Task */}
            <ListItem>
              <ListItemButton
                component={Link}
                to="/task"
                sx={{
                  color: isActive("/task") ? "black" : "white",
                  backgroundColor: isActive("/task")
                    ? "white"
                    : "transparent",
                  "&:hover .task-icon": {
                    color: "#1a2b49",
                    transition: "color 0.3s ease",
                  },
                }}
              >
                <ListItemDecorator>
                  <ClipboardIcon
                    className="task-icon"
                    style={{
                      color: isActive("/task") ? "black" : "white",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </ListItemDecorator>
                Task
              </ListItemButton>
            </ListItem>

            {/* Billing */}
            <ListItem>
              <ListItemButton
                component={Link}
                to="/billing"
                sx={{
                  color: isActive("/billing") ? "black" : "white",
                  backgroundColor: isActive("/billing")
                    ? "white"
                    : "transparent",
                  "&:hover .billing-icon": {
                    color: "#1a2b49",
                    transition: "color 0.3s ease",
                  },
                }}
              >
                <ListItemDecorator>
                  <CurrencyDollarIcon
                    className="billing-icon"
                    style={{
                      color: isActive("/billing") ? "black" : "white",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </ListItemDecorator>
                Billing
              </ListItemButton>
            </ListItem>

            {/* Report */}
            <ListItem>
              <ListItemButton
                component={Link}
                to="/reports"
                sx={{
                  color: isActive("/reports") ? "black" : "white",
                  backgroundColor: isActive("/reports")
                    ? "white"
                    : "transparent",
                  "&:hover .reports-icon": {
                    color: "#1a2b49",
                    transition: "color 0.3s ease",
                  },
                }}
              >
                <ListItemDecorator>
                  <AssessmentIcon
                    className="reports-icon"
                    style={{
                      color: isActive("/reports") ? "black" : "white",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </ListItemDecorator>
                Report
              </ListItemButton>
            </ListItem>

            {/* Documents */}
            <ListItem>
              <ListItemButton
                component={Link}
                to="/documents"
                sx={{
                  color: isActive("/documents") ? "black" : "white",
                  backgroundColor: isActive("/documents")
                    ? "white"
                    : "transparent",
                  "&:hover .documents-icon": {
                    color: "#1a2b49",
                    transition: "color 0.3s ease",
                  },
                }}
              >
                <ListItemDecorator>
                  <DescriptionIcon
                    className="documents-icon"
                    style={{
                      color: isActive("/documents") ? "black" : "white",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </ListItemDecorator>
                Documents
              </ListItemButton>
            </ListItem>
          </List>
        </Box>

        {/* Timer Trigger Button */}
        {/* <IconButton
          onClick={() => setSidebarOpen(true)}
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            backgroundColor: "#1a2b49",
            color: "white",
            mt: 1,
            zIndex: 999,
            "&:hover": {
              backgroundColor: "black",
            },
            "&:hover svg": {
              color: "white",
            },
          }}
        >
          <ClockIcon sx={{ color: "white" }} />
          <Typography sx={{ ml: 0.5, color: "white" }}></Typography>
        </IconButton> */}

        <Box
          sx={{
            padding: 2,
            borderRadius: "md",
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <ButtonGroup variant="soft">
            <Button
              onClick={() => toggleTheme("light")}
              variant={mode === "light" ? "solid" : "soft"}
            >
              <LightMode sx={{ mr: 1 }} /> Light
            </Button>
            <Button
              onClick={() => toggleTheme("dark")}
              variant={mode === "dark" ? "solid" : "soft"}
            >
              <DarkMode sx={{ mr: 1 }} /> Dark
            </Button>
          </ButtonGroup>
        </Box>

     
      </Sheet>
      {/* <Sheet
  sx={{
    position: "absolute",
    top: { xs: "auto", md: "" }, // Auto for mobile, 1rem for desktop
    bottom: { xs: "5rem", md: "auto" }, // 1rem for mobile, auto for desktop
    right: { xs: "", md: "1rem" },
    padding: 2,
    backgroundColor: "transparent",
    borderRadius: "md",
    boxShadow: 3,
    zIndex: 1300,
    minWidth: "auto",
    display: "flex",
    alignItems: "center",
    gap: { xs: "0", md: "0.1rem" },
  }}
>
<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
  <Avatar
    src={profileImage}
    sx={{ cursor: "pointer" }}
    onClick={() =>
      navigate("/profile/edit", {
        state: { userId: user?.uid, user, imageUrl: profileImage },
      })
    }
  />
  <Box sx={{ textAlign: "center", marginBottom: 1 }}>
    <Tooltip title={`${user?.first_name} ${user?.last_name}`} arrow>
      <Typography
        noWrap
        sx={{
          color: "white",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "150px",
          cursor: "pointer",
        }}
      >
        {user?.first_name} {user?.last_name}
      </Typography>
    </Tooltip>
    <Tooltip title={user?.email} arrow>
      <Typography
        noWrap
        sx={{
          color: "neutral.500",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "150px",
          cursor: "pointer",
        }}
      >
        {user?.email}
      </Typography>
    </Tooltip>
  </Box>
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
    <Avatar
    src={profileImage}
    sx={{ cursor: "pointer" }}
    onClick={() =>
      navigate("/profile/edit", {
        state: { userId: user?.uid, user, imageUrl: profileImage },
      })
    }
  />
    <IconButton onClick={handleSettingsClick}>
      <CogIcon style={{ color: "white" }} />
    </IconButton>
    <IconButton onClick={handleLogout}>
      <LogoutIcon style={{ color: "white" }} />
    </IconButton>

  </Box>
</Box>

        </Sheet> */}
        <>
      {/* Left Sidebar - Full Menu */}
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: { xs: '70px', md: '240px' },
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: { xs: '70px', md: '240px' },
            boxSizing: 'border-box',
            backgroundColor: '#1a2b49',
            color: 'white',
          },
        }}
      >
        <Box sx={{ p: 2 }}>
        <Tooltip title={`${user?.first_name} ${user?.last_name}`} arrow>

          <Avatar src={profileImage} sx={{ mb: 2, cursor: 'pointer' }} />
          </Tooltip>
          <Typography variant="h6" noWrap>Menu</Typography>
        </Box>
      </Drawer>

      {/* Right Thin Sidebar - Icons Only */}
      <Box
        sx={{
          position: 'fixed',
          top: { xs: 'auto', md: '50%' },
          right: 0,
          transform: { md: 'translateY(-50%)' },
          backgroundColor: 'rgba(26, 43, 73, 0.9)',
          padding: 1,
          zIndex:999,
          borderRadius: '8px 0 0 8px',
        }}
      >
                <Tooltip title={`${user?.first_name} ${user?.last_name}`} arrow>

         <Avatar
    src={profileImage}
    sx={{ cursor: "pointer" }}
    onClick={() =>
      navigate("/profile/edit", {
        state: { userId: user?.uid, user, imageUrl: profileImage },
      })
    }
  />
  </Tooltip>
        <IconButton onClick={handleSettingsClick} sx={{ color: 'white' }}>
          <CogIcon />
        </IconButton>
        <IconButton onClick={handleLogout} sx={{ color: 'white' }}>
          <LogoutIcon />
        </IconButton>
        <IconButton
          onClick={() => setSidebarOpen(true)}
          sx={{
       color:'white'
          }}
        >
          <ClockIcon sx={{ color: "white" }} />
          <Typography sx={{ ml: 0.5, color: "white" }}></Typography>
        </IconButton>
      </Box>

      {/* Floating Action Button */}
      {/* <IconButton
        onClick={() => setSidebarOpen(!sidebarOpen)}
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          backgroundColor: '#1a2b49',
          color: 'white',
          zIndex: 999,
          '&:hover': { backgroundColor: 'black' },
        }}
      >
        <ClockIcon />
      </IconButton> */}
    </>
      {/* Pass the needed props so TimerSidebar can access them */}
      <TimerSidebar
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        time={time}
        setTime={setTime}
        isRunning={isRunning}
        setIsRunning={setIsRunning}
      />
      
    </>
  );
};

export default Sidebar;