// src/components/TasksDashboard.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Table,
  Typography,
  Button,
  Checkbox,
  IconButton,
  Input,
  CircularProgress,
} from "@mui/joy";
import Tooltip from "@mui/joy/Tooltip";
import {
  Check as CheckIcon,
  Close as CloseIcon,
  FilterAlt as FilterAltIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import TaskModal from "../components/taskModal";
import { auth } from "../firebase/firebase";

const API_URL = `${process.env.REACT_APP_BASE_URL}/tasks`;

export default function TasksDashboard() {
  const [tasks, setTasks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [originalTask, setOriginalTask] = useState(null);

  const [currentTask, setCurrentTask] = useState({
    task_id: null,
    task_name: "",
    description: "",
    priority: "Medium",
    due_date: "",
    completed: false,
    case_id: "",
  });
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTasks, setTotalTasks] = useState(0);
  const tasksPerPage = 20;

  useEffect(() => {
    fetchTasks();
  }, [currentPage, searchQuery]);

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(API_URL, {
        params: { page: currentPage, search: searchQuery },
      });
      console.log("data task", data);
      setTasks(data.tasks);
      setTotalTasks(data.totalTasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
    setLoading(false);
  };

  const openTaskModal = (task = null) => {
    console.log("Selected task:", task);
    setIsEditing(!!task);
    const formattedTask = task

        ? {
            task_id: task.task_id,
            task_name: task.task_name,
            description: task.description,
            priority: task.priority || "Medium",
            due_date: task.due_date || "",
            completed: task.completed === 1,
            case_id: task.case_id || "",
          }
        : {
            task_id: null,
            task_name: "",
            description: "",
            priority: "Medium",
            due_date: "",
            completed: false,
            case_id: "",
          };
  
          setCurrentTask(formattedTask);
          setOriginalTask(task ? { ...task } : null); 
          setModalOpen(true);
        };

  const closeModal = () => setModalOpen(false);

  // Modified handleTaskSubmit: include the uid from the currently logged in user.
  const handleTaskSubmit = async () => {
    try {
      let taskPayload = {};
      if (isEditing && originalTask) {
        // Only send fields that actually changed
        for (const key in currentTask) {
          if (currentTask[key] !== originalTask[key]) {
            taskPayload[key] = currentTask[key];
          }
        }
  
        // If nothing changed, don't send the request
        if (Object.keys(taskPayload).length === 0) {
          closeModal();
          return;
        }
      } else {
        taskPayload = { ...currentTask };
      }
  
      // Always include UID
      taskPayload.uid = auth.currentUser.uid;
  
      const url = isEditing ? `${API_URL}/${currentTask.task_id}` : API_URL;
      const method = isEditing ? "put" : "post";
  
      await axios[method](url, taskPayload, {
        headers: {
          "x-user-uid": auth.currentUser.uid,
        },
      });
  
      fetchTasks();
      closeModal();
    } catch (error) {
      console.error("Error saving task:", error);
    }
  };

  const handleDeleteTask = async (taskId) => {
    if (window.confirm("Are you sure you want to delete this task?")) {
      try {
        await axios.delete(`${API_URL}/${taskId}`);
        fetchTasks();
      } catch (error) {
        console.error("Error deleting task:", error);
      }
    }
  };

  const totalPages = Math.ceil(totalTasks / tasksPerPage);

  return (
    <Box sx={{ padding: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography level="h4">Tasks</Typography>
        <Button
          startDecorator={<AddIcon />}
          variant="solid"
          size="sm"
          onClick={() => openTaskModal()}
        >
          Add Task
        </Button>
      </Box>

      <Box sx={{ borderRadius: 2, boxShadow: 2, padding: 2 }}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            marginY: 2,
            alignItems: "center",
          }}
        >
          <Input
            placeholder="Search Tasks..."
            size="sm"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button variant="soft" startDecorator={<FilterAltIcon />} size="sm">
            Apply Filters
          </Button>
          <Button
            variant="outlined"
            size="sm"
            onClick={() => setSearchQuery("")}
          >
            Clear Filters
          </Button>
        </Box>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table borderAxis="x" sx={{ borderRadius: 2, boxShadow: 2 }}>
            <thead>
              <tr>
                <th>
                  <Checkbox />
                </th>
                <th>Task Name</th>
                <th>Priority</th>
                <th>Completed</th>
                <th>Due Date</th>
                <th>Case/Lead</th>
                <th>Assigned To</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tasks.length ? (
                tasks.map((task) => (
                  <tr key={task.task_id}>
                    <td>
                      <Checkbox />
                    </td>
                    <td>
                      <Tooltip title={task.task_name} placement="top">
                        <Typography noWrap>{task.task_name}</Typography>
                      </Tooltip>
                    </td>
                    <td>{task.priority || "-"}</td>
                    <td>
                      {task.completed ? (
                        <CheckIcon color="success" />
                      ) : (
                        <CloseIcon color="error" />
                      )}
                    </td>
                    <td>
                      {task.due_date
                        ? new Date(task.due_date)
                            .toISOString()
                            .split("T")[0]
                        : "-"}
                    </td>
                    <td
                      onClick={() => navigate(`/cases/${task.case_id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <Tooltip title={task.name} placement="top">
                        <Typography noWrap>{task.name}</Typography>
                      </Tooltip>
                    </td>
                    <td>{task.assigned_to}</td>
                    <td>
                      <IconButton size="sm" onClick={() => openTaskModal(task)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        onClick={() => handleDeleteTask(task.task_id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="8"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    No tasks found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2,
          gap: 2,
        }}
      >
        <Button
          variant="soft"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </Button>
        <Typography>
          Page {currentPage} of {totalPages}
        </Typography>
        <Button
          variant="soft"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </Button>
      </Box>

      <TaskModal
        open={modalOpen}
        onClose={closeModal}
        task={currentTask}
        setTask={setCurrentTask}
        onSave={handleTaskSubmit}
        isEditing={isEditing}
      />
    </Box>
  );
}