import React, { useState } from "react";
import {
  Tabs,
  TabList,
  Tab,
  Menu,
  MenuItem,
  Button,
  Box,
  Card,
  Typography,
} from "@mui/joy";
import { useTheme } from "@mui/joy/styles";
import { useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import TimeEntries from "./TimeEntries";
import Expenses from "./Expenses";
import BillingDashboard from "./Dashboard";

// Additional tab components with updated, responsive padding
const RequestedFunds = () => (
  <Card sx={{ p: { xs: 1, md: 2 }, width: "100%", boxShadow: 2 }}>
    Requested Funds Content
  </Card>
);
const Invoices = () => (
  <Card sx={{ p: { xs: 1, md: 2 }, width: "100%", boxShadow: 2 }}>
    Invoices Content
  </Card>
);
const Statements = () => (
  <Card sx={{ p: { xs: 1, md: 2 }, width: "100%", boxShadow: 2 }}>
    Statements Content
  </Card>
);

// Define your tab items and associate each with its component.
const tabItems = [
  { label: "Dashboard", component: <BillingDashboard /> },
  { label: "Time Entries", component: <TimeEntries /> },
  { label: "Expenses", component: <Expenses /> },
  { label: "Requested Funds", component: <RequestedFunds /> },
  { label: "Invoices", component: <Invoices /> },
  { label: "Statements", component: <Statements /> },
];

const BillingTab = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedTab, setSelectedTab] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    // Outer container with responsive padding that will apply uniformly
    <Box sx={{ backgroundColor: "#f8f9fa", p: { xs: 1, md: 2 }, borderRadius: "8px", width: "100%", marginTop:"1rem" }}>
      {isMobile ? (
        <>
          <Button
            startDecorator={<MenuIcon />}
            onClick={() => setMenuOpen(true)}
            sx={{ width: "100%", mb: 2 }}
          >
            {tabItems[selectedTab].label}
          </Button>
          <Menu
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            placement="bottom-start"
          >
            {tabItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setSelectedTab(index);
                  setMenuOpen(false);
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
        >
          <TabList>
            {tabItems.map((item, index) => (
              <Tab
                key={index}
                sx={{
                  fontWeight: selectedTab === index ? "bold" : "normal",
                }}
              >
                {item.label}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      )}

      {/* Wrap the tab content in a Box that has 100% width */}
      <Box sx={{ mt: 3, width: "100%" }}>
        {tabItems[selectedTab].component}
      </Box>
    </Box>
  );
};

export default BillingTab;